/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class SignupServices{
    static signupServices = null;
    static getInstance(){
        if(this.signupServices==null){
            this.signupServices=new SignupServices();
        }
        return this.signupServices;
    }

    checkEmail(email){
            return http._post("/check_email",{email:email})
            .then(res => res)
            .catch(err=>{throw err})
    }
        

    signUp(user){
        return http._post("/signup",user)
            .then(res=>{
                if(res.sessionId){
                    localStorage.setItem("joopzId",res.sessionId);
                    localStorage.setItem("userId",res.userId);
                }else{
                    throw res
                }
                return true;
            })
            .catch(err=>{throw err})
    }

    sendVerificationLink(email){
        return http._post("/send-email-verification/"+email,{})
                .then(res => res)
                .catch(err => err)
    }

    verifyEmail(token){
        return http._post("/verify-email/"+token,{})
            .then(res=>res)
            .catch(err=>{throw err})
    }
}