/* eslint-disable */
export const productionHost = "https://slytext.com/api/v2";
export const stagingHost = "https://staging.slytext.com/api/v2";

export const wsProductionHost = "wss://slytext.com/ws";
export const wsStagingHost = "wss://staging.slytext.com/ws";

export const getHost = () => {
    if (window.location.hostname === ("p2pmessaging.com")) {
        return productionHost;
    }
    if (window.location.hostname === ("slytext.com")) {
        return productionHost;
    } else if (window.location.hostname === ("staging.slytext.com")) {
        return stagingHost;
    } else if (window.location.hostname.endsWith(".slytext.com")) {
        return "http://" + window.location.hostname + "/api/v2";
    } else return "http://" + window.location.hostname + ":10000/api/v2";
};

export const getWsHost = () => {
    if (window.location.hostname === ("p2pmessaging.com")) {
        return wsStagingHost;
    }
    if (window.location.hostname === ("slytext.com")) {
        return wsProductionHost;
    } else if (window.location.hostname === ("staging.slytext.com")) {
        return wsStagingHost;
    } else if (window.location.hostname.endsWith(".slytext.com")) {
        return "ws://" + window.location.hostname + "/ws";
    } else return "ws://" + window.location.hostname + ":10000/ws";
};
