/* eslint-disable */
import React from 'react'
import {formatPhoneNumber, formatTime} from "../../helpers/FormattingHelper";


export default function OptoutlistItem(props) {
    return (
            <div className={"row text-left mt-2"}>
                <div className={"col-6"}>{formatPhoneNumber(props.block.Phone)}</div>
                <div className={"col-6"}>{formatTime(props.block.CreatedAt).split(',')[0]}</div>
            </div>
    )
}
