import {
    FETCH_PROFILE_BEGIN,
    FETCH_PROFILE_FAILURE,
    FETCH_PROFILE_SUCCESS,
    UPDATE_PROFILE_SUCCESS
} from "../actions/UserActions";
import {FETCH_CUSTOMER_BEGIN, FETCH_CUSTOMER_SUCCESS} from "../actions/SubscriptionActions";

const initialState={
    loading:false,
    profile:{},
    error:null,
    customer:{
        loading:false,
        item:{},
        error:null,
    }
};

export default function UserReducer(state = initialState,action){
    switch(action.type){
        case FETCH_PROFILE_BEGIN:
            return {
                ...state,
                loading:true,
            };
        case FETCH_PROFILE_SUCCESS:
            return{
                ...state,
                loading:false,
                error: null,
                profile:{
                    ...action.payload,
                } ,
            };
        case FETCH_PROFILE_FAILURE:
            return {
                ...state,
                loading:false,
                profile: null,
                error: action.payload,
            };
        case UPDATE_PROFILE_SUCCESS:
            return{
                ...state,
                profile:action.payload
            };
        case FETCH_CUSTOMER_BEGIN:
            return {
                ...state,
                customer:{
                    loading:true,
                    item:{},
                    error:null
                }
            };
        case FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer:{
                    loading:false,
                    item:action.payload,
                    error:null
                }
            };

        default:
            return state
    }
}