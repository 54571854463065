const url = 'https://www.cellularld.com/gateway/redrapi.php';

export default class RedirectURHttpClient {
  static myInstance = null;

  static getInstance() {
    if (RedirectURHttpClient.myInstance == null) {
      RedirectURHttpClient.myInstance =
        new RedirectURHttpClient();
    }
    return this.myInstance;
  }

  _post = (path, body) => {
    return fetch(url + path, {
      method: 'POST',
      body: JSON.stringify(body),
      }
    )
      .then(res => {
        if (res.status === 403) {
          throw res;
        }
        if (res.status === 401) {
          throw res;
        }
        if (res.status === 500) {
          throw res;
        }
        if (res.status >= 300) {
          throw res;
        }

        return res.text()
      })
      .then(res => {
        if (res)
          return JSON.parse(res)
        else
          return {}
      })
      .catch(err => {
        throw err;
      })
  }
}
