// import logger from 'redux-logger';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import SlytextApp from './Reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    SlytextApp,
    {},
    composeEnhancers(
        applyMiddleware(
            thunk,),
            // logger),
    ),
    
)