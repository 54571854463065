import React from 'react'
import {Link} from 'react-router-dom';
import DeliveryReportBarChart from "./DeliveryReportBarChart";
import ConfirmationModal from "./ConfirmationModal";

 const estimatedTime = function (total,sent) {
     if(parseInt(total)<120){
         return "Few minutes";
     }
     let remaining = total-sent;
     const hours = remaining/120;
     if (hours<0){
         return "Few minutes";
     }
     return hours===parseInt(hours)?parseInt(hours) + " hours":parseInt(hours)+1 + " hours";
};

const OngoingCampaignItem = (props) => {
    const [deleteModalShow, setDeleteModalShow] = React.useState(false);

    return (
        <div className={"row  m-1 "} >

            <div className={"col-7  border pt-4 bg-light"} style={{maxHeight: "250px",overflowY: "auto"}}>
                <div className={"col-7 left"}>
                    <b>Group Name:</b> <Link to={"/groups/"+props.campaign.group.id}>{props.campaign.group.name}</Link> <br/>
                    <b>Message:</b> <br/>{props.campaign.message.message}<br/>

                </div>
                <div className={"col-5 right text-right"}>
                    <b>Total:</b> {props.campaign.total_messages_in_group}<br/>
                    <b>Sent:</b> {props.campaign.total_sent}<br/>
                    <b>Estimated End Time:</b> {estimatedTime(props.campaign.total_messages_in_group,props.campaign.total_sent)}
                </div>
                <button className={"btn btn-outline-danger"}
                        style={{position:"absolute",bottom:"15px",right:"15px"}}
                        title={"Stop campaign"}
                        onClick={()=>{
                            setDeleteModalShow(true);
                        }}>
                    Stop Campaign
                </button>

            </div>
            <div className={"col-4 pt-4 p-0 bg-light border ml-4 text-center"}>
                <b>Delivery Status</b>
                    <DeliveryReportBarChart
                    remaining = {props.campaign.total_messages_in_group-props.campaign.total_sent}
                    failed = {props.campaign.status.filter(s=>s.Status!=="SUCCESS").length}
                    success = {props.campaign.status.filter(s=>s.Status==="SUCCESS").length}/>
            </div>

            <ConfirmationModal
                show={deleteModalShow}
                alertMessage={"Do you really want to stop the campaign?"}
                note={"This action cannot be reverted."}
                onSubmit={()=>{
                            props.deleteCampaign(props.campaign.message.id);
                        }}
                onHide={()=>{
                    setDeleteModalShow(false);
                }}
                positiveButtonHead="Yes"
                negativeButtonHead={"Cancel"}
                />
        </div>
    );
};

OngoingCampaignItem.propTypes = {};

OngoingCampaignItem.defaultProps = {};

export default OngoingCampaignItem;
