/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class ContactServices{
    static contactServices = null;
    static getInstance(){
        if(this.contactServices==null){
            this.contactServices=new ContactServices();
        }
        return this.contactServices;
    }

     getContacts(){
        return http._get("/contacts")
                .then(res => res)
                .catch(err => {throw err});
    }

     getContactById(id){
        return http._get("/contacts/"+id)
        .then(res =>res)
        .catch(err => {throw err});
    }

     getBlocks(){
        return http._get("/blocks")
        .then(res=>res)
        .catch(err => err)
    }

     createBlock(contact_id=0,contact_phone=""){
        return http._post("/blocks",{
            contact_id:contact_id,
            contact_phone:contact_phone,
        })
            .then(res=>res)
            .catch(err=>{throw err})
    }
     updateContact(id, contact){
        return http._put("/contacts/"+id,contact)
                .then(res=>res)
                .catch(err=>{throw err})
    }

     deleteContact(id){
        return http._delete("/contacts/"+id)
                .then(res=>res)
                .catch(err=>{throw err})
    }


     addContact(contact){
        return http._post("/contacts",contact)
                .then(res=>res)
                .catch(err=>{
                    throw err})
    }

     addBulkContacts(contacts){
        return http._post("/contacts/bulk",contacts)
            .then(res=>res)
            .catch(err=>{throw err})
    }
}