/* eslint-disable */
import React, {Component} from 'react';
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import '../../assets/style/account-settings.css';
import AuthServices from '../../Services/AuthServices';
import NavbarFull from './NavbarFull';
import Switch from "react-switch";
import InputDialogModal from "./InputDialogModal";
import {createToast} from "../../helpers/ToastIdHelper";
import {extractPhoneString} from "../../helpers/FormattingHelper";


export default class ProfileComponent extends Component {
    constructor(props){
        super(props);
        const user = this.props.user.profile;
        this.state={
            username:user.username,
            email: user.email,
            name: user.name,
            notification_sound_enabled: user.notification_sound_enabled===true,
            notifications_enabled: user.notifications_enabled===true,
            old_password: "",
            password: "",
            phone: user.phone,
            sms_forwarding: user.sms_forwarding,
            phone_number_verified: user.phone_number_verified,
            modalShow:false,
            slybroadcast_customer:user.slybroadcast_customer,
            email_forwarding:user.email_forwarding,
            call_forwarding_enable: user.call_forwarding_enable,
            call_forwarding_number: user.call_forwarding_number,
        };
        this.toastId=105;
    }

    componentWillReceiveProps(props){
        const user = props.user.profile;
        this.setState({
            username: user.username,
            email:user.email,
            name: user.name,
            notification_sound_enabled: user.notification_sound_enabled===true,
            notifications_enabled: user.notifications_enabled===true,
            old_password: "",
            password: "",
            phone: user.phone,
            sms_forwarding: user.sms_forwarding,
            phone_number_verified: user.phone_number_verified,
            modalShow:false,
            slybroadcast_customer:user.slybroadcast_customer,
            email_forwarding:user.email_forwarding,


            call_forwarding_enable: user.call_forwarding_enable,
            call_forwarding_number: user.call_forwarding_number,
        })
    }

    componentDidMount(){
        this.props.fetchProfile();
    };

    handleInputChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    handleNotiEnableChange=(checked)=>{
        this.setState({
            notifications_enabled:checked
        })
    };
    handleNotiSoundEnableChange=(checked)=>{
        this.setState({
            notification_sound_enabled:checked
        })
    };

    handleEmailNotificationChange=(checked)=>{
        this.setState({
            email_forwarding:checked
        })
    };
    handleSmsForwardChange=(checked)=>{
        if(this.state.phone_number_verified===false){
            createToast(this.toastId,"Phone number not verified. Cannot forward messages.",toast.TYPE.ERROR);
            return
        }
        this.setState({
            sms_forwarding:checked
        })
    };
    handleCallForwardingChange=(checked)=>{
        this.setState({
            call_forwarding_enable:checked
        })
    };

    handleSave=(e)=>{
        e.preventDefault();
        if(this.state.call_forwarding_enable &&
            !this.state.call_forwarding_number){
            createToast(this.toastId,"Please provide a valid phone number for call forwarding.",toast.TYPE.ERROR);
            return;
        }
        createToast(this.toastId,"Updating profile. Please wait...",toast.TYPE.DEFAULT);
        const authServices = AuthServices.getInstance();
        let profile;
        if(this.state.password!==""){
            profile=this.state;
        }else{
            profile={
                name: this.state.name,
                notification_sound_enabled: this.state.notification_sound_enabled===true,
                notifications_enabled: this.state.notifications_enabled===true,
                phone: this.state.phone,
                sms_forwarding: this.state.sms_forwarding,
                email_forwarding: this.state.email_forwarding,
                call_forwarding_enable: this.state.call_forwarding_enable,
                call_forwarding_number: this.state.call_forwarding_number,
            }
        }
        profile.phone = extractPhoneString(profile.phone);

        authServices.updateProfile(profile)
            .then(res => {
                this.props.updateProfileSuccess(res);
                createToast(this.toastId,"Profile updated.",toast.TYPE.SUCCESS)

            })
            .catch(err=>{
                console.log('err: ', err);
                err.text().then(err => {
                    const error = JSON.parse(err).error;
                    createToast(this.toastId,"Could not update profile: "+error,toast.TYPE.ERROR)

                })

            })
    };

    verifyPhoneNumber=(e)=>{
        e.preventDefault();
        const authServices = AuthServices.getInstance();
        authServices.getPhoneVerificationCode()
            .then(()=>{
                    this.setState({
                        modalShow:true
                    })
                }
            )
            .catch(err=>{
                err.text()
                    .then(res=>{
                        toast.error(res)
                    })
                    .catch(()=>{
                        toast.error("Could not send verification code.")
                    })
            })
    };

    onPhoneVerificationSubmit=(verificationCode)=>{
        console.log(verificationCode);
        const authServices = AuthServices.getInstance();
        authServices.verifyPhoneByToken(verificationCode)
            .then(()=>{
                createToast(this.toastId,"Phone number verified.",toast.TYPE.SUCCESS);
                this.setState({
                    modalShow:false,
                    phone_number_verified:true
                })
            })
            .catch(err=> {
                this.setState({
                    modalShow: false,
                });
                err.text()
                    .then(res=>{
                        toast.error(res)
                    })
                    .catch(()=>{
                        toast.error("Could not verify phone number.")
                    })
            })
    };

    render() {
        if(this.props.user.profile==null){
            return (<div/>)
        }
        return <div>
            <NavbarFull/>
            <div className="settings-card col-md-6 col-sm-12 offset-md-3 p-0">
                <h3 className="mt-4 title">Account Settings</h3>
                <div className="container col-sm-12 p-4">
                    {this.props.user.loading ?
                        <ReactLoading type="spin"
                                      color={'#000000'}
                                      className={'centered'}
                        />
                        :
                        <form id="account-settings">
                            {this.state.slybroadcast_customer?
                                <h6>Created from slybroadcast account details.</h6>
                                :
                                ""}
                            <div className="form-row">

                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Name</label>
                                    <input type="text"
                                           className="form-control"
                                           id="name"
                                           name="name"
                                           value={this.state.name}
                                           placeholder="Firstname Lastname"
                                           onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail">Email Address</label>
                                    <input type="email"
                                           className="form-control"
                                           id="inputEmail"
                                           value={this.state.email}
                                           placeholder="Email"
                                           readOnly/>
                                </div>

                            </div>

                            <div className="form-group">
                                <label htmlFor="inputEmail">Contact Phone Number</label>
                                <input type="phone"
                                       className="form-control"
                                       id="phone"
                                       name="phone"
                                       value={this.state.phone}
                                       placeholder="Phone"
                                       onChange={this.handleInputChange}/>
                                {this.state.phone === this.props.user.profile.phone ?
                                    <React.Fragment>
                                    {!this.state.phone_number_verified ?
                                        <div className={"text-left pl-1"}>
                                            <button className={"btn btn-link"} onClick={this.verifyPhoneNumber} title="Click to verify">
                                                <u><small className={"text-danger"}><i
                                                    className="fas fa-times-circle"/> Not verified. Verify
                                                    now.</small></u>
                                            </button>
                                        </div>
                                        :
                                        <div className={"text-left pl-1"}>
                                            <small className={"text-success"}><i
                                                className="fas fa-check-circle"/> Verified.</small>
                                        </div>
                                    }
                                    </React.Fragment>
                                    :""
                                }
                            </div>
                            {!this.state.slybroadcast_customer ?
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputCurrentPassword">Current Password</label>
                                        <input type="password"
                                               className="form-control"
                                               id="old_password"
                                               name="old_password"
                                               placeholder="Current password"
                                               value={this.state.old_password}
                                               onChange={this.handleInputChange}
                                               autoComplete="on"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputNewPassword">New Password</label>
                                        <input type="password"
                                               className="form-control"
                                               id="password"
                                               name="password"
                                               placeholder="New password"
                                               value={this.state.password}
                                               onChange={this.handleInputChange}
                                               autoComplete="off"/>
                                    </div>
                                </div>
                                :
                                <div className={"form-group text-center"}>
                                    <label>Change Password</label>
                                    <p className={"text-muted"} style={{letterSpacing:"1px",fontWeight:"100"}}>
                                        Visit SlyBroadcast to change your password.
                                    </p>
                                </div>
                            }

                            <div className="container">
                                <div className="row text-center">
                                    {/* Show notification switch */}
                                    <div className={"col-12 mt-4"}>
                                        <label className=" " htmlFor="notificationShow">
                                            Show Notifications<br/>
                                            <p className={"text-muted"} style={{letterSpacing:"1px",fontWeight:"100"}}>Allow browser notifications.</p>
                                        </label>
                                        <Switch onChange={this.handleNotiEnableChange} checked={this.state.notifications_enabled}  height={20} width={40}/>
                                    </div>

                                    <div className={"col-12 mt-4"}>
                                        <label className="" htmlFor="smsForward">
                                            Email Notification<br/>
                                            <p className={"text-muted"} style={{letterSpacing:"1px",fontWeight:"100"}}> Receive email notification for unread messages.</p>
                                        </label>

                                        <Switch onChange={this.handleEmailNotificationChange} checked={this.state.email_forwarding} height={20} width={40} />
                                    </div>

                                    {/* SMS forwarding switch */}
                                    <div className={"col-12 mt-4"}>
                                        <label className="" htmlFor="smsForward">
                                            SMS Forwarding<br/>
                                            <p className={"text-muted"} style={{letterSpacing:"1px",fontWeight:"100"}}>
                                                Forward any unread messages to your contact number. Carrier rates apply.
                                            </p>
                                        </label>

                                        <Switch onChange={this.handleSmsForwardChange}
                                                checked={this.state.sms_forwarding}
                                                height={20}
                                                width={40} />
                                    </div>
                                </div>
                            </div>

                            {/* Call forwarding */}
                            <div className="container mb-4 mt-4">
                                <div className="row text-center">
                                    {/* Show notification switch */}
                                    <div className={"col-12"}>
                                        <label className=" " htmlFor="callForwarding">
                                            Call Forwarding<br/>
                                            <p className={"text-muted"} style={{letterSpacing:"1px",fontWeight:"100"}}>
                                                Forward any incoming calls to your P2P Messaging number to the phone number below. One message credit per minute. Carrier rates apply.
                                            </p>
                                        </label>
                                        <Switch onChange={this.handleCallForwardingChange}
                                                checked={this.state.call_forwarding_enable}
                                                height={20}
                                                width={40}/>
                                    </div>
                                    {this.state.call_forwarding_enable?
                                        <div className={"col-12 py-4"} style={{backgroundColor:"#eee"}}>
                                            <label htmlFor="call_forwarding_number">Forward calls to:</label>
                                            <input type="tel"
                                                   className="form-control"
                                                   id="call_forwarding_number"
                                                   name="call_forwarding_number"
                                                   placeholder="Enter phone number"
                                                   maxlength={11}
                                                   value={this.state.call_forwarding_number}
                                                   onChange={this.handleInputChange}
                                                   autoComplete="off"/>
                                        </div>
                                        :""}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-2 p-1 offset-md-4">
                                    <Link to="/"
                                          className="col-12 btn btn-secondary">Cancel</Link>
                                </div>
                                <div className="col-md-2 p-1">
                                    <button type="submit" className="col-12 btn btn-primary"
                                            onClick={this.handleSave}>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    }
                </div>

            </div>

            <div className={"mt-4 pt-4 text-decoration-none"}>
                <Link to="/">
                    Go to conversations
                </Link>
            </div>

            <InputDialogModal
                show={this.state.modalShow}
                onHide={() => {
                    this.setState({
                        modalShow:false,
                    })
                }}
                inputName={"verification-code"}
                heading={"Enter the verification code sent to your phone"}
                onSubmit={this.onPhoneVerificationSubmit}
                label={"Verification code"}
            />
        </div>
    }
}
