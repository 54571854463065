import React from 'react';
import {Button, Modal} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {PropTypes} from "prop-types";

const ConfirmationModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{borderRadius:"1.5rem"}}
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Alert!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"text-center"}>
                <i className="fas fa-exclamation-circle fa-7x text-muted"/>
                <h4>{props.alertMessage}</h4>
                <p>{props.note}</p>
            </Modal.Body>
            <Modal.Footer style={{display:"block"}}>
                <Row className={"pr-1"}>
                    <Col md={{span:3,offset:6}} className={"p-1"}>
                        <button onClick={props.onSubmit}
                                className={"btn btn-danger col-12"}
                                style={{backgroundColor:props.positiveButtonColor,
                                        borderColor:props.positiveButtonColor}}>{props.positiveButtonHead}</button>
                    </Col>

                    <Col md={{span:3}} className={"p-1"}>
                        <Button onClick={props.onHide}
                                variant={"secondary"}
                                className={"col-12"}>{props.negativeButtonHead}</Button>
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    alertMessage: PropTypes.string,
    note: PropTypes.string,
    onSubmit: PropTypes.func,
    onHide:PropTypes.func,
    positiveButtonHead:PropTypes.string,
    negativeButtonHead:PropTypes.string,
    positiveButtonColor:PropTypes.string,
};

ConfirmationModal.defaultProps = {};

export default ConfirmationModal;
