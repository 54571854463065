/* eslint-disable */

import React from 'react'
import {AutoSizer, List} from "react-virtualized";
import {Link} from 'react-router-dom'
import ContactListItem from './ContactListItem';
import 'react-virtualized/styles.css'; // only needs to be imported once

let contacts=null;

const Row = ({ index, style }) => (
        
         <Link to={"/contacts/"+contacts[index].id+"/conversation"}
                        // style={{ textDecoration: 'none' }}
                        key={contacts[index].id}
                        style={style}>
                        <ContactListItem contact={contacts[index]}/>
            </Link>
  );

export default function ContactListVirtualized(props) {
    contacts=props.contacts;
    return (
        <div id="virtualized" style={{flexGrow:1}}>
        <AutoSizer>
            {({ height, width }) => (
            <List
                height={height}
                rowCount={contacts.length}
                rowHeight={78}
                width={width}
                rowRenderer={Row}
                overscanRowCount={5}

            >
            </List>
            )}
        </AutoSizer>
        </div>
    )
}
