import MessageServices from "../Services/MessageServices";

const messageServices = MessageServices.getInstance();


/************************************************************
 *                  Fetch Auto Reply message                *
 ************************************************************/

export const FETCH_AUTO_REPLY_BEGIN = "FETCH_AUTO_REPLY_BEGIN";
export const FETCH_AUTO_REPLY_SUCCESS = "FETCH_AUTO_REPLY_SUCCESS";
export const FETCH_AUTO_REPLY_FAILURE = "FETCH_AUTO_REPLY_FAILURE";


const fetchAutoreplyBegin = () => ({
    type: FETCH_AUTO_REPLY_BEGIN
});

const fetchAutoreplySuccess = (res) => ({
    type: FETCH_AUTO_REPLY_SUCCESS,
    payload: res
});

const fetchAutoReplyFailure = (err) => ({
    type: FETCH_AUTO_REPLY_FAILURE,
    payload: err
});

export const fetchAutoReply=()=>{
    return dispatch=>{
        dispatch(fetchAutoreplyBegin());
        messageServices.getAutoReplyMessage()
            .then(res => dispatch(fetchAutoreplySuccess(res)))
            .catch(err => dispatch(fetchAutoReplyFailure(err)))
    }
};

/************************************************************
 *           Create or update Auto Reply message            *
 ************************************************************/

// export const CREATE_UPDATE_AR_BEGIN = "CREATE_UPDATE_AR_BEGIN";
export const CREATE_UPDATE_AR_SUCCESS = "CREATE_UPDATE_AR_SUCCESS";
// export const CREATE_UPDATE_AR_FAILURE = "CREATE_UPDATE_AR_FAILURE";

/*const createUpdateArBegin=()=>({
    type:CREATE_UPDATE_AR_BEGIN
});*/

export const createUpdateArSuccess=(res)=>({
    type:CREATE_UPDATE_AR_SUCCESS,
    payload:res
});

/*const createUpdateArFailure=(err)=>({
    type:CREATE_UPDATE_AR_FAILURE,
    payload:err
});*/


/*
export const createUpdateAr=(ar)=>{
    return dispatch=>{
        dispatch(createUpdateArBegin());
        messageServices.createUpdateAutoReply(ar)
            .then(res=>dispatch(createUpdateArSuccess(res)))
            .catch(err=>dispatch(createUpdateArFailure(err)))
    }
};*/
