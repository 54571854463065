/* eslint-disable */

import MessageItem from './MessageItem';

import React from 'react'

export default function ChatMessages(props) {
  
        return (
            <div  className=" p-4 ">
                <span className={props.messages.length===0?"":"hidden"}>
                    <h6 className="my-3 p-3 text-secondary">
                    Enter a message to start a conversation!
                    </h6>
                </span>
                {props.messages.map(message => 
                    <MessageItem message={message}
                                 key={message.id}
                                 conversationName={props.name}
                                 groupCount={props.groupCount}
                                 broadcastGroup = {props.groups?
                                                    props.groups.find(
                                                        group=>group.id===message.broadcast_group_id)
                                     :null}/>

                )}
               
            </div>
        )
    }
