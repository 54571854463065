/* eslint-disable */
import {connect} from 'react-redux'
import React, {PureComponent} from 'react'
import ContactList from '../components/AppComponents/ContactList';
import GroupList from '../components/AppComponents/GroupList';

const stateToPropertyMapper = (state, props) => {
    return {
        groups: state.groups,
        contacts: state.contacts,
        showGroups:props.groups,
        toggleToGroups:props.showGroups,
        toggleToContacts:props.showContacts,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
  
})


class BookContainer extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            show:this.props.showGroups?"groups":"contacts",
        }
        this.showContacts = this.showContacts.bind(this);
        this.showGroups = this.showGroups.bind(this);
    }

    componentDidMount(){
      
    }

    componentWillReceiveProps(props) {
        this.setState({
            show:props.showGroups?"groups":"contacts",
        })
    }

    showContacts(){
        this.setState({
            show:"contacts"
        })
    }

    showGroups(){
        this.setState({
            show:"groups"
        })
    }
    render() {
        return (
            <div className="book-container">
                    <button className={this.state.show=="contacts"?"col-6 btn btn-secondary":"col-6 btn btn-light"}
                            onClick={this.props.toggleToContacts}>
                        <b>Contacts</b>
                    </button>
                    <button className={this.state.show=="groups"?"col-6 btn btn-secondary":"col-6 btn btn-light"}
                            onClick={this.props.toggleToGroups}>
                        <b>Groups</b>
                    </button>
                    <div className={this.state.show=="contacts"?"mt-1 h-100":"hidden"}>
                        <ContactList contacts={this.props.contacts}/>
                    </div>
                    <div className={this.state.show=="groups"?"mt-1  h-100":"hidden"}>
                        <GroupList groups={this.props.groups}/>
                    </div>
            </div>
        )
    }
}


export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(BookContainer)
