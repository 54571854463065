import {library} from '@fortawesome/fontawesome-svg-core';
import {faStroopwafel} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';

// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store} from './store';
import {createMemoryHistory} from 'history';

// const tagManagerArgs = {
//     gtmId: 'GTM-M96W495'
// };
//
// TagManager.initialize(tagManagerArgs);

library.add(faStroopwafel);

const history = createMemoryHistory();

ReactDOM.render(
    <Provider store={store}>
        <Router basename={''} history={history}>
                <App />
        </Router>
    </Provider>,  
    document.getElementById('root'));

serviceWorker.unregister();
