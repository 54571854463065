import RedirectURLServices from "../Services/RedirectURLServices";

export const FETCH_DOMAINS_BEGIN = "FETCH_DOMAINS_BEGIN";
export const FETCH_DOMAINS_SUCCESS = "FETCH_DOMAINS_SUCCESS";
export const FETCH_DOMAINS_FAILURE = "FETCH_DOMAINS_FAILURE";

const fetchDomainsBegin = () => ({
  type: FETCH_DOMAINS_BEGIN
});

const fetchDomainsSuccess = (data) => ({
  type: FETCH_DOMAINS_SUCCESS,
  payload: data
});

const fetchDomainsFailure = (err) => ({
  type: FETCH_DOMAINS_FAILURE,
  payload: err
});

export const fetchDomains = () => {
  return dispatch => {
    dispatch(fetchDomainsBegin());
    RedirectURLServices.fetchDomains()
      .then(res => dispatch(fetchDomainsSuccess(res)))
      .catch(err => dispatch(fetchDomainsFailure(err)))
  }
};

/*********************************************************************
 ******                 Fetch Domains                           ******
 ********************************************************************/

export const GENERATE_KEYS_BEGIN = "GENERATE_KEYS_BEGIN";
export const GENERATE_KEYS_SUCCESS = "GENERATE_KEYS_SUCCESS";
export const GENERATE_KEYS_FAILURE = "GENERATE_KEYS_FAILURE";

const generateKeysBegin = () => ({
  type: GENERATE_KEYS_BEGIN
});

const generateKeysSuccess = (data) => ({
  type: GENERATE_KEYS_SUCCESS,
  payload: data
});

const generateKeysFailure = (err) => ({
  type: GENERATE_KEYS_FAILURE,
  payload: err
});

export const generateKeys = data => {
  return dispatch => {
    dispatch(generateKeysBegin());
    RedirectURLServices.generateKeysForPhoneNumbers(data)
      .then(res => dispatch(generateKeysSuccess(res)))
      .catch(err => dispatch(generateKeysFailure(err)))
  }
};

/*********************************************************************
 ******                 Generate Keys For Phone Numbers          *****
 ********************************************************************/

export const UPDATE_URL_BY_LIST_BEGIN = "UPDATE_URL_BY_LIST_BEGIN";
export const UPDATE_URL_BY_LIST_SUCCESS = "UPDATE_URL_BY_LIST_SUCCESS";
export const UPDATE_URL_BY_LIST_FAILURE = "UPDATE_URL_BY_LIST_FAILURE";

const updateURLByListBegin = () => ({
  type: UPDATE_URL_BY_LIST_BEGIN
});

const updateURLByListSuccess = (data) => ({
  type: UPDATE_URL_BY_LIST_SUCCESS,
  payload: data
});

const updateURLByListFailure = (err) => ({
  type: UPDATE_URL_BY_LIST_FAILURE,
  payload: err
});

export const updateURLByList = data => {
  return dispatch => {
    dispatch(updateURLByListBegin());
    RedirectURLServices.updateURLByList(data)
      .then(res => dispatch(updateURLByListSuccess(res)))
      .catch(err => dispatch(updateURLByListFailure(err)))
  }
};

/*********************************************************************
 ******                 Update URL By List                       *****
 ********************************************************************/

export const GET_LIST_BY_DOMAIN_BEGIN = "GET_LIST_BY_DOMAIN_BEGIN";
export const GET_LIST_BY_DOMAIN_SUCCESS = "GET_LIST_BY_DOMAIN_SUCCESS";
export const GET_LIST_BY_DOMAIN_FAILURE = "GET_LIST_BY_DOMAIN_FAILURE";

const getListByDomainBegin = () => ({
  type: GET_LIST_BY_DOMAIN_BEGIN
});

const getListByDomainSuccess = (data) => ({
  type: GET_LIST_BY_DOMAIN_SUCCESS,
  payload: data
});

const getListByDomainFailure = (err) => ({
  type: GET_LIST_BY_DOMAIN_FAILURE,
  payload: err
});

export const getListByDomain = data => {
  return dispatch => {
    dispatch(getListByDomainBegin());
    RedirectURLServices.getListByDomain(data)
      .then(res => dispatch(getListByDomainSuccess(res)))
      .catch(err => dispatch(getListByDomainFailure(err)))
  }
};

/*********************************************************************
 ******                 Get List By Domain                    *****
 ********************************************************************/
