/* eslint-disable */
import React from 'react';
import {Link} from "react-router-dom";
import "../../assets/style/account-settings.css";
import "../../assets/style/signin.css";

import AuthServices from '../../Services/AuthServices';
import NavbarFull from './NavbarFull';
import SignInFormComponent from './SignInFormComponent';
import GroupMessageContainer from "../../containers/GroupMessageContainer";
import SignInFormComponentSlybroadcast from "./SignInFormComponentSlybroadcast";
import {Route} from "react-router-dom";

const authServices = AuthServices.getInstance();


export default function SignInContainer(props) {
    if(authServices.isLoggedIn()){
        window.location.href="/app";
    }
    return (
            <div>
                <NavbarFull signedOut="true"/>
                <div className="row mx-0 p-0">
                    <div className={"col-md-6 p-0 signin-gradient d-none d-md-block"}
                         style={{background:props.match.path.includes("slybroadcast")?
                              "linear-gradient(to right, #647e4f, #2ecc71)"
                                    :""}}>
                        <div className={"login-placeholder"}/>
                    </div>
                    <div className="col-md-4 col-sm-8 offset-sm-2 offset-md-0 mt-4 p-0 py-3 vertical-center ">
                        {/* <img src="https://slytext.com/app/img/logo.png" height="50" alt=""></img> */}
                        <div className={"col-md-10 offset-md-1 signin-align"}>
                            <h1 className="title mb-0" style={{color:"#333"}}>Log in</h1>
                                <Route  exact path="/signin/slybroadcast" component={SignInFormComponentSlybroadcast} />
                                <Route  exact path="/signin/" component={SignInFormComponent} />
                            <div style={{height:"50px"}} className="d-none d-md-block"/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
    )
}
