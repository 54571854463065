/* eslint-disable */
import {connect} from 'react-redux';
import {deleteScheduledMessageSuccess, fetchScheduledMessages} from '../actions/ScheduledMessagesActions';
import ScheduledMessages from '../components/AppComponents/ScheduledMessages';
import React from "react";
import CampaignDashboard from "../components/AppComponents/CampaignDashboard";


const CampaignDashboardContainer=()=>{
    return(
        <div className="message-container">
            <div className="conversation-container">
                {/* Titlebar start */}
                <div className="conversation-title-container text-light shadow">
                    <div className="row p-2">
                        <div className="col-12">
                            <div className="p-2  text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Dashboard
                                        </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{overflowY: "scroll",paddingBottom: "10px"}}>
                    <CampaignDashboard/>
                </div>
                {/*<ScheduledMessages {...props}/>*/}
            </div>
        </div>
    )
};



export default CampaignDashboardContainer;