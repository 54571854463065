/* eslint-disable */
import {connect} from "react-redux";
import ClickThroughReport from "../components/AppComponents/ClickThroughReport";
import {fetchDomains} from "../actions/redirectURLActions";

const stateToPropertyMapper = (state, props) => {
    return {
        domains: state.redirectURL.domains,
    };
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchDomains: () => {
        dispatch(fetchDomains());
    }
});


const ClickThroughReportContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(ClickThroughReport);

export default ClickThroughReportContainer;