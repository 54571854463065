/* eslint-disable */
import ScheduleMessageListItem from "./ScheduleMessageListItem";

import React, {PureComponent} from 'react';
import ScheduledMessages from "./ScheduledMessages";
import MessageServices from "../../Services/MessageServices";
import {toast} from "react-toastify";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import {sortTimeStamp} from "../../helpers/FormattingHelper";

class ConversationScheduledMessagesComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state={
            show:false,
            scheduledMessages:this.props.scheduledMessages
        }
        this.scrollToBottom=this.scrollToBottom.bind(this)
    }

    componentDidUpdate(){
        this.setState({
            scheduledMessages:this.props.scheduledMessages
        })
    }

    handleEditClick=(id)=>{
        this.setState({
            edit:id,
        })
    }

    handleEditCancel=()=>{
        this.setState({
            edit:-1,

        })
        this.props.fetchScheduledMessages();

    }

    handleDelete=(id)=>{
        const messageServices = MessageServices.getInstance();
        messageServices.deleteScheduledMessage(id)
            .then(res => {
                toast.success(NotificationHelpers.SUCCESS_SCHEDULED_MESSAGE_DELETE)
                this.deleteScheduledMessage(id)
            })
            .catch(err => {
                console.log('err: ', err);
                toast.error(NotificationHelpers.ERROR_DELETE_SCHEDULED_MESSAGE);
            })
    }
    handleEditMessageChange=(id,message)=>{
        this.setState({
            scheduledMessages:{
                ...this.state.scheduledMessages,
                items:this.state.scheduledMessages.items.map(sm=>{
                    if(sm.messageID===id){
                        sm.message=message;
                    }
                    return sm
                })
            }
        })
    }
    handleSave=(id,message)=>{
        const messageServices = MessageServices.getInstance();
        messageServices.updateScheduledMessage(id,{messageText:message})
            .then(res => {
                toast.success(NotificationHelpers.SUCCESS_UPDATE_SCHEDULED_MESSAGE);
                this.setState({
                    edit:-1
                })
            })
            .catch(err => {
                toast.error(NotificationHelpers.ERROR_UPDATE_SCHEDULED_MESSAGE);
            })
    }
    deleteScheduledMessage=(id)=>{
        this.setState({
            scheduledMessages:{
                ...this.state.scheduledMessage,
                items:this.state.scheduledMessages.items.filter(sm=>sm.messageID!=id)
            }
        })
    }
    render() {

            if(this.state.scheduledMessages.items.length > 0) {
                return   <div className="mb-1 conversation-scheduled-messages">
                    <div style={{backgroundColor: "#f8fbfd"}}>
                        <div style={{background: "#eee", padding: "5px"}}>
                            <h6 style={{color: "#666"}}>
                                Scheduled messages <span
                                className="font-italic">({this.state.scheduledMessages.items.length} pending)</span>
                                <button
                                   className="right"
                                   onClick={(e) => {
                                       e.preventDefault()
                                       this.setState({show: !this.state.show});
                                       this.scrollToBottom();
                                   }}>{this.state.show ? "Hide" : "Show"}</button>
                            </h6>
                        </div>
                        {this.state.show ?
                            <ul className="list-group m-4">
                                {sortTimeStamp(this.state.scheduledMessages.items,"timestamp").map(sm =>
                                    <ScheduleMessageListItem {...sm}
                                        edit={this.state.edit===sm.messageID}
                                        onEditClick={this.handleEditClick}
                                        onCancel={this.handleEditCancel}
                                        onMessageChange={this.handleEditMessageChange}
                                        onSave={this.handleSave}
                                        onDelete={this.handleDelete}
                                        key={sm.messageID}
                                        fromConversation={this.props.fromConversation}
                                    >
                                    </ScheduleMessageListItem>)
                                }
                            </ul>

                            :
                            ""
                        }
                        <div ref={el => {
                            this.el = el;
                        }}/>
                    </div>
                </div>
            }

            return (<div></div>)

    }

    scrollToBottom() {
        this.el.scrollIntoView({ block:"end"});
    }
}
export default ConversationScheduledMessagesComponent;
