/* eslint-disable */
import {
    ADD_GROUP_BEGIN,
    ADD_GROUP_FAILURE,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP_SUCCESS,
    FETCH_GROUP_BY_ID_SUCCESS,
    FETCH_GROUPS_BEGIN,
    FETCH_GROUPS_SUCCESS,
    UPDATE_GROUP_BEGIN,
    UPDATE_GROUP_SUCCESS
} from "../actions/groupActions";


const initialState = {
    loading: false,
    error: null,
    items: [],
    selectedGroup:{
        name:''
    },
    updateLoading:false,
    updateError:null,
    newGroup:{
        loading:false,
        error:null,
        item:{}
    }
}

export default function GroupReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_GROUPS_BEGIN:
            return {
                ...state,
                items:state.items,
                loading:true,
                error: null,
            };
        case FETCH_GROUPS_SUCCESS:
            return{
                ...state,
                items:action.payload,
                loading:false,
                error: null
            };
        case FETCH_GROUP_BY_ID_SUCCESS:
            return{
                ...state,
                loading:false,
                error:null,
                selectedGroup: action.payload
            };
        case ADD_GROUP_BEGIN:
            return {
                ...state,
                newGroup: {
                    loading: true,
                    error: null,
                    item: {},
                },
                selectedGroup: {}

            };
        case ADD_GROUP_SUCCESS:
            return {
                ...state,
                newGroup:{
                    loading:false,
                    error:null,
                    item:action.payload,
                },
                items:[...state.items,action.payload]
            };
        case ADD_GROUP_FAILURE:
            return {
                ...state,
                newGroup:{
                    loading:false,
                    error:action.payload,
                    item:{}
                }
            };
        case UPDATE_GROUP_BEGIN:
            return {
                ...state,
                updateLoading:true,
                updateError:null,
            };
        case UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                updateLoading:false,
                updateError:null,
                selectedGroup:action.payload,
                items:state.items.map(group=>{
                    if(group.id===action.payload.id){
                        return action.payload
                    }else return group
                })
            };

        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                items:state.items.filter(group=>group.id!==action.payload.id)
            };
        default:
            return state
    }
}