/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {propTypes} from "react-csv/src/metaProps";
import {getHost} from "../../helpers/url-helper";


export default function MediaVieweModal(props) {
    if(props.fileType==="" || props.fileType==="image"){
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{borderRadius:"1.5rem"}}
                className={"media-viewer-modal"}
            >
                <Modal.Body>
                    <div className={"close-button"}>
                        <a onClick={props.onHide}
                           title={"Close"}><i className="fas fa-times-circle"></i></a>
                    </div>
                    <img src={getHost()+"/mms/"+props.fileHash} style={{width:'100%'}} alt={"Media"}/>
                </Modal.Body>
            </Modal>
        )
    }else if(props.fileType==="video"){
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={"media-viewer-modal"}

            >
                <Modal.Body>
                    <div className={"close-button"}>
                        <a onClick={props.onHide} title={"Close"}><i className="fas fa-times-circle"></i></a>
                    </div>
                    <video  style={{width:'100%'}} controls>
                        <source src={getHost()+"/mms/"+props.fileHash} type="video/mp4"/>
                        <source src={getHost()+"/mms/"+props.fileHash} type="video/ogg"/>
                        Your browser does not support the video tag.
                    </video>
                </Modal.Body>
            </Modal>
        )
    }else{
        return <div>
            Cannot preview the file.
        </div>
    }



}


MediaVieweModal.propTypes = {
    onHide: PropTypes.func,
    fileHash: PropTypes.string,
    filetype: propTypes.string
}

