/* eslint-disable */
import React from 'react'
import {formatPhoneNumber} from "../../helpers/FormattingHelper";

export default function Tutorial(props) {
    return (
        <div className="tutorial-container col-md-9">
            <div className="tutorial container mt-4 pt-4 text-muted">
                <h1>Hello, {props.profile.name}! </h1>
                <h5>Click on a conversation on the left panel to start a chat.</h5>
                <h5 className="mt-4">To start a new a conversation:</h5>
                <ol className="text-left col-md-5 offset-md-4">
                    <li>Create a contact or group by clicking on the <i className={"fa fa-plus"}/></li>
                    <li>Go to <i className={"fas fa-user"}/></li>
                    <li>Click on contact or group to open the corresponding conversation</li>
                </ol>
                <br/>
                <h5>Your P2P Messaging phone number is <b>{formatPhoneNumber(props.profile.slytext_phone)}</b></h5>

                <br/>
                <br/>
                <br/>
                <br/>
                {/**Visit our <a href={"https://www.youtube.com/channel/UC9OU5hHH885nSjhz8NhEtSw"} target="_blank" rel={"slytext.com"}>YouTube channel</a> for additional tutorials.*/}
            </div>
        </div>
    )
}
