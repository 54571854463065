/* eslint-disable */
import {toast} from "react-toastify";

export const SIGN_IN = 100;
export const SIGN_UP = 101;
export const FORGET_PASS = 102;
export const RESET_PASS = 103;
export const FIRT_TIME_LOGIN = 104;
export const MAIN_APP = 105;
export const ACCOUNT_SETTINGS = 106;
export const SUBSCRIPTION_SETTINGS = 107;
export const MESSAGE_INPUT_BAR = 108;
export const CONTACT_SETTINGS = 109;
export const SLYB_GROUP_PHONES = 110;
export const CAMPAIGN_DASHBOARD = 111;
export const GROUP_SETTINGS = 109;

export function createToast(toastId,msg,type){
    if(toast.isActive(toastId)){
        toast.update(toastId,{
            render:msg,
            type:type,
            autoClose:5000,
            className: 'rotateY animated',
        })
    }else{
        toast(msg,{
            toastId:toastId,
            type:type,
            autoClose: 5000
        })
    }
}

