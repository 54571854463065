/* eslint-disable */
import React, {Component} from 'react'
import GroupListItem from './GroupListItem';
import {Link} from 'react-router-dom';
import {AutoSizer, List} from "react-virtualized";
import 'react-virtualized/styles.css'

export default class GroupList extends Component {
    constructor(props){
        super(props);
        this.state={
            groupFilterString:"",
            groups:this.props.groups.items
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            groups:props.groups.items
        })
    }
    render() {
        return (
            <div className=" flex-flow-column h-100">
                 <div className="container my-2 bg-muted">
                    <input className="form-control rounded empty"
                            placeholder="&#xF002; Search groups..."
                            onChange={(e)=>{
                                this.setState({
                                    groupFilterString:e.target.value.toLowerCase(),
                                    groups:this.props.groups.items.filter(group=>group.name.toLowerCase().includes(e.target.value.toLowerCase()))
                                })
                            }}/>
                </div>
                <div className="container">
                    <div className="row">
                        <Link   to="/groups/new/edit"
                                className="btn col-12 btn-light conversation-list-item h-auto" >
                            <p className="p-2 m-0"><i className="fas fa-plus"></i> Create group</p>
                        </Link>
                        
                    </div>
                </div>
                <div id="virtualized" style={{flexGrow:1}}>
                    {this.state.groups.length ?

                        <AutoSizer>
                            {({height, width}) => (
                                <List
                                    height={height}
                                    rowCount={this.state.groups.length}
                                    rowHeight={78}
                                    width={width}
                                    rowRenderer={this.Row}
                                    overscanRowCount={5}
                                >
                                </List>
                            )}
                        </AutoSizer>
                        :
                        <div className={"text-muted col-10 offset-1"}>
                            <br/>
                            No groups available.
                        </div>
                    }
                </div>
            </div>
        )
    }

    Row=({index,style})=>{
        return <Link to={"/groups/"+this.state.groups[index].id+"/conversation"}
                    style={style}
                    key={this.state.groups[index].id}>
                    <GroupListItem group={this.state.groups[index]}/>
                </Link>
    }
}
