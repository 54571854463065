/* eslint-disable */
import {connect} from 'react-redux';
import {fetchProfile, updateProfileSuccess} from '../actions/UserActions';
import ProfileComponent from '../components/AppComponents/ProfileComponent';

const stateToPropertyMapper = (state, props) => {
    return {
        user: state.user,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
    fetchProfile : () => {
        dispatch(fetchProfile());
    },
    updateProfileSuccess : (res) => {
        dispatch(updateProfileSuccess(res))
    }
})

export const ProfileContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper
)(ProfileComponent);

