/* eslint-disable */
import React from 'react'
import slyb from "../../assets/images/slyb.png";

export default function GroupListItem(props) {
    return (
        <div className="container conversation-list-item p-0 ">
            <div className="row p-2">
                <div className="col-3">
                    <img className=" round" 
                        src={"https://slytext.com/"+props.group.avatar}
                        alt=""/>
                </div>
                <div className="col-9 bottom-bordered row p-0">
                    <div className="col-12 text-left">
                        <div className="row">
                            <div className="col-8 title">
                                {props.group.name}
                                <br/>
                                <span className="text-muted small font-weight-light font-italic">
                                    {props.group.members?props.group.members.length:""} member(s)
                                </span>
                            </div>
                            <div className={"col-4"}>
                                {props.group.slybroadcast_list_id &&  props.group.slybroadcast_list_id > 0?
                                    <img src={slyb} className={"mt-2"} title={"Imported from slyBroadcast"} height={30}/>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}