/* eslint-disable */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import '../../assets/style/date-picker.css';


class ScheduleModal extends Component {
    constructor(props){
        super(props);
        this.state={
            date:props.scheduleTime,
        };
    }
    componentWillReceiveProps(props) {
        this.setState({
            date:props.scheduleTime,
        });
    }

    render() {
        return <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{borderRadius: "1.5rem"}}>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Schedule Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={" text-center"}>
                    <div className="row">
                        <div className=" col-12">
                            <label>Select date and time:</label>
                        </div>
                        <div className="col-12">
                            <DateTimePicker
                                value={this.state.date}
                                minDate={new Date()}
                                calendarIcon={null}
                                disableClock={true}
                                dayPlaceholder={"dd"}
                                monthPlaceholder={"mm"}
                                yearPlaceholder={"yyyy"}
                                hourPlaceholder={"hh"}
                                minutePlaceholder={"MM"}
                                onChange={(date)=>{
                                    this.setState({
                                        date:date
                                    })
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <p style={{
                        position: "absolute",
                        left: "1rem"
                    }}
                       className={"text-muted"}>
                        Your message will go out on the specified date and time.</p>

                    <Button onClick={(e) => {
                        e.preventDefault();
                        this.props.updateScheduleTime(this.state.date);
                        this.props.onHide();
                    }}>Save</Button>
                </Modal.Footer>
            </Modal>
    }
}

ScheduleModal.propTypes = {
    message: PropTypes.string,
    onSubmit: PropTypes.func,
    onHide: PropTypes.func,
    updateScheduleTime:PropTypes.func,
    file: PropTypes.string,
    scheduleTime:PropTypes.any
};

export default ScheduleModal;