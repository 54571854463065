/* eslint-disable */
import {
    DELETE_SCHEDULED_MESSAGE_SUCCESS,
    FETCH_SCHEDULED_MESSAGES_BEGIN,
    FETCH_SCHEDULED_MESSAGES_FAILURE,
    FETCH_SCHEDULED_MESSAGES_SUCCES,
    UPDATE_SCHEDULED_MESSAGE_SUCCESS
} from "../actions/ScheduledMessagesActions";

const initialState = {
   loading:false,
   items:[],
   error:null,
}

export default function ScheduledMessageReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_SCHEDULED_MESSAGES_BEGIN:
            return {
                ...state,
                loading:true,
                error:null,
            }
        case FETCH_SCHEDULED_MESSAGES_SUCCES:
            return{
                ...state,
                error:null,
                loading:false,
                items:action.payload
            }
        case FETCH_SCHEDULED_MESSAGES_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload,
                items:[]
            }
        case UPDATE_SCHEDULED_MESSAGE_SUCCESS:
            return{
                ...state,
                item:state.items.map(sm=>{
                    if(sm.messageID==action.payload.messageID){
                        return action.payload;
                    }else{
                        return sm;
                    }
                }),
            }
        case DELETE_SCHEDULED_MESSAGE_SUCCESS:
            return{
                ...state,
                items:state.items.filter(sm=>sm.messageID!=action.payload),
            }
      
        default:
            return state;
    }
}