/* eslint-disable */
import React, {Component} from 'react'
import AuthServices from '../../Services/AuthServices';
import {toast} from 'react-toastify';
import NavbarFull from './NavbarFull';
import ReactLoading from 'react-loading'
import PhoneServices from '../../Services/PhoneNumberServices';
import {formatPhoneNumber} from '../../helpers/FormattingHelper';
import NotificationHelpers from "../../helpers/NotificationHelpers";
import TagManager from "react-gtm-module";

const authServices = AuthServices.getInstance();
const numberServices = PhoneServices.getInstance();

const tagManagerArgs = {
    gtmId: 'GTM-M96W495',
    dataLayer: {
        page: 'welcome'
    },
};

export default class FirstTimeLogin extends Component {
    constructor(props){
        super(props);
        this.state = {
            area_code:"",
            loading:false
        }
    }
    componentDidMount(){
        TagManager.initialize(tagManagerArgs);
        authServices.getProfile()
            .then(res => this.setState({
                profile:res
            }))
            .catch(err => {
                toast.error(NotificationHelpers.PROFILE_FETCH_ERROR)
            })
    }

    handleInputChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    handleFindNumbers=(e)=>{
        this.setState({loading: true});
        numberServices.getNumbers(this.state.area_code)
            .then(res => {
                this.setState({
                    numbers: res
                })
            }).catch(err=>{
                err.text()
                    .then(res=>{
                        // toast.error(JSON.parse(res).error)
                        toast.info("Please select a plan.");
                        this.props.history.push("/subscription")
                    })
            }).finally(() => {
                this.setState({loading: false});
            });
    };

    handlePhoneNumberPurchase=(phone)=>{
        this.setState({loading: true});
        numberServices.purchaseNumber(phone)
            .then(res => {
                toast.success(NotificationHelpers.sucessPhonePurchased(formatPhoneNumber(phone)));
                window.location.href="/app";
            })
            .catch(err=>{
                toast.error(NotificationHelpers.errorPhonePurchase(formatPhoneNumber(phone)))
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };

    render() {
        return (
            <div>
                <NavbarFull/>
                <div className=" mt-4">
                    <div className="row">
                        <div className="col-md-8 col-sm-12 offset-md-2 mt-4">
                            {!this.state.profile?
                                <ReactLoading type="spin"
                                color={'#000000'}
                                className={'centered'}
                                />:

                                <React.Fragment>
                                    <h2>Hello, {this.state.profile.name}</h2>
                                    <h5 className="text-muted">Please choose your P2P Messaging phone number.</h5>
                                    <p className="text-muted">Choose from our database of telephone numbers and assign your business its own texting phone number. </p>
                                    <p className="text-muted" style={{fontWeight:700}}>Cell phones cannot be text enabled.</p>
                                    <br/>

                                        <label className="mt-2">Enter area code to find a number</label>
                                    <div className="row">
                                        <div className="col-6 offset-md-3">
                                            <input className="form-control"
                                            type="phone"
                                            name="area_code"
                                            placeholder="E.g. 617 or 857"
                                            value={this.state.area_code}
                                            onChange={this.handleInputChange}/>
                                        </div>
                                        <div className="col-2">
                                            <button className="btn btn-primary"
                                                    onClick={this.handleFindNumbers}>Find</button>

                                        </div>
                                    </div>

                                    {this.state.loading?
                                        <div style={{'marginTop': '20px'}}>
                                            <ReactLoading type="spin"
                                                        color={'#000000'}
                                                        className={'centered'} />
                                        </div>
                                    :
                                    <div className="mt-4 mb-4">
                                        {this.state.numbers?
                                            <React.Fragment>
                                                {this.state.numbers.length===0?
                                                    <React.Fragment>
                                                        <h5>
                                                            No numbers available starting with {this.state.area_code}
                                                        </h5>
                                                    </React.Fragment>:
                                                    <React.Fragment>
                                                        <h5>Available numbers are:</h5>
                                                        <div className="list-group">

                                                        {this.state.numbers.map(number=>(
                                                            <button className="list-group-item list-group-item-action"
                                                                    onClick={(e)=>{
                                                                        e.preventDefault();
                                                                        this.handlePhoneNumberPurchase(number)
                                                                    }}>
                                                                {formatPhoneNumber(number)}
                                                            </button>
                                                        ))}
                                                        </div>

                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        :
                                        ""
                                        }
                                    </div>}

                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
