/* eslint-disable */
import {connect} from 'react-redux';
import {fetchAutoReply} from '../actions/autoReplyActions';
import AutoReply from '../components/AppComponents/AutoReply';

const stateToPropertyMapper = (state, props) => {
    return {
        autoReply:state.autoReply
    }
}

const propertyToDispatchMapper = (dispatch) => ({
     fetchAutoReply:()=>dispatch(fetchAutoReply()),
})


const AutoReplyContainer =  connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(AutoReply)

export default AutoReplyContainer;