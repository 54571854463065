/* eslint-disable */
import React from 'react'
import {getHost} from '../../helpers/url-helper';
import 'react-fancybox/lib/fancybox.css'
import MediaViewerModal from "./MediaViewerModal";
import ReactLoading from "react-loading";

export default function MediaViewer(props) {
    const [mediaModalShow, setMediaModalShow] = React.useState(false);
    if(!props.fileHash || props.fileHash===""){
        return (
            <ReactLoading type="spin"
                          color={'#000000'}
                          className={'centered'}
            />
        )
    }
    if(props.fileType==="" || props.fileType==="image"){
        return (
            <div >
                <img className="media-viewer-box"
                     src={getHost()+"/mms/"+props.fileHash}
                     width={320} alt={"Media"}
                     onClick={() => {setMediaModalShow(true)}}/>
                <MediaViewerModal
                    show={mediaModalShow}
                    onHide={() => setMediaModalShow(false)}
                    {...props}
                />
            </div>
        )
    }else if(props.fileType==="video"){
        return <div>
            <video className="img-thumbnail"  controls width={320} onClick={() => {setMediaModalShow(true)}}>
                <source src={getHost()+"/mms/"+props.fileHash} type="video/mp4"/>
                <source src={getHost()+"/mms/"+props.fileHash} type="video/ogg"/>
                Your browser does not support the video tag.
            </video>
            <MediaViewerModal
                show={mediaModalShow}
                onHide={() => setMediaModalShow(false)}
                {...props}
            />
        </div>
    }else if(props.fileType==="audio"){
        return <div>
                    <audio controls >
                    <source src={getHost()+"/mms/"+props.fileHash} type="audio/ogg"/>
                    <source src={getHost()+"/mms/"+props.fileHash} type="audio/mpeg"/>
                    <source src={getHost()+"/mms/"+props.fileHash} type="audio/wav"/>

                    Your browser does not support the audio tag.

                </audio>
                    <MediaViewerModal
                    show={mediaModalShow}
                    onHide={() => setMediaModalShow(false)}
                    {...props}
                    />
        </div>
    }else{
        return <div>
            Cannot preview the file.
        </div>
    }

}
