import React, {Component} from 'react';
import MessageServices from "../../Services/MessageServices";
import ReactLoading from "react-loading";
import OngoingCampaignItem from "./OngoingCampaignItem";
import QueuedMessageItem from "./QueuedMessageItem";
import {CAMPAIGN_DASHBOARD, createToast} from "../../helpers/ToastIdHelper";
import {toast} from "react-toastify";
import Dashboard7DaysColumnChart from "./Dashboard7DaysColumnChart";
import DashboardPastCampaignItem from "./DashboardPastCampaignItem";
import {sortTimeStamp} from "../../helpers/FormattingHelper";

class CampaignDashboard extends Component {
    propTypes = {};

    defaultProps = {};

    constructor(props){
        super(props);
        this.state={
            ongoingCampaigns:{
                loading:true,
                item:null,
                error:null,
            },
            queuedCampaigns:{
                loading:true,
                items:[],
                error: null,
            },
            pastCampaigns:{
                loading:true,
                items:[],
                error: null,
            },
            messageCounts:{
                loading:false,
                item:[],
                error:null,
            },
            collapsed:{
                sevenDayChart:false,
                ongoingCampaigns: false,
                queuedCampaigns: false,
                pastCampaign:false,
            },
            pastCampaignShowCount:10
        };
    };

    componentDidMount() {
        this.loadOngoingCampaigns();
        this.loadQueuedCampaigns();
        this.loadMessageCounts();
        this.loadPastCampaigns();

        this.timer = setInterval(()=>{
            this.loadQueuedCampaigns();
            this.loadOngoingCampaigns();
            this.loadMessageCounts();
            this.loadPastCampaigns();

        }, 5000);

    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async loadOngoingCampaigns(){
        MessageServices.getOngoingCampaignMessage()
            .then(res => {
                this.setState({
                    ongoingCampaigns:{
                        loading:false,
                        item:res,
                        error:null,
                    }
                })
            })
            .catch(err => {
                    this.setState({
                        ongoingCampaigns:{
                            loading:false,
                            item:null,
                            error:"Could not fetch ongoing campaigns.",
                        }
                    })
            })

    };

    async loadQueuedCampaigns(){
        MessageServices.getQueuedCampaignMessages()
            .then(res => {
                this.setState({
                    queuedCampaigns:{
                        loading:false,
                        item:res,
                        error:null,
                    }
                })
            })
            .catch(err => {
                    this.setState({
                        queuedCampaigns:{
                            loading:false,
                            item:null,
                            error:"Could not fetch queued campaigns.",
                        }
                })
            })

    };

    async loadPastCampaigns(){
        MessageServices.getPastCampaigns()
            .then(res => {
                this.setState({
                    pastCampaigns:{
                        loading:false,
                        items:res.filter(pm=>pm.message.id!==0),
                        error:null,
                    }
                })
            })
            .catch(err => {
                this.setState({
                    pastCampaigns:{
                        loading:false,
                        items:this.state.pastCampaigns.items,
                        error:"Could not fetch queued campaigns.",
                    }
                })
            })

    };

    async loadMessageCounts(){
        MessageServices.getMessageCounts()
            .then(res => {
                this.setState({
                    messageCounts:{
                        loading:false,
                        item:res,
                        error:null,
                    }
                })
            })
            .catch(err => {
                    this.setState({
                        messageCounts:{
                            loading:false,
                            item:null,
                            error:"Could not fetch message counts.",
                        }
                })
            })

    };

    deleteQueuedMessage=(id)=>{
        MessageServices.deleteQueuedMessage(id)
            .then(()=>{
                createToast(CAMPAIGN_DASHBOARD, "Message deleted.",toast.TYPE.SUCCESS);
                this.loadQueuedCampaigns();
            })
            .catch(err => {
                err.text().then(() => {
                    createToast(CAMPAIGN_DASHBOARD, "Cannot delete message.",toast.TYPE.ERROR);
                });
                this.loadQueuedCampaigns();
            });
    };

    handleOngoingDelete=(messageId)=>{
        MessageServices.deleteOngoingCampaign(messageId)
            .then(()=>{
                createToast(CAMPAIGN_DASHBOARD, "Ongoing campaign deleted.",toast.TYPE.SUCCESS);
                this.loadOngoingCampaigns();
                this.loadPastCampaigns();
                this.loadQueuedCampaigns();
            })
            .catch(err => {
                err.text().then(() => {
                    createToast(CAMPAIGN_DASHBOARD, "Cannot delete ongoing message.",toast.TYPE.ERROR);
                });
                this.loadOngoingCampaigns();
                this.loadPastCampaigns();
                this.loadQueuedCampaigns();
            });
    };

    render() {
        return (
            <div className={"container mt-4 text-left"}>
                <div className="card">
                    <div className="card-header" id="headingLast7">
                        <h3 className="mb-0">
                            Last 7 days
                            <button className="btn btn-link right" type="button" data-toggle="collapse"
                                    data-target="#collapseLast7" aria-expanded="true" aria-controls="collapseLast7"
                                    onClick={()=>{this.setState(
                                        {collapsed:{
                                                    ...this.state.collapsed,
                                                    sevenDayChart: !this.state.collapsed.sevenDayChart
                                        }})}}>
                                {this.state.collapsed.sevenDayChart?
                                    <i className="fas fa-caret-down fa-lg" title={"Expand"}/>:
                                    <i className="fas fa-caret-up fa-lg" title={"Collapse"}/>}
                            </button>
                        </h3>
                    </div>

                    <div id="collapseLast7" className="collapse show" aria-labelledby="headingOne"
                         data-parent="#ongoingCampaigns">
                        <div className="card-body " style={{backgroundColor:"#efefef",borderRadius:"0.25rem"}}>
                            <Dashboard7DaysColumnChart data={this.state.messageCounts.item}/>
                        </div>
                    </div>
                </div>
                <br/>

                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h3 className="mb-0">
                            Ongoing Campaigns

                            <button className="btn btn-link right" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                    onClick={()=>{this.setState(
                                {collapsed:{
                                        ...this.state.collapsed,
                                        ongoingCampaigns: !this.state.collapsed.ongoingCampaigns
                                    }})}}>
                                {this.state.collapsed.ongoingCampaigns?
                                    <i className="fas fa-caret-down fa-lg" title={"Expand"}/>:
                                    <i className="fas fa-caret-up fa-lg" title={"Collapse"}/>}
                            </button>
                        </h3>
                    </div>

                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                         data-parent="#ongoingCampaigns">
                        <div className="card-body " style={{backgroundColor:"#efefef",borderRadius:"0.25rem"}}>
                            {this.state.ongoingCampaigns.loading?
                                <ReactLoading type="spin"
                                              color={'#000000'}
                                              className={'centered'}
                                />:
                                this.state.ongoingCampaigns.item===null?
                                    "No ongoing campaigns.":
                                    <OngoingCampaignItem campaign={this.state.ongoingCampaigns.item}
                                                         deleteCampaign={this.handleOngoingDelete}/>
                            }
                        </div>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-header" id="headingTwo">
                        <h3 className="mb-0">
                            Queued Campaigns

                            <button className="btn btn-link right" type="button" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"
                                    onClick={()=>{this.setState(
                                        {collapsed:{
                                                ...this.state.collapsed,
                                                queuedCampaigns: !this.state.collapsed.queuedCampaigns
                                            }})}}>
                                {this.state.collapsed.queuedCampaigns?
                                    <i className="fas fa-caret-down fa-lg" title={"Expand"}/>:
                                    <i className="fas fa-caret-up fa-lg" title={"Collapse"}/>}
                            </button>
                        </h3>
                    </div>

                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo"
                         data-parent="#ongoingCampaigns">
                        <div className="card-body" style={{backgroundColor:"#efefef",borderRadius:"0.25rem"}}>
                            {this.state.queuedCampaigns.loading?
                                <ReactLoading type="spin"
                                              color={'#000000'}
                                              className={'centered'}
                                />:
                                this.state.queuedCampaigns.item.length===0?
                                    "No queued campaigns.":
                                    <React.Fragment>
                                        {this.state.queuedCampaigns.item.map(q=>
                                            <QueuedMessageItem {...q} onDelete={this.deleteQueuedMessage}/>
                                        )}
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                <br/>



                {/*    Past campaigns    */}
                    <div className="card">
                        <div className="card-header" id="pastCampaigns">
                            <h3 className="mb-0">
                                Past Campaigns

                                <button className="btn btn-link right" type="button" data-toggle="collapse"
                                        data-target="#collapsePast" aria-expanded="true" aria-controls="collapsePast"
                                        onClick={()=>{this.setState(
                                            {collapsed:{
                                                    ...this.state.collapsed,
                                                    pastCampaign: !this.state.collapsed.pastCampaign
                                                }})}}>
                                    {this.state.collapsed.pastCampaign?
                                        <i className="fas fa-caret-down fa-lg" title={"Expand"}/>:
                                        <i className="fas fa-caret-up fa-lg" title={"Collapse"}/>}
                                </button>
                            </h3>
                        </div>

                        <div id="collapsePast" className="collapse show" aria-labelledby="headingTwo"
                             data-parent="#ongoingCampaigns">
                            <div className="card-body" style={{backgroundColor:"#efefef",borderRadius:"0.25rem"}}>
                                {this.state.pastCampaigns.loading?
                                    <ReactLoading type="spin"
                                                  color={'#000000'}
                                                  className={'centered'}
                                    />:
                                    this.state.pastCampaigns.items!==null && this.state.pastCampaigns.items.length===0?
                                        "No past campaigns.":
                                        <React.Fragment>
                                            {sortTimeStamp(this.state.pastCampaigns.items,"last_sent_at",0)
                                                .slice(0,this.state.pastCampaignShowCount).map(q=>
                                                <DashboardPastCampaignItem {...q}/>
                                            )}
                                            {this.state.pastCampaignShowCount<this.state.pastCampaigns.items.length?
                                                <div className={"text-center"}>
                                                    <button className={"btn btn-secondary mt-2"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    pastCampaignShowCount: this.state.pastCampaignShowCount + 10,
                                                                })
                                                            }}>
                                                        Load more
                                                    </button>
                                                </div>
                                                :""
                                            }
                                        </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default CampaignDashboard;
