/* eslint-disable */
import {FETCH_PLANS_BEGIN, FETCH_PLANS_FAILURE, FETCH_PLANS_SUCCESS} from "../actions/SubscriptionActions";

const initialState = {
   loading:false,
   items:[],
   error:null,
}

export default function PlanReducer(state = initialState, action){
    switch(action.type){
        case FETCH_PLANS_BEGIN:
            return {
                ...state,
                loading:true
            }
        case FETCH_PLANS_SUCCESS:
            return {
                ...state,
                loading:false,
                items:action.payload
            }
        case FETCH_PLANS_FAILURE:
            return {
                ...state,
                loading:false,
                items:action.payload
            }
        default:
            return state
    }
}