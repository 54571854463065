import ContactServices from "../Services/ContactServices";
import {fetchConversationForContact} from "./conversationActions";

/************************************************************
 *                   Get Contact List                       *
 ************************************************************/

export const FETCH_CONTACTS_BEGIN = 'FETCH_CONTACTS_BEGIN';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

const contactServices = ContactServices.getInstance();

const fetchContactBegin = () => ({
    type: FETCH_CONTACTS_BEGIN,
});
const fetchContactsSuccess = (contacts) => ({
    type: FETCH_CONTACTS_SUCCESS,
    payload: contacts,
});

const fetchContactsFailute = (error) => ({
type: FETCH_CONTACTS_FAILURE,
payload: error,
});

export const fetchContacts = () => {
    return dispatch => {
        dispatch(fetchContactBegin());
        contactServices.getContacts(0)
            .then(res => {
                dispatch(fetchContactsSuccess(res));
            })
            .then(err => {
                dispatch(fetchContactsFailute(err));
            })
    }
};

/************************************************************
 *                   Get Contact By ID                      *
 ************************************************************/

export const FETCH_CONTACT_BY_ID_BEGIN = 'FETCH_CONTACT_BY_ID_BEGIN';
export const FETCH_CONTACT_BY_ID_SUCCESS = 'FETCH_CONTACT_BY_ID_SUCCESS';
export const FETCH_CONTACT_BY_ID_FAILURE = 'FETCH_CONTACT_BY_ID_FAILURE';


const fetchContactByIdBegin = (id) => ({
    type: FETCH_CONTACT_BY_ID_BEGIN,
    payload:{
        id:id
    }
});
const fetchContactByIdSuccess = (contact) => ({
    type: FETCH_CONTACT_BY_ID_SUCCESS,
    payload: contact,
});

const fetchContactByIdFailute = (error) => ({
type: FETCH_CONTACT_BY_ID_FAILURE,
payload: error,
});

export const fetchContactById = (id) => {
    return dispatch => {
        dispatch(fetchContactByIdBegin(id));
        contactServices.getContactById(id)
            .then(res => {
                dispatch(fetchContactByIdSuccess(res));
                dispatch(fetchConversationForContact(res.id))
            })
            .catch(err => {
                dispatch(fetchContactByIdFailute(err));
            })
    }
};

/************************************************************
 *                     Update Contact                       *
 ************************************************************/

export const UPDATE_CONTACT_BEGIN = 'UPDATE_CONTACT_BEGIN';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';

const updateContactBegin=()=>({
    type:UPDATE_CONTACT_BEGIN
});

const updateContactSuccess=(res)=>({
    type:UPDATE_CONTACT_SUCCESS,
    payload:res
});

const updateContactFailure=(err)=>({
    type:UPDATE_CONTACT_FAILURE,
    payload:err
});

export const updateContact=(id,contact)=>{
    return dispatch=>{
        dispatch(updateContactBegin());
        contactServices.updateContact(id,contact)
            .then(res=>{
                dispatch(updateContactSuccess(res));
                dispatch(fetchContactByIdSuccess(res));
            })
            .catch(err=>{dispatch(updateContactFailure(err))})
    }
};

/************************************************************
 *                    Add New Contact                       *
 ************************************************************/


export const ADD_CONTACT_BEGIN = "ADD_CONTACT_BEGIN";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";


const addContactBegin = () => ({
    type:ADD_CONTACT_BEGIN
});

const addContactSuccess = (res) =>({
    type:ADD_CONTACT_SUCCESS,
    payload:res
});

const addContactFailure = (err) => ({
    type:ADD_CONTACT_FAILURE,
    payload:err
});

export const addContact=(contact)=>{
    return dispatch=>{
        dispatch(addContactBegin(contact));
        contactServices.addContact(contact)
            .then(res => dispatch(addContactSuccess(res)))
            .catch(err => dispatch(addContactFailure(err)))
    }
};

/********************************************************** */
/*                        Delete a contact                  */
/********************************************************** */

// export const DELETE_CONTACT_BEGIN = "DELETE_CONTACT_BEGIN";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
// export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

// const deleteContactBegin=()=>({
//     type:DELETE_CONTACT_BEGIN    
// })

export const deleteContactSuccess=(res)=>({
    type:DELETE_CONTACT_SUCCESS,
    payload:res
});

// const deleteGroupFailure=(err)=>({
//     type:DELETE_GROUP_FAILURE,
//     payload:err
// })

// export const deleteGroup=(id,group)=>{
//     return dispatch=>{
//         dispatch(deleteGroupBegin())
//         groupServices.deleteGroup(id,group)
//             .then(res=>deleteGroupSuccess(res))
//             .catch(err=>deleteGroupFailure(err))
//     }
// }