/* eslint-disable */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AuthServices from '../../Services/AuthServices';
import {Link} from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import slyb from "../../assets/images/slyb.png";
import {storedResponderToken} from "../../Services/AuthServices";

const authServices = AuthServices.getInstance();

export default function Navbar(props) {
    return(
        <nav className="navbar shadow navbar-light bg-light">
            <a href="/app" className="nav-brand">
                <img src={logo} height="40" alt=""/>
                {props.profile.slybroadcast_customer?
                <React.Fragment>
                  &nbsp;  <img src={slyb} height="30" alt=""/>
                </React.Fragment>
                    :""}
            </a>
            <div className="inline">
                        <div className="btn-group dropdown">
                            <button type="button"
                                    className="btn btn-light round"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="Create Contact/Group"
                                    disabled={storedResponderToken()}>
                                        <i className="fas fa-plus text-muted"/>
                            </button>
                            <div className="dropdown-menu">
                                <Link   to="/contacts/new/edit"
                                        className="dropdown-item" href="#" >Create contact</Link>
                                <Link   to="/groups/new/edit"
                                        className="dropdown-item" href="#">Create group</Link>
                                {/*<Link   to="/import/contacts"*/}
                                {/*        className="dropdown-item" href="#">Bulk import contacts</Link>*/}
                                {props.profile.slybroadcast_customer?
                                    <Link   to="/slybroadcast/lists"
                                        className="dropdown-item" href="#">Import lists from slybroadcast</Link>
                                    :""
                                }
                            </div>
                        </div>
                        <button onClick={() => props.showContacts()}
                                className={props.activeContacts?"btn btn-secondary mx-1  round text-light":"btn btn-light mx-1 round text-muted"}
                                title="Contacts" disabled={storedResponderToken()}>
                            <i className="fas fa-user"/>
                        </button>

                        <button onClick={props.showGroups}
                                className={props.activeGroups?"btn btn-secondary mx-1  round text-light":"btn btn-light mx-1 round text-muted"}
                                title="Groups" disabled={storedResponderToken()}>
                            <i className="fas fa-users "/>
                        </button>

                <div className="btn-group dropdown">
                            <button type="button"
                                    className="btn btn-light round"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title={"Hi, "+props.profile.name}>
                                        <i className="fas fa-cog text-muted"/>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                {storedResponderToken()?
                                <Link className="dropdown-item "
                                onClick={(e)=>{
                                    e.preventDefault()
                                    authServices.signOut();
                                    window.location.href="/app";
                                }}><span className={"text-danger"}>Sign Out</span></Link>
                                :
                                <div>
                                    <h6 className="dropdown-header mt-2 pt-0"><i className="fas fa-chevron-circle-down"/> More Options</h6>
                                    <Link
                                        to="/campaign-dashboard"
                                        className="dropdown-item" href="#">Dashboard</Link>
                                    <Link
                                        to="/scheduled-messages"
                                        className="dropdown-item" href="#">Scheduled Messages</Link>
                                    <Link to="/auto-reply"
                                        className="dropdown-item" href="#">Auto Reply</Link>
                                    <Link to="/opt-out-list"
                                            className="dropdown-item" href="#">Opt-out List</Link>
                                    <Link to="/click-through-report"
                                          className="dropdown-item" href="#">Click Through Report</Link>





                                    <div className="dropdown-divider"/>
                                    <h6 className="dropdown-header"><i className="fas fa-user-cog"/> Account Settings</h6>

                                    <Link to="/account" className="dropdown-item" href="#">Profile</Link>
                                    <Link to="/subscription" className="dropdown-item" href="#">Subscription</Link>

                                    
                                    {/*<div className="dropdown-divider"/>*/}
                                    {/*<h6 className="dropdown-header"><i className="fas fa-question-circle"/> Help</h6>*/}
                                    {/*<a className="dropdown-item"*/}
                                    {/*href={"https://slytext.com/faq"}*/}
                                    {/*target="_blank"*/}
                                    {/*rel="noopener noreferrer">*/}
                                    {/*    FAQ*/}
                                    {/*</a>*/}
                                    {/*<a className="dropdown-item"*/}
                                    {/*href={"https://www.youtube.com/channel/UC9OU5hHH885nSjhz8NhEtSw"}*/}
                                    {/*target="_blank"*/}
                                    {/*rel="noopener noreferrer">*/}
                                    {/*    Video Tutorials*/}
                                    {/*</a>*/}


                                    <div className="dropdown-divider"/>
                                    <Link className="dropdown-item "
                                          to="#"
                                        onClick={(e)=>{
                                            e.preventDefault()
                                            authServices.signOut();
                                            window.location.href="/app";
                                        }}><span className={"text-danger"}>Sign Out</span></Link>
                                </div>
                                }
                            </div>
                        </div>


            </div>
        </nav>
    )
}
