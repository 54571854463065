/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class SmartReplyServices{
    static smartReplyServices = null;
    static getInstance(){
        if(this.smartReplyServices==null){
            this.smartReplyServices=new SmartReplyServices();
        }
        return this.smartReplyServices;
    }

    getSmartReplies(group_id){
        return http._get("/smart-reply/"+group_id)
            .then(res=>res)
            .catch(err => {throw err})
    }

    createSmartReplies(group_id,reply){
        return http._post("/smart-reply",{
            group_id:group_id,
            smart_replies:[reply]
        })
            .then(res=>res)
            .catch(err => {throw err})
    }

    UpdateSmartReply(smartReplyId,reply){
        http._put("/smart-reply/"+smartReplyId,reply)
            .then(res=>res)
            .catch(err => {throw err})
    }

    deleteSmartReply(smartReplyId){
        return http._delete("/smart-reply/"+smartReplyId)
                .then(res=>res)
                .catch(err => {throw err})
    }
}