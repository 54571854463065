import SubscriptionServices from "../Services/SubscriptionServices";

const subscriptionServices = SubscriptionServices.getInstance();

export const FETCH_CUSTOMER_BEGIN = "FETCH_CUSTOMER_BEGIN";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

/*************************************************
 *         Fetch stripe customer                 *
 *************************************************/
const fetchCustomerBegin=()=>({
    type:FETCH_CUSTOMER_BEGIN
});

const fetchCustomerSuccess=(res)=>({
    type:FETCH_CUSTOMER_SUCCESS,
    payload:res
});

const fetchCustomerFailure=(err)=>({
    type:FETCH_CUSTOMER_FAILURE,
    payload:err
});

export const fetchCustomer=()=>{
    return dispatch=>{
        dispatch(fetchCustomerBegin());
        subscriptionServices.getCustomer()
            .then(res => dispatch(fetchCustomerSuccess(res)))
            .catch(err => dispatch(fetchCustomerFailure(err)))
    }
};


/*************************************************
 *           Fetch prepaid plans                 *
 *************************************************/

export const FETCH_PLANS_BEGIN = "FETCH_PLANS_BEGIN";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";


const fetchPlansBegin=()=>({
    type:FETCH_PLANS_BEGIN
});

const fetchPlansSuccess=(res)=>({
    type:FETCH_PLANS_SUCCESS,
    payload:res
});

const fetchPlansFailure=(err)=>({
    type:FETCH_PLANS_FAILURE,
    payload:err
});


export const fetchPlans=()=>{
    return dispatch=>{
        dispatch(fetchPlansBegin());
        subscriptionServices.getPlans()
            .then(res => dispatch(fetchPlansSuccess(res)))
            .catch(err => dispatch(fetchPlansFailure(err)))
    }
};

