import React from 'react'
import {Redirect, Route} from "react-router-dom";
import AuthServices from '../Services/AuthServices';

const authServices = AuthServices.getInstance();
export const PrivateRoute = ({component: Component,...rest}) => (
    <Route
        {...rest}
        render = {props => 
            authServices.isLoggedIn()? (
                <Component {...props}/>
            ) : (
                <Redirect
                    to={{
                        pathname:"/signin",
                        state: {from:props.location}
                    }}
                    />
            )
        }
        />
);
    