/* eslint-disable */
import React from 'react'
import {formatTime} from "../../helpers/FormattingHelper";
import SmartReply from './SmartReply';


export default function ScheduleMessageListItem(props) {
    return (
        <li href="#" className="list-group-item flex-column align-items-start mx-2 mt-1 rounded p-3">
            <div className="d-flex w-100 justify-content-between">
                {props.fromConversation ?"": <h5 className="mb-1">To: {props.to}</h5>}
                <small>{formatTime(props.timestamp).replace(",","")}</small>
            </div>
            {props.edit?
                <textarea   className="form-control"
                            value={props.message}
                            rows={4}
                            onChange={(e)=>{
                                e.preventDefault();
                                props.onMessageChange(props.messageID,e.target.value)
                            }}>

                </textarea>
                :
                <p className="mb-1 text-left">{props.message}</p>
            }
            <span>
                <hr/>
                {props.edit ?
                    <div className="row p-0 m-0">
                        <div className="col-xs-4 col-sm-6 col-md-1 p-0">
                            <button className="col-12 btn btn-light text-primary text-left"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onSave(props.messageID, props.message);
                                    }}>Save
                            </button>
                        </div>
                        <div className="col-xs-4 col-sm-6  col-md-1 p-0">
                            <button className="col-md-12 btn btn-light text-secondary  text-left"
                                    onClick={props.onCancel}>Cancel
                            </button>
                        </div>

                    </div>
                    :
                    <div className="row p-0 m-0">
                        <div className="col-xs-4 col-sm-6 col-md-1 offset-md-9">
                            {props.is_group?
                                <button className={(props.showSmartReply==props.to_id ? "btn-danger btn round" : "btn-secondary round")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.onShowSmartReply(props.to_id);
                                        }}
                                        title="Smart reply">
                                    SR
                                </button>
                            : ""
                            }   
                        </div>
                        <div className="col-xs-4 col-sm-6 col-md-1">
                            <button className="col-12 btn btn-secondary btn-sm "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onEditClick(props.messageID);
                                    }}
                                    title={"Edit"}><i className="far fa-edit"></i>
                            </button>
                        </div>
                        <div className="col-xs-4 col-sm-6  col-md-1 text-center">
                            <button className="col-md-12 btn btn-danger btn-sm "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onDelete(props.messageID);
                                    }}
                                    title={"Delete"}><i className="fas fa-trash-alt"></i>
                            </button>
                        </div>

                    </div>
                }
                {(props.showSmartReply != undefined)?
                    <div
                        className={props.showSmartReply==props.to_id ? "slider smart-reply-container row bg-none"
                                                                    : "slider closed"}>
                        <div className="container">
                            <h5 className="font-weight-light">Smart Replies</h5>

                            <SmartReply smartReplies={props.smartReplies} cantEdit={true}/>
                        </div>
                    </div>
                    : ""
                }
            </span>
         </li>
    )
}
