import React from 'react'
import Chart from 'react-google-charts';

const DAYS = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
function last7Days(){
    let today = new Date();

    let days = [];
    for(let i=0;i<7;i++){
        days=[{date:today,dow:DAYS[today.getDay()]},...days];
        if(today.getDate()-1>today.getDate()) {
            today = new Date(today.getUTCFullYear(), today.getMonth()-1,today.getDate() - 1);
        }else{
            today = new Date(today.getUTCFullYear(), today.getMonth(),today.getDate() - 1);
        }


    }
    console.log('days: ', days);
    return days;
}
const Dashboard7DaysColumnChart = (props) => {
    let dayData = last7Days();
    dayData = dayData.map(day=>({date:day.date,dow:day.dow,incoming:0,outgoing:0}));
    const propData = props.data;
    for(let i=0;i<propData.length;i++) {
        const p = propData[i];
        for(let j=0;j<dayData.length;j++) {
            if (p.Dow.includes(dayData[j].dow)) {
                dayData[j].incoming= p.Incoming;
                dayData[j].outgoing= p.Outgoing;
            }
        }
    }

    const data = dayData.map(d=>[d.dow.charAt(0).toUpperCase() + d.dow.slice(1),d.outgoing,d.incoming]);
    return (
        <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={[
                ['Count', 'Outgoing Messages', 'Incoming Messages'],
                ...data,
            ]}
            options={{
                chartArea: { width: '60%' },
            }}

        />
    );
};

Dashboard7DaysColumnChart.propTypes = {};

Dashboard7DaysColumnChart.defaultProps = {};

export default Dashboard7DaysColumnChart;
