/* eslint-disable */

import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from 'react-router-dom';

export default function SlybImportSuccess(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{borderRadius:"1.5rem"}}
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Success!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"text-center"}>
                <i className="fas fa-check-circle fa-7x text-success"/>
                <h4>List Imported</h4>
                <p>
                    Imported groups:
                    {props.groups.map(g=>
                        <li className={"list-item"}><Link to={`/groups/${g.id}/edit`}>{g.name}</Link></li>
                    )}
                </p>
            </Modal.Body>

            <Modal.Footer style={{display:"block"}}>
                {/*<Row className={"pr-1"}>*/}
                {/*    <Col md={{span:3}} className={"p-1 pull-right"}>*/}
                {/*        <Button onClick={props.onHide}*/}
                {/*                variant={"secondary"}*/}
                {/*                className={"col-12"}>Cancel</Button>*/}
                {/*    </Col>*/}

                {/*</Row>*/}
            </Modal.Footer>
        </Modal>
    );
}
