import ConversationServices from "../Services/ConversationServices";
import {fetchScheduledMessagesForConversation} from "./ScheduledMessagesActions";


/************************************************************
 *                 Get Conversation List                    *
 ************************************************************/
export const FETCH_CONVERSATIONS_BEGIN = 'FETCH_CONVERSATIONS_BEGIN';
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS';
export const FETCH_CONVERSATIONS_FAILURE = 'FETCH_CONVERSATIONS_FAILURE';


const conversationServices = ConversationServices.getInstance();

const fetchConversationBegin = () => ({
    type: FETCH_CONVERSATIONS_BEGIN,
});
const fetchConversationsSuccess = (conversations) => ({
    type: FETCH_CONVERSATIONS_SUCCESS,
    payload: conversations,
});

const fetchConversationsFailute = (error) => ({
type: FETCH_CONVERSATIONS_FAILURE,
payload: error,
});

export const fetchConversations = (page=0) => {
    return dispatch => {
        dispatch(fetchConversationBegin());
        conversationServices.getConversations(page,1000)
            .then(res => {
                dispatch(fetchConversationsSuccess({first:page===0, result:res}));
                // if((res.length)>=1000){
                //     dispatch(fetchConversations(page+1000))
                // }
            })
            .then(err => {
                dispatch(fetchConversationsFailute(err));
            })

        
    }
};

/************************************************************
 *                 Get Conversation By ID                   *
 ************************************************************/

export const FETCH_CONVERSATION_BY_ID_BEGIN = "FETCH_CONVERSATION_BY_ID_BEGIN";
export const FETCH_CONVERSATION_BY_ID_SUCCESS = "FETCH_CONVERSATION_BY_ID_SUCCESS";
export const FETCH_CONVERSATION_BY_ID_FAILURE = "FETCH_CONVERSATION_BY_ID_FAILURE";

const fetchConversationByIDBegin = (id) => ({
    type: FETCH_CONVERSATION_BY_ID_BEGIN,
    payload:{
        id:id
    }
});

const fetchConversationByIDSuccess = (conversation) => ({
    type: FETCH_CONVERSATION_BY_ID_SUCCESS,
    payload: conversation
});

const fetchConversationByIDFailure = (error) => ({
    type: FETCH_CONVERSATION_BY_ID_FAILURE,
    payload: error
});

export const fetchConversationByID = (id) => {
    return dispatch => {
        dispatch(fetchConversationByIDBegin(id));
        conversationServices.getConversationById(id)
            .then(res => 
                dispatch(fetchConversationByIDSuccess(res)))
            .catch(err => 
                dispatch(fetchConversationByIDFailure(err)))
    }
};

/************************************************************
 *                   Get Conversation for group             *
 ************************************************************/

export const FETCH_CONVERSATION_FOR_GROUP_BEGIN = "FETCH_CONVERSATION_FOR_GROUP_BEGIN";
export const FETCH_CONVERSATION_FOR_GROUP_SUCCESS = "FETCH_CONVERSATION_FOR_GROUP_SUCCESS";
export const FETCH_CONVERSATION_FOR_GROUP_FAILURE = "FETCH_CONVERSATION_FOR_GROUP_FAILURE";

const fetchConversationForGroupBegin = (id) => ({
    type: FETCH_CONVERSATION_FOR_GROUP_BEGIN,
    payload:{
        id:id
    }
});

const fetchConversationForGroupSuccess = (conversation) => ({
    type: FETCH_CONVERSATION_FOR_GROUP_SUCCESS,
    payload: conversation
});

const fetchConversationForGroupFailure = (error) => ({
    type: FETCH_CONVERSATION_FOR_GROUP_FAILURE,
    payload: error
});

export const fetchConversationForGroup = (id) => {
    return dispatch => {
        dispatch(fetchConversationForGroupBegin(id));
        conversationServices.getConversationForGroup(id)
            .then(res => {
                dispatch(fetchConversationForGroupSuccess(res));
                dispatch(fetchConversationByID(res.id));
                dispatch(fetchScheduledMessagesForConversation(res.id));

            })
            .catch(err => 
                dispatch(fetchConversationForGroupFailure(err)))
    }
};



/************************************************************
 *                   Get Conversation for Contact           *
 ************************************************************/
export const FETCH_CONVERSATION_FOR_CONTACT_BEGIN = "FETCH_CONVERSATION_FOR_CONTACT_BEGIN" ;
export const FETCH_CONVERSATION_FOR_CONTACT_SUCCESS = "FETCH_CONVERSATION_FOR_CONTACT_SUCCESS";
export const FETCH_CONVERSATION_FOR_CONTACT_FAILURE = "FETCH_CONVERSATION_FOR_CONTACT_FAILURE";

const fetchConversationForContactBegin = (id) => ({
    type: FETCH_CONVERSATION_FOR_CONTACT_BEGIN,
    payload:{id:id}
});

const fetchConversationForContactSuccess = (conversation) => ({
    type: FETCH_CONVERSATION_FOR_CONTACT_SUCCESS,
    payload: conversation
});

const fetchConversationForContactFailure = (error) => ({
    type: FETCH_CONVERSATION_FOR_CONTACT_FAILURE,
    payload: error
});

export const fetchConversationForContact = (id) => {
    return dispatch => {
        dispatch(fetchConversationForContactBegin(id));
        conversationServices.getConversationForContact(id)
            .then(res => {
                dispatch(fetchConversationForContactSuccess(res));
                dispatch(fetchConversationByID(res.id));
                dispatch(fetchScheduledMessagesForConversation(res.id))
            })
            .catch(err => 
                dispatch(fetchConversationForContactFailure(err)))
    }
};

/************************************************************
 *                   Other message events                   *
 ************************************************************/

export const NEW_MESSAGE_RECEIVED = "NEW_MESSAGE_RECEIVED";
export const newMessageArrived = (conv_id,message) => ({
    type:NEW_MESSAGE_RECEIVED,
    payload:{
        id:conv_id,
        message:message
    }
});

export const NEW_MESSAGE_SENT = "NEW_MESSAGE_SENT";
export const newMessageSent = (conv_id,message,conv_name) => ({
    type:NEW_MESSAGE_SENT,
    payload:{
        id:conv_id,
        message:message,
        conv_name:conv_name,
    }
});


export const READ_CONVERSATION_MESSAGE = "READ_MESSAGE"
// export const readConversationMessage = (id) => ({
//     type:READ_CONVERSATION_MESSAGE,
//     payload:id
//
// });