/* eslint-disable */
import React from 'react'
import OptoutlistItem from './OptoutlistItem';
import {getDate} from "../../helpers/FormattingHelper";
import {CSVLink} from "react-csv";

export default function OptoutList(props) {
    return (
        <div className="row">
            <div className="col-md-10 col-sm-12 mt-4 offset-md-1">
                <CSVLink data={props.blocks.map(b=>({
                    Phone:b.Phone,
                    Time:getDate(b.CreatedAt),
                }))}
                         filename={"slytextOptoutList.csv"}
                         headers={[
                             { label: "Phone number", key: "Phone" },
                             { label: "Opt-out date", key: "Time" },
                         ]}>
                    <button className="btn btn-secondary btn-sm text-white mt-2">Download CSV</button>
                </CSVLink>
                <hr/>
                <div className={"row text-left"}>
                    <div className="col-6"><h5>Phone number </h5> <span className={"font-italic text-muted"}>{props.blocks.length} Number(s)</span></div>
                    <div className="col-6"><h5>Opt-out date</h5></div>
                </div>
                    {props.blocks.sort((a,b)=>new Date(b.CreatedAt)-new Date(a.CreatedAt)).map((block,index) => {

                        return <OptoutlistItem block={block} id = {index+1} key={block.ID}/>
                    })}
                <span className={"mt-1"}><hr/></span>
            </div>
        </div>
    )
}

