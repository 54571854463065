/* eslint-disable */

import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ConfirmationModal from "./ConfirmationModal";


function MessageQueueDeleteConfirmationModal(props) {
    return <ConfirmationModal
                {...props}
                alertMessage={"Are you sure you want to delete this campaign?"}
                note={"This action cannot be reverted."}
                onSubmit={()=>{
                    props.onSubmit(props.message.id)
                }}
                positiveButtonHead={"Yes"}
                negativeButtonHead={"Cancel"}
            />

}

export default MessageQueueDeleteConfirmationModal;