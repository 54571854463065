import React from 'react'
import PropTypes from 'prop-types';
import {capitalizeFirstLetter, formatPhoneNumber} from '../../helpers/FormattingHelper';


const JsontoTable = (props) => {
    if(props.json===undefined || props.json===null || props.json.length===0){
        return <>No member found.</>;
    }
    let cols = Object.keys(props.json[0]).filter(c=>c!=="#name" && c!=="#phone" && c!=="name" && c!=="phone");
    cols=["name","phone",...cols];
    let headerRow = cols.map((col)=><th>{col.split("_").map(c=>
                                   capitalizeFirstLetter(c)).join(" ")}</th>);
    let bodyRows = props.json.map((row)=>{
        return(
            <tr>
                <td>
                    {props.selectedMembers.includes(row['phone']) ?
                        <button style={{backgroundColor: "#fff"}}
                                title={"Select all"}
                                onClick={()=>{props.removeSelected(row)}}>
                            <i className="far fa-check-square fa-lg"/>
                        </button> :
                        <button style={{backgroundColor: "#fff"}}
                                title={"Select all"}
                                onClick={()=>{props.addSelected(row)}}>
                            <i className="far fa-square fa-lg"/>
                        </button>
                    }

                </td>
                {cols.map((colname)=><td>
                    {colname==="phone"?formatPhoneNumber(row[colname]):row[colname]}
                </td>)}
            </tr>);
    });
    return(
            <>
                <h5>{props.json.length} member(s)</h5>

                <table className="table">
                    <thead className={"thead-light"}>
                    <tr>

                        <th style={{width: "50px"}}>
                            <button style={{backgroundColor:"#e9ecef"}}
                                    title={"Select all"}
                                    onClick={props.toggleSelectAll}>
                                {props.selected?
                                    <i className="far fa-minus-square fa-lg"/>
                                    :<i className="far fa-square fa-lg"/>
                                }
                            </button>
                        </th>

                        {headerRow}
                    </tr>
                    </thead>
                    <tbody>
                        {bodyRows}
                    </tbody>
                </table>
            </>
    );
};

JsontoTable.propTypes = {
    deleteMemberByPhone:PropTypes.func,
    toggleSelectAll:PropTypes.func,
    addSelected:PropTypes.func,
    removeSelected:PropTypes.func
};

JsontoTable.defaultProps = {};

export default JsontoTable;
