/* eslint-disable */
import {ADD_GROUP_SUCCESS, FETCH_GROUP_BY_ID_BEGIN, FETCH_GROUP_BY_ID_SUCCESS} from "../actions/groupActions";
import {
    ADD_CONTACT_SUCCESS,
    FETCH_CONTACT_BY_ID_BEGIN,
    FETCH_CONTACT_BY_ID_SUCCESS,
    UPDATE_CONTACT_SUCCESS
} from "../actions/contactActions";
import {
    FETCH_CONVERSATION_BY_ID_BEGIN,
    FETCH_CONVERSATION_BY_ID_SUCCESS,
    FETCH_CONVERSATION_FOR_CONTACT_BEGIN,
    FETCH_CONVERSATION_FOR_CONTACT_SUCCESS,
    FETCH_CONVERSATION_FOR_GROUP_BEGIN,
    FETCH_CONVERSATION_FOR_GROUP_SUCCESS,
    NEW_MESSAGE_SENT
} from "../actions/conversationActions";
import {POST_SMS_BEGIN, POST_SMS_FAILURE, POST_SMS_SUCCESS} from "../actions/messageActions";
import {
    FETCH_SCHEDULED_MESSAGES_CONVERSATION_BEGIN,
    FETCH_SCHEDULED_MESSAGES_CONVERSATION_SUCCES
} from "../actions/ScheduledMessagesActions";

const initialState = {
    selectedGroup:{},
    selectedContact:{},
    messages:{
        loading:false,
        items:[],
    },
    scheduledMessages:{
        loading:false,
        items:[]
    },
    conversation:{},
    postMessage:{
        error:false,
        errorString:null,
    }
}

export default function ChatReducer(state = initialState, action){
switch(action.type) {
    case FETCH_GROUP_BY_ID_BEGIN:
        if(state.selectedGroup.id===action.payload.id){
            return{
                ...state,
                loading:true,
                postMessage:{
                    error:false,
                    errorString:null,
                }
            }
        }
        return{
            ...state,
            selectedGroup:{},
            selectedContact:{},
            messages:{
                loading:true,
                items:[],
            },
            loading:true,
            conversation:{},
            postMessage:{
                error:false,
                errorString:null,
            },
            scheduledMessages:{
                loading:false,
                items:[]
            },
        }

    case FETCH_GROUP_BY_ID_SUCCESS:
        if(state.selectedGroup.id!==action.payload.id){
            return{
                ...state,
                selectedGroup:action.payload,
                messages:{
                    loading:true,
                    items:state.messages.items,
                },
                loading:false,

            }
        }else{
            return {
                ...state,
                selectedGroup:action.payload,
                loading:false,
            }
        }

    case FETCH_CONVERSATION_FOR_GROUP_BEGIN:
        if(state.selectedGroup.id!==action.payload.id){
            return{
                ...state,
                loading:true,
                messages:{
                    loading:true,
                    items:[],
                },
                postMessage:{
                    error:false,
                    errorString:null,
                },
                scheduledMessages:{
                    loading:false,
                    items:[]
                },
            }
        }
        return{
            ...state,
            loading:false,
            conversation:{},
            postMessage:{
                error:false,
                errorString:null,
            },
            scheduledMessages:{
                loading:false,
                items:[]
            },
        }
    case FETCH_CONVERSATION_FOR_GROUP_SUCCESS:
        if(state.conversation.id!==action.payload.id){
            return{
                ...state,
                conversation:action.payload,
                messages:{
                    loading:true,
                    items:[],
                },
                loading:false,
            }
        }else{
            return {
                ...state,
                conversation:action.payload,
                loading:false,
            }
        }

   
   
   
    case FETCH_CONTACT_BY_ID_BEGIN:
        if(state.selectedContact.id===action.payload.id){
            return{
                ...state,
                loading:true,
                postMessage:{
                    error:false,
                    errorString:null,
                }
            }
        }
        return{
            ...state,
            selectedGroup:{},
            selectedContact:{},
            messages:{
                loading:true,
                items:state.messages.items,
            },
            loading:true,
            conversation:{},
            postMessage:{
                error:false,
                errorString:null,
            },
            scheduledMessages:{
                loading:false,
                items:[]
            },
        }
        
    case FETCH_CONTACT_BY_ID_SUCCESS:
        if(state.selectedContact.id!==action.payload.id){
            return{
                ...state,
                selectedContact:action.payload,
                messages:{
                    loading:true,
                    items:state.messages.items,
                },
                loading:false,
            }
        }else{
            return {
                ...state,
                selectedContact:action.payload,
                loading:false,
            }
        }

    case FETCH_CONVERSATION_FOR_CONTACT_BEGIN:
        if(state.selectedContact.id!==action.payload.id){
            return{
                ...state,
                loading:true,
                messages:{
                    loading:true,
                    items:[],
                },
                postMessage:{
                    error:false,
                    errorString:null,
                },
                scheduledMessages:{
                    loading:false,
                    items:[]
                },
            }
        }
        return{
            ...state,
            loading:false,
            conversation:{},
            postMessage:{
                error:false,
                errorString:null,
            }
        }
    case FETCH_CONVERSATION_FOR_CONTACT_SUCCESS:
        if(state.conversation.id!==action.payload.id){
            return{
                ...state,
                conversation:action.payload,
                messages:{
                    loading:true,
                    items:[],
                },
                loading:false,
                postMessage:{
                    error:false,
                    errorString:null,
                },
                scheduledMessages:{
                    loading:false,
                    items:[]
                },
            }
        }else{
            return {
                ...state,
                conversation:action.payload,
                loading:false,
            }
        }
        
    


    case FETCH_CONVERSATION_BY_ID_BEGIN:
        return {
            ...state,
            postMessage:{
                error:false,
                errorString:null,
            }
        }
        
    case FETCH_CONVERSATION_BY_ID_SUCCESS:
        return{
            ...state,
            messages:{
                loading:false,
                items:action.payload
            }
        }


    case FETCH_SCHEDULED_MESSAGES_CONVERSATION_BEGIN:
        return {
            ...state,
            scheduledMessages:{
                loading:true,
                items:[]
            }
        }
    case FETCH_SCHEDULED_MESSAGES_CONVERSATION_SUCCES:
        return{
            ...state,
            scheduledMessages:{
                loading:false,
                items:action.payload
            }
        }


    case ADD_CONTACT_SUCCESS:
        return{
            ...state,
            selectedContact:action.payload
        }
    case ADD_GROUP_SUCCESS:
        return{
            ...state,
            selectedGroup:action.payload
        }
    case UPDATE_CONTACT_SUCCESS:
        return{
            ...state,
            selectedContact:action.payload
        }
    case POST_SMS_SUCCESS:
        return{
            ...state,
            messages:{
                loading:false,
                items:[...state.messages.items,action.payload]
            },
            postMessage:{
                error:false,
                errorString:null,
            }
        }
    case NEW_MESSAGE_SENT:
        if(state.conversation.id===action.payload.id){
            let message = action.payload.message
            message.timestamp=new Date().toISOString()
            message.direction="O"
            return {
                ...state,
                messages:{
                    loading:false,
                    items:[...state.messages.items,message]
                },
                postMessage:{
                    error:false,
                    errorString:null,
                }
            }

        }else{
            return state;
        }
    case POST_SMS_BEGIN:
        return {
            ...state,
            postMessage:{
                error:false,
                errorString:null,
            }
        }
    case POST_SMS_FAILURE:
        return {
            ...state,
            postMessage:{
                error:true,
                errorString:action.payload,
            }
        }
    default:
       return state
}
}

 