/* eslint-disable */
import React, {Component} from 'react';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import {Button} from "react-bootstrap";
import ReactLoading from "react-loading";
import SlybListModal from "./SlybListModal";

class SlybListsComponent extends Component {

    constructor(props){
        super(props);
        this.state={
            modalShow:false,
        }
    }
    propTypes = {};

    componentWillMount() {
        this.props.fetchLists();
    }

    defaultProps = {};

    isGroupImported=(slybListId)=>{
        let group = this.props.groups.items.filter(g => g.slybroadcast_list_id===slybListId);
        return group.length>0;
    };

    render() {
        return (
            <div className="message-container">
                <div className="conversation-container">
                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow">
                        <div className="row p-2 px-4">
                            <div className="p-2  col-8 text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            slybroadcast Lists
                                        </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 text-white text-black-50 mt- pl-4 pr-4 rounded text-left">
                              <p>Click on the list name below to import the list from your slybroadcast account.
                             Any phone numbers on your slybroadcast account's Do Not Dial list will not be texted.</p>
                        </div>
                    {/* Titlebar end */}
                    <div className={"optout-list-container p-4"}>
                        {this.props.lists.loading?
                            <ReactLoading type="spin"
                                          color={'#000000'}
                                          className={'centered'}
                            />
                            :
                            <div className="list-group col-md-8">
                               {this.props.lists.items.map(list=>
                                   <li
                                       className="list-group-item list-group-item-action
                                                    flex-column align-items-start cursor-pointer"
                                       onClick={(e)=>{
                                           e.preventDefault();
                                           this.setState({selectedList:list,
                                                                modalShow:true})}
                                       }
                                        title={"Import list to P2P Messaging"}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h4 className="mb-1">{list.list_name}</h4>
                                            <small className={"font-"}>
                                                {list.date}
                                                <br/>
                                                {list.number_of_phone} phone number(s)
                                            </small>
                                        </div>

                                    </li>
                                )}
                                <SlybListModal show={this.state.modalShow}
                                               onHide={() => {this.setState({modalShow:false})}}
                                               list={this.state.selectedList}
                                               fetchGroups={this.props.fetchGroups}
                                               isImported={this.isGroupImported}
                                               />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SlybListsComponent;
