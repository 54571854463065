/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();

export default class SlybroadcastListServices {
    static slybroadcastListServices = null;

    static getInstance() {
        if (this.slybroadcastListServices == null) {
            this.slybroadcastListServices = new SlybroadcastListServices();
        }
        return this.slybroadcastListServices;
    }

    static getLists(){
        return http._get("/slybroadcast/lists")
            .then(res=>res)
            .catch(err=>{throw err});
    }

    static getListPhones(listId){
        return http._get(`/slybroadcast/lists/${listId}`)
            .then(res=>res)
            .catch(err=>{throw err});
    }

    static importList(listId, name){
        return http._post(`/slybroadcast/lists/${listId}/import`, {list_name:name.length>0?name:listId})
            .then(res=>res)
            .catch(err => {throw err})
    }
}