/* eslint-disable */

import React, {Component} from 'react'
import Switch from "react-switch";
import "../../assets/style/autoreply.css"
import MessageServices from '../../Services/MessageServices';
import {createUpdateArSuccess} from '../../actions/autoReplyActions';
import {toast} from 'react-toastify';
import NotificationHelpers from "../../helpers/NotificationHelpers";

export default class AutoReply extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ...this.props.autoReply.item,
            message:this.props.autoReply.item.message&&this.props.autoReply.item.message!==""?
                            this.props.autoReply.item.message:
                            ""
        };
    }

    componentDidMount(){
        this.props.fetchAutoReply();
    }

    componentWillReceiveProps(props){
        this.setState({
            ...props.autoReply.item,
            message:this.props.autoReply.item.message&&this.props.autoReply.item.message!==""?
                            this.props.autoReply.item.message:
                            ""
        })
    }

    handleChange=(checked)=> {
        this.setState({ enabled: checked });
    };


    handleInputChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    };

    handleSave=(e)=>{
        e.preventDefault();
        const messageServices = MessageServices.getInstance();
        messageServices.createUpdateAutoReply(this.state)
            .then(() => {
                createUpdateArSuccess(this.state);
                toast.success(NotificationHelpers.SUCCESSFUL_AUTO_REPLY_SAVE);
            })
            .catch(() => {
                toast.error(NotificationHelpers.ERROR_AUTO_REPLY_SAVE)
            })
    };

    render() {
        return (
            <div className="message-container">
                <div className="conversation-container">

                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow">
                        <div className="row p-2">
                            <div className="col-12">
                                <div className="p-2  text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Auto reply
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Titlebar end */}

                    <div className=" p-4 text-left optout-list-container">
                        <p className={"col-sm-12 col-md-6 p-0 text-dark my-2"}>
                            <i className="fas fa-info-circle"/>
                            When your auto reply is enabled, anyone who responds to your message will automatically
                            receive the response you enter below.</p>

                        <div className={"row px-3 py-0"}>
                            <label className="text-dark" style={{position:"relative",top:"12px"}}>Enable:</label>
                            <span className="toggle">
                                <Switch onChange={this.handleChange} checked={this.state.enabled} />
                            </span>
                        </div>

                        <form className="col-sm-12 col-md-6 p-0 mt-4">
                            <label htmlFor="message" className="text-dark">Message:</label>
                            <textarea   className="form-control"
                                        placeholder="Thank you! I will get back to you shortly!"
                                        rows={4}
                                        value={this.state.message}
                                        name="message"
                                        onChange={this.handleInputChange}
                                        readOnly={!this.state.enabled}/>
                        </form>
                    </div>
                       
                    <div className=" settings-button-footer">
                        <div className="row p-2">
                            <div className="col-sm-12 col-md-2 p-1 offset-md-10">
                                <button className="col-12 btn btn-primary "
                                        onClick={this.handleSave}>
                                        Save
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}
