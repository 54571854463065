/* eslint-disable */

import React, {Component} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import {Col, Row} from "react-bootstrap";
import {capitalizeFirstLetter} from "../../helpers/FormattingHelper";
import {formatPhoneNumber} from "../../helper";
import {toast} from "react-toastify";
import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/Spinner";
import RedirectURLServices from "../../Services/RedirectURLServices";
import {compileCSVContent} from "../../helpers/CsvDataParser";
import "../../assets/style/click-through-report.css";
import "react-datepicker/dist/react-datepicker.css";


const DateFromInput = ({value, onClick}) => (
    <input className="form-control mt-2"
           placeholder={"Date From"}
           onClick={onClick}
           value={value}
           type={"text"}
           style={{flex: 1}}/>
);
const DateToInput = ({value, onClick}) => (
    <input className="form-control mt-2"
           placeholder={"Date To"}
           onClick={onClick}
           value={value}
           type={"text"}
           style={{flex: 1}}/>
);

export default class ClickThroughReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            cols: [],
            tableColumns: [],
            tableData: [],
            filteredTableData: [],
            startDate: new Date(moment().subtract(1, "month").format("YYYY-MM-DD")),
            endDate: new Date(),
            domain: "",
        };
    }

    componentDidMount() {
        this.props.fetchDomains();
    }

    handleSearchChange = (e) => {
        const searchString = e.target.value;
        const {tableData, cols} = this.state;
        const filteredTableData = tableData.filter(record => {
                let match = false;
                for (let i = 0; i < cols.length; i++) {
                    match = record[cols[i]].toLowerCase().includes(searchString.trim().toLowerCase());
                    if (match) break;
                }
                return match;
            }
        );
        this.setState({
            searchString,
            filteredTableData,
        });
    };

    clickThruReport = (domain, dateFrom, dateTo) => {
        if (domain !== "") {
            this.setState({loading: true});
            RedirectURLServices.getClickThruReport(domain, dateFrom, dateTo)
                .then((data) => {
                    const cols = Object.keys(data[0]).filter(c => c !== "#name" && c !== "#phone" && c !== "name" && c !== "phone");
                    this.setState({
                        cols: cols,
                        tableColumns: cols.map(col => {
                            return {
                                name: capitalizeFirstLetter(col.replace(/_/g, " ")),
                                selector: col === "phone" ? "formattedPhone" : col,
                                wrap: true
                            };
                        }),
                        tableData: data.map(record => {
                            return {
                                ...record,
                                formattedPhone: formatPhoneNumber(record.phone),
                            };
                        })
                    });
                    this.setState({
                        clickThruReportData: Array.isArray(data) ? data : [],
                        loading: false
                    });
                })
                .catch(e => {
                    this.setState({loading: false});
                    toast.error("Could not fetch click thru report data.");
                });
        } else {
            this.setState({clickThruReportData: []});
        }
    };

    exportToExcel = () => {
        const json = this.state.clickThruReportData;
        const {filteredTableData, searchString} = this.state;
        const encodedUri = encodeURI(compileCSVContent(searchString === "" ? json : filteredTableData));
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "click-thru-report.csv");
        document.body.appendChild(link);
        link.click();
    };

    render() {
        const {loading} = this.state;
        const {domains} = this.props;
        const options = [<option style={{display: "none"}}/>, ...domains.map(({domain}) => <option key={domain}
                                                                                                   value={domain}>{domain}</option>)];
        const {tableColumns, searchString, filteredTableData, tableData} = this.state;

        return (
            <div className="message-container">
                <div className="conversation-container">

                    {/* Titlebar start */}
                    <div className="conversation-title-container text-light shadow">
                        <div className="row p-2">
                            <div className="col-12">
                                <div className="p-2  text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Click Through Report
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Titlebar end */}

                    <div className="click-through-report-container">
                        <Row>
                            <Col sm={4} md={3}>
                                <div>Date from</div>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={startDate => {
                                        this.setState({startDate}, () => {
                                            this.clickThruReport(this.state.domain, this.state.startDate, this.state.endDate);
                                        });
                                    }}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    customInput={<DateFromInput/>}
                                    maxDate={this.state.endDate}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <div>Date to</div>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={endDate => {
                                        this.setState({endDate}, () => {
                                            this.clickThruReport(this.state.domain, this.state.startDate, this.state.endDate);
                                        });
                                    }}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    minDate={this.state.startDate}
                                    customInput={<DateToInput/>}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <div style={{paddingBottom: "7px"}}>Domain</div>
                                <select className="form-control"
                                        id="campaignAgentCount"
                                        name="agent_count"
                                        onChange={e => {
                                            this.setState({domain: e.target.value}, () => {
                                                this.clickThruReport(this.state.domain, this.state.startDate, this.state.endDate);
                                            });
                                        }}
                                        required
                                >
                                    {options}
                                </select>
                            </Col>
                        </Row>

                        <input className={"form-control mt-2"}
                               placeholder={"Search report"}
                               onChange={this.handleSearchChange}
                               value={this.state.searchString}
                               type={"text"}
                               disabled={loading || tableData.length === 0}
                               style={{flex: 1}}/>

                        <br/>

                        {!loading ? <div style={{overflowX: "auto"}}>
                                <DataTable
                                    columns={tableColumns}
                                    theme="solarized"
                                    data={searchString ? filteredTableData : tableData}
                                    selectableRows={false}
                                    pagination
                                    paginationRowsPerPageOptions={[5, 10, 50, 100]}
                                    striped
                                    noHeader
                                    paginationPerPage={5}
                                />
                            </div>
                            : <Row className="justify-content-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </Row>
                        }

                    </div>
                    {!loading && <div style={{margin: "15px"}}>
                        <button className="btn btn-primary" onClick={this.exportToExcel}
                                disabled={searchString !== "" ? filteredTableData.length === 0 : tableData.length === 0}>Export
                        </button>
                    </div>}

                </div>
            </div>
        );
    }
}
