
import React from 'react';
import { Modal} from 'react-bootstrap';
import {capitalizeFirstLetter} from "../../helpers/FormattingHelper";


function KeywordSuggestionItem(suggestion,onSelect){
    return (
        <div className={"row px-4"} key={suggestion}>
            <div className={"col-1"}>
                <button className={"btn btn-sm btn-outline-primary round mr-2 mb-2"}
                        style={{width:"30px",height:"30px", fontSize:"15px",minWidth:"30px"}}
                        onClick={()=>{onSelect()}}
                        title={"Insert"}><i className="fas fa-plus"/></button>
            </div>
            <div className={"col-6 pt-1"}>
                    {capitalizeFirstLetter(suggestion)}
            </div>
            <div className={"col-5 pt-1"}>
                {"#"+suggestion}
            </div>
        </div>
    )
}
function SuggestionModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{borderRadius: "1.5rem"}}>

                {/* Modal header */}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Insert custom fields
                    </Modal.Title>
                </Modal.Header>

                {/* Modal body */}
                <Modal.Body style={{overflowX: "auto"}}>
                    <p>Insert merge tags to customize texts specific to your contacts.
                    </p>
                    <br/>
                    <div className={"row px-4"}>
                        <div className={"col-7"}>
                         Custom field
                        </div>
                        <div className={"col-5"}>
                           Merge tag
                        </div>
                    </div>
                    <hr/>
                    <div>
                        {props.suggestions.map(s=>KeywordSuggestionItem(s,()=>{props.onSelect(s)}))}
                    </div>
                </Modal.Body>


            </Modal>
        );
    }

export default SuggestionModal;