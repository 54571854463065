/* eslint-disable */
import React from 'react'
import {Link} from "react-router-dom";
import {storedResponderToken} from "../../Services/AuthServices";


export default function ConversationTItleBar(props) {
    return (
        <div className="conversation-title-container text-light shadow ">
            <div className="p-0">
                <div className="row p-1 align-middle">
                    <div className="col-11 text-left">
                        <Link   to="/"
                                className="btn round d-md-none">
                            <i className="fas fa-arrow-left"></i>
                        </Link>
                        <img className=" p-2 round " 
                            height={50}
                            src={props.displayImage}
                            alt=""/>
                        <span className="text-light text-left title">
                            {props.heading}
                            <span className={"ml-1"}  title="Broadcast group">{props.isGroup? <i className="fas fa-bullhorn"></i>:""}</span>
                        </span>
                    </div>
                    <div className="col-1">
                        <div className="btn-group dropleft right">
                            <button 
                                    className="btn mt-2 ml-2 round right" 
                                    data-toggle="dropdown" 
                                    aria-haspopup="true" 
                                    aria-expanded="false"
                                    disabled={storedResponderToken()}>
                                        <i className="fas fa-ellipsis-v text-light"></i>
                            </button>
                            <div className="dropdown-menu">
                                {/* <a className="dropdown-item" href="#">Clear Conversation</a> */}
                                {/* <div className="dropdown-divider"></div> */}
                                <Link   to={"/"+(props.isGroup?"groups/":"contacts/")+props.id+"/edit"}
                                        className="dropdown-item" href="#">Settings</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
    

