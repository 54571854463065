/* eslint-disable */
import React, {Component} from 'react';
import ReactLoading from 'react-loading';
import {toast} from 'react-toastify';
import MessageServices from '../../Services/MessageServices';
import ScheduleMessageListItem from './ScheduleMessageListItem';
import PropTypes from 'prop-types';
import {sortTimeStamp} from "../../helpers/FormattingHelper";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import '../../assets/style/scheduled-messages.css';
import SmartReplyServices from '../../Services/SmartreplyServices';

const smartReplyServices = SmartReplyServices.getInstance();

class ScheduledMessages extends Component {
    constructor(props){
        super(props);
        this.state = {
            edit:-1,
            scheduledMessages:this.props.scheduledMessages,
            showSmartReply:-1,
            smartReplies:[]
        }
        this.onShowSmartReply = this.onShowSmartReply.bind(this);
    }
    componentDidMount(){
        if(this.props.fetchScheduledMessages)
            this.props.fetchScheduledMessages();
    }

    componentWillReceiveProps(props){
        this.setState({
            edit:-1,
            scheduledMessages:props.scheduledMessages,
        })
    }

    handleEditClick=(id)=>{
        this.setState({
            edit:id,
        })
    };

    handleEditCancel=()=>{
        this.setState({
            edit:-1,

        });
        if(this.props.fetchScheduledMessages)
            this.props.fetchScheduledMessages();

    };

    handleDelete=(id)=>{
        const messageServices = MessageServices.getInstance();
        messageServices.deleteScheduledMessage(id)
            .then(res => {
                toast.success(NotificationHelpers.SUCCESS_SCHEDULED_MESSAGE_DELETE)
                this.props.deleteScheduledMessage(id)
            })
            .catch(err => {
                toast.error(NotificationHelpers.ERROR_DELETE_SCHEDULED_MESSAGE);
            })
    };
    handleEditMessageChange=(id,message)=>{
        this.setState({
            scheduledMessages:{
                ...this.state.scheduledMessages,
                items:this.state.scheduledMessages.items.map(sm=>{
                    if(sm.messageID===id){
                        sm.message=message;
                    }
                    return sm
                })
            }
        })
    };
    handleSave=(id,message)=>{
        const messageServices = MessageServices.getInstance();
        messageServices.updateScheduledMessage(id,{messageText:message})
            .then(res => {
                toast.success(NotificationHelpers.SUCCESS_UPDATE_SCHEDULED_MESSAGE);
                this.setState({
                    edit:-1
                })
            })
            .catch(err => {
                toast.error(NotificationHelpers.ERROR_UPDATE_SCHEDULED_MESSAGE);
            })
    };
    onShowSmartReply(groupID){
        if (groupID == 0) return;
        let currentlyShowing = this.state.showSmartReply == groupID;
        this.setState({
            showSmartReply: currentlyShowing? -1 : groupID
        });
        smartReplyServices.getSmartReplies(groupID)
            .then(res => {this.setState({
                smartReplies:res
            })});
    };
    render() {
        return (
            <React.Fragment>
                        {/* Titlebar end */}
                        <div className={"scheduled-message-container"}>
                            {this.props.scheduledMessages.loading?
                                <ReactLoading type="spin"
                                    color={'#000000'}
                                    className={'centered'}
                                />
                                :
                                <ul className="list-group m-4">
                                    {sortTimeStamp(this.state.scheduledMessages.items,"timestamp").map(sm =>
                                        <ScheduleMessageListItem {...sm}
                                                                 edit={this.state.edit===sm.messageID}
                                                                 onEditClick={this.handleEditClick}
                                                                 onCancel={this.handleEditCancel}
                                                                 onMessageChange={this.handleEditMessageChange}
                                                                 onSave={this.handleSave}
                                                                 onDelete={this.handleDelete}
                                                                 key={sm.messageID}
                                                                 fromConversation={this.props.fromConversation}
                                                                 onShowSmartReply={this.onShowSmartReply}
                                                                 showSmartReply={this.state.showSmartReply}
                                                                 smartReplies={this.state.smartReplies}
                                                                >
                                                                 </ScheduleMessageListItem>)}
                                </ul>
                            }
                        </div>
                        {this.props.scheduledMessages.items.length===0?
                            <span className="text-muted"> No scheduled messages.</span>
                            :""
                        }
                </React.Fragment>
        )
    }
}


export default ScheduledMessages;

ScheduledMessages.propTypes = {
  deleteScheduledMessage: PropTypes.any,
  fetchScheduledMessages: PropTypes.any,
  scheduledMessages: PropTypes.any.isRequired
};