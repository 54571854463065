/* eslint-disable */

import React, {Component} from "react";
import GroupEditor from "../components/AppComponents/GroupEditor";
import {connect} from "react-redux";
import {
    addGroup,
    addGroupBegin,
    addGroupSuccess,
    deleteGroupSuccess,
    fetchGroupById,
    updateGroupSuccess
} from "../actions/groupActions";
import {toast} from "react-toastify";
import GroupServices from "../Services/GroupServices";
import {extractPhoneString} from "../helpers/FormattingHelper";
import NotificationHelpers from "../helpers/NotificationHelpers";
import {createToast} from "../helpers/ToastIdHelper";
import {CSV_PARSE_ERROR_INVALID_FILE, CSV_PARSE_ERROR_NO_DATA, parseCsv} from "../helpers/CsvDataParser";
import {fetchDomains} from "../actions/redirectURLActions";
import RedirectURLServices from "../Services/RedirectURLServices";


const groupServices = GroupServices.getInstance();
const stateToPropertyMapper = (state) => {
    return {
        groups: state.groups,
        handlingSubmit: false,
        user: state.user,
        domains: state.redirectURL.domains,
    };
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchGroupById: (id) =>
        dispatch(fetchGroupById(id)),
    addGroup: (group) =>
        dispatch(addGroup(group)),
    deleteGroupSuccess: (group) =>
        dispatch(deleteGroupSuccess(group)),
    addGroupSuccess: (res) =>
        dispatch(addGroupSuccess(res)),
    addGroupBegin: () =>
        dispatch(addGroupBegin()),
    updateGroupSuccess: (group) =>
        dispatch(updateGroupSuccess(group)),
    fetchDomains: () => {
        dispatch(fetchDomains());
    },
});

const compileCSVContent = (rows) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    let header = Object.keys(rows[0]).join(",");
    csvContent += header + "\r\n";

    for (let i = 0; i < rows.length; i++) {
        let row = Object.values(rows[i]).join(",");
        csvContent += row + "\r\n";
    }
    return csvContent;
};


// Provides a container for GroupEditor. 
// This component class is used for creating or editing a group.
class GroupContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: {
                name: "",
                members: [],
                id: -1,
            },
            membersString: "",
            csvImport: false,
            responseData: [],
            csvLink: React.createRef(),
            groupSizeLimit: 1000,
            showUrlRedirect: false,
            multipleDomain: "",
            multipleURL: "",
        };
        this.toastId = 103;
    }

    // fetch group when component is mounted
    componentDidMount() {
        if (this.props.match.params.id != "new")
            this.props.fetchGroupById(this.props.match.params.id);
        else
            this.props.addGroupBegin();
    }


    createToast(msg, type) {
        if (toast.isActive(this.toastId)) {
            toast.update(this.toastId, {
                render: msg,
                type: type,
                autoClose: 5000,
                className: "rotateY animated",
            });
        } else {
            toast(msg, {
                toastId: this.toastId,
                type: type,
                autoClose: 5000
            });
        }
    }

    // set group to the state when props change
    componentWillReceiveProps(props) {
        if (this.props.match.params.id !== "new" && props.match.params.id === "new") {
            this.props.addGroupBegin();
        }
        this.setState({
            group: props.groups.selectedGroup,
            membersString: props.groups.selectedGroup.members ?
                props.groups.selectedGroup.members.map(mem => mem.name + "," + mem.phone).join("\n") : "",
            groupSizeLimit: props.user.profile.broadcast_group_size_limit
        });
    }

    // handle group name change
    // this method is passed to child component
    handleGroupNameChange = (e) => {
        e.preventDefault();
        this.setState({
            group: {
                ...this.state.group,
                name: e.target.value,
            }
        });
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    // handle members change
    // member name and number are seperated by a comma
    // two different members are seperated by a new line
    handleMembersChange = (e) => {
        e.preventDefault();
        let membersStrings = e.target.value.split("\n");
        this.setState({
            membersString: e.target.value,
            group: {
                ...this.state.group,
                members: membersStrings.map(str => {
                    if (str.includes(",")) {
                        const name = str.split(",")[0];
                        const phone = extractPhoneString(str.split(",")[1]);
                        return {name: name, phone: phone};

                    } else {
                        const name = extractPhoneString(str);
                        const phone = extractPhoneString(str);
                        return {name: name, phone: phone};

                    }
                })
            }
        });
    };

    //handle delete group
    handleDeleteGroup = (e) => {
        e.preventDefault();
        groupServices.deleteGroup(this.state.group.id)
            .then(() => {
                toast.success("Group " + this.state.group.name + " delete successfully.");
                this.props.deleteGroupSuccess(this.state.group);
                this.props.history.push("/app");
            })
            .catch(err => {
                err.text()
                    .then(res => {
                        createToast(this.toastId, JSON.parse(res).error, toast.TYPE.ERROR);
                    })
                    .catch(() => {
                        createToast(this.toastId, error(NotificationHelpers.ERROR_GROUP_CREATE), toast.TYPE.ERROR);
                    });
            });
    };

    handleSubmitStart = () => {
        this.setState({handlingSubmit: true});
    };

    handleSubmitFinish = () => {
        this.setState({handlingSubmit: false});
    };

    // handle submit creates or updates a group with new data
    // if id==new then it will create a new group otherwise it will update an existing
    // group
    handleSubmit = async (e, generateKeys) => {
        e.preventDefault();
        if (this.state.name == "") {
            toast.error(NotificationHelpers.WARN_GROUP_NAME_MANDATORY);
            return;
        }
        this.handleSubmitStart();
        let listId;
        let members = this.state.group.members || [];
        const hasTargetUrl = members.length ? !!members[0].target_url : false;
        if (this.props.match.params.id === "new") {
            if (generateKeys && !hasTargetUrl && (this.state.multipleURL === "" || this.state.multipleDomain === "")) {
                toast.error("All fields are required.");
                return;
            }
            if (generateKeys && hasTargetUrl && this.state.multipleDomain === "") {
                toast.error("All fields are required.");
                return;
            }
            if (generateKeys) {
                const generateResponse = await RedirectURLServices.generateKeysForPhoneNumbers({
                    domain: this.state.multipleDomain,
                    listName: this.state.group.name,
                    phoneList: members.map(m => ({
                        phone: m.phone,
                        url: m.target_url || this.state.multipleURL,
                        note: m.note || m.name
                    }))
                });
                listId = generateResponse.listid;
                members = members.map(m => {
                    const newMembers = generateResponse.result;
                    const newMember = newMembers.find(nm => nm.phone === m.phone);
                    return {
                        ...m,
                        target_url: m.target_url || this.state.multipleURL,
                        short_url: newMember ? `${this.state.multipleDomain}/?${newMember.urlkey}` : "",
                    };
                });
                this.setState({
                    group: {
                        ...this.state.group,
                        listId,
                        members
                    }
                });
            }
            groupServices.addGroup({
                name: this.state.group.name,
                members,
                domain: this.state.multipleDomain,
                list_name: this.state.group.name,
                url: this.state.multipleURL,
                list_id: listId,
            })
                .then(res => {
                    toast.success(NotificationHelpers.SUCCESS_GROUP_CREATE);
                    this.props.addGroupSuccess(res);
                    this.props.fetchGroupById(res.id);
                    this.props.history.push("/groups/" + res.id + "/edit");
                })
                .catch(err => {
                    err.text()
                        .then(res => {
                            if ("Cannot create group without any member in it" === JSON.parse(res).error)
                                createToast(this.toastId, "Cannot create group with 0 members.", toast.TYPE.ERROR);
                            else
                                createToast(this.toastId, JSON.parse(res), toast.TYPE.ERROR);
                        })
                        .catch(err => {
                            createToast(this.toastId, NotificationHelpers.ERROR_GROUP_CREATE, toast.TYPE.ERROR);
                        });
                })
                .finally(() => this.handleSubmitFinish());
        } else {
            const item = this.state.group;
            if(item.domain){
                const generateResponse = await RedirectURLServices.generateKeysForPhoneNumbers({
                    domain: item.domain,
                    listName: item.list_name,
                    phoneList: item.members.map(m => ({
                        phone: m.phone,
                        url: m.target_url,
                        note: m.note || m.name
                    }))
                });
                listId = generateResponse.listid;
                members = item.members.map(m => {
                    const newMembers = generateResponse.result;
                    const newMember = newMembers.find(nm => nm.phone === m.phone);
                    return {
                        ...m,
                        short_url: newMember ? `${item.domain}/?${newMember.urlkey}` : ""
                    }
                });
                this.setState({
                    group: {
                        ...this.state.group,
                        listId,
                        members
                    }
                });
            }
            groupServices.updateGroup(this.state.group.id,
                {
                    name: this.state.group.name,
                    members,
                    domain: item.domain,
                    list_id: item.list_id,
                    list_name: item.list_name,
                    url: item.url,
                })
                .then(res => {
                    toast.success(NotificationHelpers.SUCCESS_GROUP_UPDATE);
                    this.props.updateGroupSuccess(res);
                })
                .catch(err => toast.error(NotificationHelpers.ERROR_GROUP_UPDATE + "\n" + err))
                .finally(() => this.handleSubmitFinish());
        }
    };

    showImportedContacts = contacts => {
        const invalid = contacts.filter(con => con.invalid);
        contacts = contacts.filter(con => con.invalid !== true);
        this.createToast("Imported " + contacts.length + " contacts. " +
            invalid.length + " invalid entries found.", toast.TYPE.INFO);
        this.setState({
            group: {
                ...this.state.group,
                members: contacts
            },
            invalidContacts: invalid,
            invalidString: invalid.map(con => con.name + ", " + con.phone).join("\n"),
            membersString: contacts.map(con => con.name + ", " + con.phone).join("\n")
        });
    };

    handleCsvLoad = (data, file) => {
        let parsed = parseCsv(data, file);
        if (parsed.err !== null) {
            switch (parsed.err) {
                case CSV_PARSE_ERROR_INVALID_FILE:
                    createToast(this.toastId, NotificationHelpers.ERROR_INVALID_CSV, toast.TYPE.ERROR);
                    return;
                case CSV_PARSE_ERROR_NO_DATA:
                    createToast(this.toastId, NotificationHelpers.ERROR_CSV_NO_DATA);
                    return;
            }
        }
        let contacts = parsed.contacts;
        this.showImportedContacts(contacts);
    };

    setCsvJson = (data) => {
        this.setState({
            csvImport: true,
            group: {
                ...this.state.group,
                members: data,
            },
            showUrlRedirect: this.props.match.params.id === "new" && data && data.length > 0 && this.props.domains.length > 0,
        });
    };

    deleteMembersByPhones = (phones) => {
        if (phones.length === this.state.group.members.length) {
            createToast(this.toastId, "Cannot delete all members of a group", toast.TYPE.ERROR);
            return -1;
        }
        this.setState({
            group: {
                ...this.state.group,
                members: this.state.group.members.filter(member => !phones.includes(member.phone)),
            },
        });
    };

    addMember = (member) => {
        return new Promise((resolve, reject) => {
            if (this.state.group.members.filter(m => m.phone === member.phone).length > 0) {
                reject(-1);
            } else {
                this.setState({
                    group: {
                        ...this.state.group,
                        members: [member, ...this.state.group.members],
                    }
                });
                resolve();
            }
        });
    };

    responseReport = () => {
        // This is different from DeliveryReport because a user can send characters in the message
        // that need to be encoded for the link href (i.e. #).
        // So to prevent having to encode and decode, this is easier
        groupServices.responseReport(this.state.group.id)
            .then((rows) => {
                if (rows.length == 0) {
                    toast.info("There are no responses for this group");
                    return;
                }

                this.setState({responseData: rows}, () => {
                    // click the CSVLink component to trigger the CSV download
                    this.state.csvLink.current.link.click();
                });
            })
            .catch(() => {
                toast.error("Could not download response report.");
            });
    };

    // render group editor
    render() {
        return (
            <div className="message-container">
                <GroupEditor group={this.state.group}
                             new={this.props.match.params.id == "new"}
                             membersString={this.state.membersString}
                             handleGroupNameChange={this.handleGroupNameChange}
                             handleMembersChange={this.handleMembersChange}
                             handleSubmit={this.handleSubmit}
                             handleDelete={this.handleDeleteGroup}
                             onCsvLoad={this.handleCsvLoad}
                             invalidString={this.state.invalidString}
                             csvImport={this.state.csvImport}
                             setCsvJson={this.setCsvJson}
                             deleteMembersByPhones={this.deleteMembersByPhones}
                             addMember={this.addMember}
                             fetchGroupById={this.props.fetchGroupById}
                             responseReport={this.responseReport}
                             responseData={this.state.responseData}
                             csvLink={this.state.csvLink}
                             handlingSubmit={this.state.handlingSubmit}
                             groupSizeLimit={this.state.groupSizeLimit}
                             handleInputChange={this.handleInputChange}
                             fetchDomains={this.props.fetchDomains}
                             domains={this.props.domains}
                             showUrlRedirect={this.state.showUrlRedirect}
                />
            </div>
        );
    }
}

export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(GroupContainer);

