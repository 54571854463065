/* eslint-disable */
import {connect} from 'react-redux';
import {fetchProfile, updateProfileSuccess} from '../actions/UserActions';
import ProfileComponent from '../components/AppComponents/ProfileComponent';
import {fetchSlyBLists} from "../actions/SlybroadcastListActions";
import SlybListsComponent from "../components/AppComponents/SlybListsComponent";
import {fetchGroups} from "../actions/groupActions";

const stateToPropertyMapper = (state, props) => {
    return {
        lists: state.slybLists,
        groups:state.groups,
    }
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchLists : () => {
        dispatch(fetchSlyBLists());
    },
    fetchGroups:()=>{
        dispatch(fetchGroups());
    }
});

export const SlybListContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper
)(SlybListsComponent);

