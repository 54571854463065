import MessageServices from "../Services/MessageServices";
import {fetchConversationByID, newMessageSent} from "./conversationActions";
import {fetchScheduledMessagesForConversation} from "./ScheduledMessagesActions";

const messageServices = MessageServices.getInstance();

export const POST_SMS_BEGIN =  "POST_SMS_BEGIN";
export const POST_SMS_SUCCESS = "POST_SMS_SUCCESS";
export const POST_SMS_FAILURE =  "POST_SMS_FAILURE";

const postSmsBegin=()=>({
    type: POST_SMS_BEGIN,
});

// const postSmsSuccess=(sms)=>({
//     type: POST_SMS_SUCCESS,
//     payload: sms
// })

const postSmsFailure=(err)=>({
    type:POST_SMS_FAILURE,
    payload:err
});

export const postSms=(message,conversation_name)=>{
    return dispatch => {
        dispatch(postSmsBegin());
        messageServices.sendMessage(message)
            .then(res => {
                dispatch(fetchConversationByID(res.conversation_id));
                dispatch(fetchScheduledMessagesForConversation(res.conversation_id));
                dispatch(newMessageSent(res.conversation_id,message,conversation_name))
            })
            .catch(err => {
                console.log('err: ', err);
                dispatch(postSmsFailure(err));
            })
    }
};