/* eslint-disable */

import React from 'react'
import {formatPhoneNumber} from '../../helper';


export default function ContactListItem(props) {
    return (
        <div className="container conversation-list-item p-0 ">
            <div className="row p-2">
                <div className="col-3 ">
                    <img className=" round" 
                        src={"https://slytext.com/"+props.contact.avatar}
                        alt=""/>
                </div>
                <div className="col-9 bottom-bordered row p-0">
                    <div className="col-12 text-left">
                        <div className="row">
                            <div className="col-8 title">
                                {props.contact.name}
                                <br/>
                                <span className="text-muted small">
                                    {formatPhoneNumber(props.contact.phone)}
                                </span>
                                
                            </div>
                            {/* <div className="col-4 text-right time-stamp p-0">
                                {this.state.formattedTime}
                            </div>
                            <div className="col-12 message-text">
                                {this.state.message}
                            </div> */}
                        </div>
                    </div>
                    
                </div>
            </div>
    </div>
    )
}
