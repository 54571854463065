/* eslint-disable */
import {
    ADD_CONTACT_BEGIN,
    ADD_CONTACT_FAILURE,
    ADD_CONTACT_SUCCESS,
    DELETE_CONTACT_SUCCESS,
    FETCH_CONTACT_BY_ID_BEGIN,
    FETCH_CONTACT_BY_ID_SUCCESS,
    FETCH_CONTACTS_BEGIN,
    FETCH_CONTACTS_SUCCESS,
    UPDATE_CONTACT_BEGIN,
    UPDATE_CONTACT_FAILURE,
    UPDATE_CONTACT_SUCCESS
} from "../actions/contactActions";

const initialState = {
    loading: false,
    error: null,
    items: [],
    selectedContact:{},
    updateContactLoading:false,
    updateContactError:null,
    newContact:null

}

export default function ContactReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_CONTACTS_BEGIN:
            return {
                ...state,
                items:state.items,
                loading:true,
                error: null,
            }
        case FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                items: action.payload,
            }
        case FETCH_CONTACT_BY_ID_BEGIN:
                return {
                    ...state,
                    loading: true,
                    error: null,
                    newContact:null,
                    selectedContact:{}
                }
        case FETCH_CONTACT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                selectedContact: action.payload,
            }
        case UPDATE_CONTACT_BEGIN:
            return {
                ...state,
                updateContactError:null,
                updateContactLoading:true,
            }
        case UPDATE_CONTACT_SUCCESS:
            return{
                ...state,
                selectedContact:action.payload,
                items:state.items.map(contact=>{
                            if(contact.id!=action.payload.id){
                                return contact;
                            }else{
                                return action.payload;
                            }
                        }),
                updateContactError:null,
                updateContactLoading:false,
            }
        case UPDATE_CONTACT_FAILURE:
            return{
                ...state,
                updateContactError:action.payload,
                updateContactLoading:false,
            }
        case ADD_CONTACT_BEGIN:
            return {
                ...state,
                updateContactError:null,
                updateContactLoading:true,
                newContact:null,
            }
        case ADD_CONTACT_SUCCESS:
            return {
                ...state,
                updateContactError:null,
                updateContactLoading:false,
                selectedContact:action.payload,
                newContact:action.payload,
                items:[...state.items,action.payload]
            }
        case ADD_CONTACT_FAILURE:
            return {
                ...state,
                updateContactError:action.payload,
                selectedContact:{},
                newContact:null
            }
        case DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                items:state.items.filter(contact=>contact.id!=action.payload.id)
            }
        default:
            return state
    }
}