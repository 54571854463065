/* eslint-disable */
import React, {Component} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthServices from '../../Services/AuthServices';
import NotificationHelpers from "../../helpers/NotificationHelpers";
import {Link} from "react-router-dom";
import {createToast} from "../../helpers/ToastIdHelper";

const authService = AuthServices.getInstance();

export default class SignInFormComponentSlybroadcast extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            password:"",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toastId=104;
    }

    handleInputChange(e){
        e.preventDefault();
        this.setState({
            [e.target.id]:e.target.value,
        });
    }


    handleFormSubmit(e){
        e.preventDefault();
        authService.getSessionSlybroadcast(this.state.email,this.state.password)
            .then(res => {
                if(res===true){
                    window.location.href="/app"
                }
            })
            .catch(err => {
                if(err.status===409){
                    createToast(this.toastId,NotificationHelpers.DUPLICATE_EMAIL,toast.TYPE.ERROR)
                }
                if(err.status===500||err.status>300){
                    createToast(this.toastId,NotificationHelpers.INVALID_LOGIN_CRED,toast.TYPE.ERROR)
                }
            })
    }

    render() {
        return (
            <div id={"slybroadcast-signin-form"}>
                <h4> with your <span style={{color:"#2ecc71"}}>slybroadcast</span> email address & password.</h4>
                <form>
                    <div className="form-group">
                        <label for="email">Slybroadcast Email address</label>
                        <input type="email"
                               className="form-control"
                               id="email"
                               aria-describedby="emailHelp"
                               placeholder="Email address"
                               onChange={this.handleInputChange}
                               style={{borderRadius:"0.25rem"}}/>
                    </div>
                    <div className="form-group" >
                        <label for="password">Slybroadcast Password</label>
                        <input type="password"
                               className="form-control"
                               id="password"
                               placeholder="Password"
                               onChange={this.handleInputChange}
                               style={{borderRadius:"0.25rem"}}/>
                        <small><a href={"https://www.slybroadcast.com/login.php"}
                                  className="mb-4 mr-2 pull-right text-muted"
                                  target={"_blank"}>
                                    Forgot password?</a>
                        </small>
                    </div>

                    <button type="submit"
                            className="btn btn-primary col-12"
                            onClick={this.handleFormSubmit}
                            style={{borderRadius:"0.25rem"}}>Submit</button>
                </form>
            </div>
        )
    }
}
