/* eslint-disable */


import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class ConversationServices{
    static authServices = null;
    static getInstance(){
        if(this.authServices==null){
            this.authService=new ConversationServices();
        }
        return this.authService;
    }

    getConversations(page,count){
        return http._get("/conversations?page="+page+"&count="+count)
                .then(res => {
                    return res
                })
                .catch(err => {throw err});
    }

    getConversationById(id){
        return http._get("/conversations/"+parseInt(id)+"?p=1")
            .then(res => res)
            .catch(err => err);
    }

    getConversationForGroup(id){
        return http._get("/conversation-for-group/"+parseInt(id))
            .then(res => res)
            .catch(err => err);
    }

    getConversationForContact(id){
        return http._get("/conversation-for-contact/"+parseInt(id))
            .then(res => res)
            .catch(err => err);
    }
}