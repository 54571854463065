/* eslint-disable */
import React, {Component} from "react";
import {initNotifications, notify} from "browser-notification";

import "bootstrap/dist/css/bootstrap.css";
import "../Styles/main.css";
import Navbar from "./AppComponents/Navbar";
import ConversationListContainer from "../containers/ConversationListContainer";
import {Link, Route, withRouter} from "react-router-dom";
import GroupMessageContainer from "../containers/GroupMessageContainer";
import ContactMessageContainer from "../containers/ContactMessageContainer";
import BookContainer from "../containers/BookContainer";
import ContactContainer from "../containers/ContactContainer";
import Tutorial from "./AppComponents/Tutorial";
import OptoutlistContainer from "../containers/OptoutlistContainer";
import GroupContainer from "../containers/GroupContainer";
import WebSocketService from "../Services/WebSocketService";
import {CAMPAIGN_STOPPED, NEW_MESSAGE} from "../helpers/WebSocketHelpers";
import AutoReplyContainer from "../containers/AutoReplyContainer";
import ScheduledMessageContainer from "../containers/ScheduledMessageContainer";
import BulkImportContacts from "./AppComponents/BulkImportContacts";
import {SlybListContainer} from "../containers/SlybListContainer";
import CampaignDashboardContainer from "../containers/CampaignDashboardContainer";
import ClickThroughReportContainer from "../containers/ClickThroughReportContainer";
import {toast} from "react-toastify";

const webSocketService = WebSocketService.getInstance();

class Main extends Component {

    constructor(props){
        super(props);
        this.state={
            showContacts:false,
            showGroups:false
        }
        this.showContacts = this.showContacts.bind(this);


    }

    componentDidMount(){
        webSocketService.connect(this.handleWsMessage)
        this.props.fetchProfile();
        this.props.fetchGroups();
        this.props.fetchContacts();

    }
    componentWillReceiveProps(props){
        if (!props.user || !props.user.profile) {
            localStorage.clear();
            window.location.reload();
            return;
        }
        if(props.user && props.user.profile.message_credit===0 &&
            props.user.profile.subscription_quota===0 &&
            props.user.profile.slytext_phone==="" &&
            !props.user.profile.slybroadcast_customer){
            props.history.push("/subscription")
        }
        else if(props.user.profile.username && (props.user.profile.slytext_phone==null || props.user.profile.slytext_phone=="")){
            props.history.push("/welcome1")
        }
    }

    handleWsMessage=(msg)=>{
        msg = JSON.parse(msg.data)
        switch (msg.type) {
            case NEW_MESSAGE:
                this.props.newMessage(msg.message.conversationId,msg.message.message);
                this.props.fetchNewMessages();
                if(this.props.conversation.id==msg.message.conversationId){
                    this.props.fetchConversationById(msg.message.conversationId)
                }
                if(this.props.user.profile.notifications_enabled){
                    initNotifications();
                    notify("You have a new message!");
                }
                break;
            case CAMPAIGN_STOPPED:
                toast.error(<div>
                    <h5>Your campaign has been stopped</h5>
                    <Link to={`/groups/${msg.message.GroupID}/conversation/${msg.message.ConversationID}?message=${msg.message.MessageID}`}
                          className="col-12 btn btn-light">
                        Go to delivery report
                    </Link>
                </div>, { autoClose: false })
                break;
            default:
                // console.log(msg);
        }
    }

    showContacts(){
        this.setState({
            showContacts:true,
            showGroups:false
        })
        if(!this.state.showContacts){
            // this.props.fetchContacts()
            this.props.fetchGroups()
        }

    }

    showGroups=(e)=>{
        e.preventDefault();
        this.setState({
            showContacts:true,
            showGroups:true
        })
        if(!this.state.showContacts){
            // this.props.fetchContacts()
            this.props.fetchGroups()
        }
    }
    handleData(data) {
        let result = JSON.parse(data);
    }
    render() {
        return (
            <div className="main-container row p-0 mx-0">
            <div className={this.props.location.pathname=="/"?
                                "col-sm-12 col-xs-12 col-md-3 left-nav bg-light custom-shadow":
                                "d-none  d-md-block col-md-3 left-nav bg-light custom-shadow"}
                                >
                <Navbar showContacts={this.showContacts}
                        showGroups={this.showGroups}
                        activeContacts={this.state.showContacts&&!this.state.showGroups}
                        activeGroups={this.state.showGroups}
                        profile={this.props.user.profile}/>

                <div className={this.state.showContacts?"hidden":"converstion-list"}>
                    <ConversationListContainer profile={this.state.profile}/>
                </div>

                {this.state.showContacts?
                <div className={"contact-list converstion-list flex-flow-column"}>
                    <h5 className="p-2 mb-0 text-left"
                        onClick={(e)=>{
                            this.setState({
                                showContacts:false,
                                showGroups:false
                            })
                        }}>
                        <button className="btn btn-light mx-1 round"
                            >
                            <i className="fas fa-arrow-left"></i>
                        </button>
                        Back
                        </h5>
                    <BookContainer show={this.state.showContacts}
                                   groups={this.state.showGroups}
                                   showGroups={this.showGroups}
                                   showContacts={this.showContacts}
                                   />
                </div>
                :
                ""
                }

            </div>
            <Route exact path="/" component={() => <Tutorial profile={this.props.user.profile} />}/>

            <div className={this.props.location.pathname==="/app"?
                                "":
                                "col-sm-12 col-xs-12 d-block col-md-9 p-0"}>

                    <Route  exact path="/groups/:id" component={GroupMessageContainer} />
                    <Route  exact path="/groups/:id/conversation" component={GroupMessageContainer}/>
                    <Route  exact path="/groups/:id/conversation/:conversation_id" component={GroupMessageContainer}/>
                    <Route  exact path="/groups/:id/edit" component={GroupContainer}/>

                    <Route exact path="/contacts/:id" component={ContactMessageContainer}/>
                    <Route  exact path="/contacts/:id/conversation" component={ContactMessageContainer}/>
                    <Route  exact path="/contacts/:id/conversation/:conversation_id" component={ContactMessageContainer}/>
                    <Route  exact path="/contacts/:id/edit" component={ContactContainer}/>

                    <Route exact path="/import/contacts" component={() => <BulkImportContacts fetchContacts={this.props.fetchContacts}/>}/>
                    <Route exact path="/opt-out-list" component={OptoutlistContainer}/>
                    <Route exact path="/click-through-report" component={ClickThroughReportContainer}/>
                    <Route exact path="/auto-reply" component={AutoReplyContainer}/>
                    <Route exact path="/scheduled-messages" component={ScheduledMessageContainer}/>
                    <Route exact path="/campaign-dashboard" component={CampaignDashboardContainer}/>

                    <Route exact path="/slybroadcast/lists" component={SlybListContainer}/>

            </div>
        </div>
        )
    }
}


export default withRouter(Main);
