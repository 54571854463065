/* eslint-disable */
import "bootstrap/dist/css/bootstrap.css";
import {Picker} from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import "flatpickr/dist/themes/material_blue.css";
import React, {Component} from "react";
import {FilePicker} from "react-file-picker";
import {toast} from "react-toastify";
import "../../assets/style/chatmessages.css";
import MediaServices from "../../Services/MediaServices";
import SmartReplyServices from "../../Services/SmartreplyServices";
import "../../Styles/main.css";
import "../../Styles/message-container.css";
import MediaViewer from "./MediaViewer";
import SmartReply from "./SmartReply";
import InputDialogModal from "./InputDialogModal";
import {formatPhoneNumber, formatTime} from "../../helpers/FormattingHelper";
import NotificationHelpers from "../../helpers/NotificationHelpers";
import MessageInputArea from "./MessageInputArea";
import ScheduleModal from "./ScheduleModal";
import {Link} from "react-router-dom";
import SuggestionModal from "./SuggestionsModal";
import RedirectURLServices from "../../Services/RedirectURLServices";

const mediaService = MediaServices.getInstance();
const smartReplyServices = SmartReplyServices.getInstance();
const urlMapping = {};

export default class MessageInputBar extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            showEmoji: false,
            text: "",
            file: null,
            fileType: "",
            mms: false,
            showAttachment: false,
            schedule: "",
            showSchedule: false,
            showSmartReply: false,
            smartReplies: [],
            modalShow: false,
            showSuggestions: false,
            domain: "",
            key: "",
            destinationURL: "",
            redirect_url_type: "none"
        });
        this.onShowEmojiClicked = this.onShowEmojiClicked.bind(this);
        this.handleInputTextChange = this.handleInputTextChange.bind(this);
        this.addEmoji = this.addEmoji.bind(this);
        this.onMessageSend = this.onMessageSend.bind(this);
        this.onShowSmartReply = this.onShowSmartReply.bind(this);

    }

    componentWillReceiveProps(props) {
        this.setState({
            showEmoji: false,
            text: "",
            file: null,
            fileType: "",
            mms: false,
            showAttachment: false,
            schedule: "",
            showSchedule: false,
            showSmartReply: false,
            smartReplies: [],
            modalShow: false,
            showSuggestions: false,

        });
    }

    handleKeywordSubmit = (data) => {
        if (data === null || data === "") {
            this.setState({
                newSmartReplyParent: {}
            });
            return;
        }
        this.setState({
            alertInputHeading: "",
            alertInputName: "",
            alertInputLabel: "",
            onAlertSubmit: null,
            keyword: data,
            reply: "",
            modalShow: false

        });
        this.showReplyAlert();

    };

    onSuggestionSelected = (cursorPosition, changeWord, suggestion) => {
        this.setState({
            text: this.state.text.slice(0, cursorPosition)
                + suggestion
                + this.state.text.slice(cursorPosition + changeWord.length),
            showSuggestions: false,
        });
    };
    handleReplySubmit = (data) => {
        if (data === null || data === "") {
            this.setState({
                newSmartReplyParent: {}
            });
            return;
        }
        this.setState({
            alertInputHeading: "",
            alertInputName: "",
            alertInputLabel: "",
            onAlertSubmit: null,
            reply: data,
            modalShow: false

        });

        const dummySmartReply = {
            Keyword: this.state.keyword,
            message: data,
            parent: this.state.newSmartReplyParent.id,
        };

        smartReplyServices.createSmartReplies(parseInt(this.props.group_id), dummySmartReply)
            .then(() => {
                smartReplyServices.getSmartReplies(this.props.group_id)
                    .then(res => {
                        this.setState({
                            smartReplies: res,
                            newSmartReplyParent: {}
                        });
                    });
            }).catch(() => toast.error(NotificationHelpers.ERROR_ADD_SMART_REPLY));

    };

    showEnterKeywordAlert = () => {
        this.setState({
            alertInputHeading: "Create smart reply",
            alertInputName: "keyword",
            alertInputLabel: "If response includes ",
            onAlertSubmit: this.handleKeywordSubmit,
            keyword: "",
            reply: "",
            modalShow: true
        });

    };

    showReplyAlert = () => {
        this.setState({
            alertInputHeading: "Create smart reply",
            alertInputName: "reply",
            alertInputLabel: "then reply",
            onAlertSubmit: this.handleReplySubmit,
            reply: "",
            modalShow: true
        });
    };

    onSmartReplyAdd = (parent) => {
        this.showEnterKeywordAlert();
        this.setState({
            newSmartReplyParent: parent,
        });
    };

    onDeleteSmartReply = (id) => {
        smartReplyServices.deleteSmartReply(id)
            .then(() => {
                smartReplyServices.getSmartReplies(this.props.group_id)
                    .then(res => {
                        this.setState({
                            smartReplies: res
                        });
                    });
            }).catch(() => toast.error(NotificationHelpers.ERROR_DELETE_SMART_REPLY));
    };


    onShowEmojiClicked(e) {
        e.preventDefault();
        this.setState({
            showEmoji: !this.state.showEmoji
        });
    };

    onShowSmartReply(e) {
        e.preventDefault();
        this.setState({
            showSmartReply: !this.state.showSmartReply
        });
        smartReplyServices.getSmartReplies(this.props.group_id)
            .then(res => {
                this.setState({
                    smartReplies: res
                });
            });
    };

    handleInputChange = (e) => {
        const fieldName = e.target.name;
        const value = e.target.value;
        this.setState({
            ...this.state,
            [fieldName]: value,
        });
    };

    handleInputTextChange = (e) => {
        e.preventDefault();
        const text = e.target.value;
        if (!text && text !== "") {
            return;
        }
        this.setState({text});
        const domains = this.props.domains.map(({domain}) => domain).join("|");
        const singleURLRegex = new RegExp("(" + domains + ")(\\/\\?\\w{1,})", "g");
        const singleURLResults = text.match(singleURLRegex);
        if (domains.length > 0 && singleURLResults && singleURLResults.length > 0) {
            const splitURL = singleURLResults[0].split("/?");
            const destinationURL = urlMapping[`${splitURL[0]}/?${splitURL[1]}`];
            this.setState({
                redirect_url_type: "singleURL",
                domain: splitURL[0],
                key: splitURL[1],
                destinationURL: destinationURL || ""
            });
            return;
        }
        this.setState({redirect_url_type: ""});
    };

    addEmoji = (e) => {

        if (e.unified.length <= 5) {
            let emojiPic = String.fromCodePoint(`0x${e.unified}`);
            this.setState({
                text: this.state.text + emojiPic
            });
        } else {
            let sym = e.unified.split("-");
            let codesArray = [];
            sym.forEach(el => codesArray.push("0x" + el));
            let emojiPic = String.fromCodePoint(...codesArray);
            this.setState({
                text: this.state.text + emojiPic
            });
        }
    };

    onMessageSend(e) {
        e.preventDefault();
        const valid = this.validRedirectURLFields();
        if (!valid) {
            toast.error("All the fields are mandatory");
            return;
        }
        this.submitRedirectURL().then(() => {
            const message = {
                contact_id: this.props.contact_id,
                fileHash: this.state.file,
                group_id: (this.props.group_id),
                message: this.state.text,
                mms: this.state.mms,
                text: this.state.text,
                showAttachment: false,
                showEmoji: false,
                schedule: this.state.schedule
            };
            this.props.sendMessage(message, this.props.conversation_name);
            this.setState({
                text: "",
                file: null,
                mms: false,
                showAttachment: false,
                schedule: "",
                showSchedule: false,
                redirect_url_type: "none"
            });
        }).catch(() => {
            toast.error("Could not create Redirect URL");
        });
    };

    submitRedirectURL = async () => {
        const {redirect_url_type} = this.state;
        if (redirect_url_type === "singleURL") {
            const {domain, destinationURL, key} = this.state;
            urlMapping[`${domain}/?${key}`] = destinationURL;
            await RedirectURLServices.setSingleURLWithKey({
                domain,
                url: destinationURL,
                key,
            });
            toast.success("Redirect URL created");
        }
    };

    validRedirectURLFields = () => {
        const {key, destinationURL, domain, redirect_url_type} = this.state;
        if (redirect_url_type === "singleURL") {
            return key !== "" && destinationURL !== "" && domain !== "";
        }
        return true;
    };

    onSuggestionSelectedFromModal = (suggestion) => {
        this.setState({
            text: this.state.text + " #" + suggestion,
            showSuggestions: false,
        });
    };

    render() {
        return (
            <div className="send-tools">
                <div className="row p-0 mx-0">
                        <span className="text-left col-12 text-center ">
                            Sending from P2P Messaging number: {formatPhoneNumber(this.props.slyTextPhone)}
                            &nbsp; | Remaining credits: {this.props.remainingCredits}
                        </span>
                    {this.state.schedule !== "" ?
                        <span className="text-left col-12 text-center text-primary">
                            <br/>
                            Schedule Time:  <u><Link onClick={(e) => {
                            e.preventDefault();
                            this.setState({showSchedule: true});
                        }}
                                                     className={""}>
                                    {formatTime(this.state.schedule)}</Link></u>
                            <button className={"btn-light"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            schedule: ""
                                        });
                                    }}
                                    title={"Cancel"}>
                                <i className="fas fa-times close-btn"/>
                            </button>
                            <br/>
                            <span className="pull-right" style={{"color": "red"}}>
                                Click <em>Send</em> to finish scheduling your message.
                            </span>
                        </span>
                        : ""}
                    <div className="message-input-row">
                        <div className="message-input-area">

                            <button
                                className={this.state.showEmoji ? "btn-danger btn my-2 ml-2  round emoji-button"
                                    : " btn-light my-2 ml-2 round  emoji-button"}
                                onClick={this.onShowEmojiClicked}
                                title="Insert emoji">
                                <span>
                                    <i className="far fa-smile"/>
                                </span>
                            </button>
                            <button className="btn my-2 left round send-button"
                                    onClick={this.onMessageSend}
                                    title="Send message">
                                <i className="fas fa-paper-plane"/>
                            </button>
                            <div className="dropup p-0 attach-button">
                                <button
                                    className=" btn-light my-2 ml-2 round left "
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                    title="Add attachment">
                                    <i className="fas fa-paperclip"/>
                                </button>

                                <div className="dropdown-menu media-attachment-buttons">
                                    <FilePicker extensions={["jpg", "jpeg", "png", "gif"]}
                                                dims={{minWidth: 0, maxWidth: 5000, minHeight: 0, maxHeight: 5000}}
                                                onChange={base64 => {
                                                    this.setState({
                                                        mms: true,
                                                        fileType: "image",
                                                        showAttachment: true,
                                                    });
                                                    mediaService.uploadMedia(base64)
                                                        .then(res => {
                                                            this.setState({
                                                                file: res.sha256,
                                                                fileType: "image",
                                                                mms: true,
                                                                showAttachment: true,
                                                            });
                                                        })
                                                        .catch(() => {
                                                        });

                                                }}
                                                onError={errMsg => {
                                                    toast.warn("Invalid file type. JPG, PNG or GIF only.");
                                                }}>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="btn btn-primary text-light round left mb-3"
                                            title="Add image">
                                            <i className="fas fa-image"/>
                                        </button>
                                    </FilePicker>

                                    <br/>
                                    <FilePicker
                                        extensions={["mp4", "3gp", "m4v", "mpg", "mpeg", "avi"]}
                                        dims={{minWidth: 0, maxWidth: 5000, minHeight: 0, maxHeight: 5000}}
                                        maxSize={10}
                                        onChange={base64 => {
                                            this.setState({
                                                mms: true,
                                                fileType: "video",
                                                showAttachment: true,
                                            });
                                            mediaService.uploadMedia(base64)
                                                .then(res => {
                                                    this.setState({
                                                        file: res.sha256,
                                                        mms: true,
                                                        fileType: "video",
                                                        showAttachment: true,
                                                    });
                                                })
                                                .catch(() => {

                                                });

                                        }}
                                        onError={errMsg => {
                                            toast.warn("Invalid file type.  MP4, 3GP, M4V, MPG, MPEG or AVI only.");
                                        }}>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="btn btn-success text-light round left mb-3"
                                            title="Add video">
                                            <i className="fas fa-video"/>
                                        </button>
                                    </FilePicker>
                                    <br/>


                                    <FilePicker
                                        extensions={["m4r", "mp3", "wav"]}
                                        dims={{minWidth: 0, maxWidth: 5000, minHeight: 0, maxHeight: 5000}}
                                        onChange={base64 => {
                                            this.setState({
                                                mms: true,
                                                fileType: "audio",
                                                showAttachment: true,
                                            });
                                            mediaService.uploadMedia(base64)
                                                .then(res => {
                                                    this.setState({
                                                        file: res.sha256,
                                                        mms: true,
                                                        fileType: "audio",
                                                        showAttachment: true,
                                                    });
                                                })
                                                .catch(() => {
                                                });

                                        }}
                                        onError={errMsg => {
                                            toast.warn("Invalid file type. WAV, MP3 or M4A only.");
                                        }}>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}

                                            className="btn btn-warning text-light round left mb-3"
                                            title="Add audio">
                                            <i className="fas fa-music"/>
                                        </button>
                                    </FilePicker>
                                    {/* <a
                                            className="btn btn-info text-light round left mb-1"
                                            title="Add audio" >
                                            <i className="fas fa-music"></i>
                                        </a> */}
                                </div>
                            </div>

                            {/*eslint-disable-next-line*/}
                            {this.props.group_id && this.props.group_id != 0 ?
                                <>
                                    <button
                                        className={"btn-light  my-2 ml-2   round  keyword-button suggestions-button"}
                                        onClick={() => {
                                            this.setState({showSuggestions: true});
                                        }}
                                        title="Add custom field">
                                        <i className="fas fa-hashtag"/>
                                    </button>
                                    <button
                                        className={((this.state.showSmartReply || this.state.smartReplies.length) ? "btn-green btn my-2 ml-2 round smart-reply-button"
                                            : "btn-light  my-2 ml-2   round  smart-reply-button")}
                                        onClick={this.onShowSmartReply}
                                        title="Smart reply">
                                        SR
                                    </button>
                                </>
                                :
                                ""
                            }


                            <button
                                className={((this.state.showSchedule || this.state.schedule) ? "btn-green btn my-2 ml-2  round schedule-button"
                                    : "btn-light  my-2 ml-2  round left schedule-button")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        ...this.state,
                                        showSchedule: !this.state.showSchedule,
                                    });
                                }}
                                title="Schedule message">
                                <i className="far fa-clock"/>
                            </button>

                            <ScheduleModal show={this.state.showSchedule}
                                           onHide={() => {
                                               this.setState({
                                                   showSchedule: false,
                                               });
                                           }}
                                           message={this.state.text}
                                           file={this.state.file}
                                           fileType={this.state.fileType}
                                           onSubmit={this.state.onAlertSubmit}
                                           label={this.state.alertInputLabel}
                                           scheduleTime={this.state.schedule}
                                           updateScheduleTime={(time) => {
                                               this.setState({
                                                   schedule: time
                                               });
                                               toast.warn("Don't forget to click 'Send' to finish scheduling your message");
                                           }}/>

                            <MessageInputArea onChange={this.handleInputTextChange}
                                              onSuggestionSelected={this.onSuggestionSelected}
                                              value={this.state.text}
                                              keywords={this.props.keywords}
                                              showSuggestions={this.state.showSuggestions}/>

                        </div>

                    </div>
                </div>

                <div className={this.state.showEmoji ? "slider" : "slider closed"}>
                    <Picker onSelect={this.addEmoji}
                            style={{
                                width: "100%",
                                height: "10%",
                                borderBottomLeftRadius: "25px",
                                borderBottomRightRadius: "25px"
                            }}
                            title=''
                            showPreview={false}/>
                </div>


                {/* Attachment display */}
                <div className={this.state.showAttachment ? "slider dropzone row" : "slider closed"}
                     style={{position: "relative"}}>
                    <div className="container">
                        <MediaViewer fileHash={this.state.file} fileType={this.state.fileType}/>
                    </div>
                    <div style={{position: "absolute", right: "10px", top: "10px"}}>
                        <button className="btn btn-light round"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        file: "",
                                        mms: false,
                                        fileType: "",
                                        showAttachment: false,
                                    });
                                }}>
                            <i className="fas fa-times close-btn"/>
                        </button>
                    </div>
                </div>


                {/* Smart Reply */}
                <div
                    className={this.state.showSmartReply ? "slider smart-reply-container row bg-none"
                        : "slider closed"}>
                    <div className="container">
                        <h5 className="font-weight-light">Enter Keyword/Reply pair
                            <button className="btn btn-secondary ml-2"
                                    onClick={this.onSmartReplyAdd}
                                    title="Add smart reply for the intial response."
                            >+</button>
                        </h5>


                        <SmartReply smartReplies={this.state.smartReplies}
                                    onAdd={this.onSmartReplyAdd}
                                    onDelete={this.onDeleteSmartReply}/>

                        <InputDialogModal
                            show={this.state.modalShow}
                            onHide={() => {
                                this.setState({
                                    modalShow: false,
                                });
                            }}
                            inputName={this.state.alertInputName}
                            heading={this.state.alertInputHeading}
                            onSubmit={this.state.onAlertSubmit}
                            label={this.state.alertInputLabel}
                            keyword={this.state.keyword}
                        />

                        <SuggestionModal
                            show={this.state.showSuggestions}
                            onHide={() => {
                                this.setState({
                                    showSuggestions: false,
                                });
                            }}
                            suggestions={this.props.keywords ? this.props.keywords : []}
                            onSelect={this.onSuggestionSelectedFromModal}
                        />
                    </div>
                </div>

                {/* URL redirect */}
                <div
                    className={this.state.redirect_url_type === "singleURL" ? "slider url-redirect row bg-none"
                        : "slider closed"}>
                    <div className="container mr-2">
                        <div className="form-group row">
                            <label htmlFor="campaignAgentCount" className="col-sm-3 col-form-label">Domain:</label>
                            <div className="col-sm-9">
                                <select className="form-control"
                                        id="campaignAgentCount"
                                        name="domain"
                                        value={this.state.domain}
                                        disabled
                                        onChange={this.handleInputChange}>
                                    <option key={"none"}
                                            value="">-
                                    </option>
                                    {this.props.domains.map(record => <option key={record.domain}
                                                                              value={record.domain}>{record.domain}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="campaignPassword" className="col-sm-3 col-form-label">Key:</label>
                            <div className="col-sm-9">
                                <input type="text"
                                       className="form-control"
                                       id="key"
                                       name="key"
                                       placeholder="Enter Key"
                                       disabled
                                       value={this.state.key}
                                       onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="campaignPassword" className="col-sm-3 col-form-label">Target URL:</label>
                            <div className="col-sm-9">
                                <input type="text"
                                       className="form-control"
                                       id="destinationURL"
                                       name="destinationURL"
                                       value={this.state.destinationURL}
                                       placeholder="Enter target URL"
                                       onChange={this.handleInputChange}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
