import React, {PureComponent} from "react";
import {PropTypes} from "prop-types";

import Modal from "react-bootstrap/Modal";
import JsontoTable from "./JsontoTable";
import {capitalizeFirstLetter, extractPhoneString} from "../../helpers/FormattingHelper";
import ConfirmationModal from "./ConfirmationModal";
import {createToast} from "../../helpers/ToastIdHelper";
import {toast} from "react-toastify";

class GroupMembersViewModal extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            newMember:null,
            collapsed:{
                addNew:false,
            },
            searchString:"",
            selected:false,
            selectedMembers:[],
            unsavedChanges:false,
            showUnsavedAlert:false,
        };
        this.toastID=103;
    }

    componentWillReceiveProps(props) {
        if (props.json === undefined || props.json.length < 1) {
            return;
        }
        let cols = Object.keys(props.json[0]).filter(c=>c!=="#name" && c!=="#phone" && c!=="name" && c!=="phone");
        cols=["name","phone",...cols];
        let newMember = {};
        for(let i in cols){
            newMember[cols[i]]="";
        }
        this.setState({
            emptyMember:newMember,
            newMember:newMember,
            cols:cols,
        })
    }

    handleSearchChange=(e)=>{
        this.setState({
            searchString:e.target.value
        })
    };

    handleNewMemberChange=(e)=>{
        this.setState({
            newMember:{
                ...this.state.newMember,
                [e.target.name]:e.target.value,
            },
        })
    };

    handleNewMemberAdd=(e)=>{
        e.preventDefault();
        if(this.state.newMember===null ||
            this.state.newMember.phone===undefined ||
            extractPhoneString(this.state.newMember.phone)===""
            ) {
            createToast(this.toastID,"Invalid details.",toast.TYPE.ERROR);
            return
        }
        let cols = Object.keys(this.props.json[0]);
        let newMember = {};
        for(let c in cols){
            newMember[cols[c]]="";
        }
        newMember = {...newMember, ...this.state.newMember,
                        phone:extractPhoneString(this.state.newMember.phone)};
        this.props.addMember(newMember)
            .then(()=>{
                createToast(this.toastId,"Member added. Please save the changes.", toast.TYPE.SUCCESS);
                this.setState({
                    unsavedChanges:true,
                    newMember:this.state.emptyMember,
                })
            })
            .catch(()=>{
                    createToast(this.toastId,
                        "Member with same phone number already exists.",
                        toast.TYPE.ERROR);

            });

    };

    handleToggleSelectAll=()=>{
        if(this.state.selected){
            this.setState({
                selected:!this.state.selected,
                selectedMembers:[],
            })
        }else{
            this.setState({
                selected:!this.state.selected,
                selectedMembers:this.props.json.map(m=>m.phone),
            })
        }
    };

    addToSelectedMembers=(member)=>{
      this.setState({
          selectedMembers:[...this.state.selectedMembers,member.phone],
          selected:true,
      })
    };

    removeFromSelectedMember=(member)=>{
        this.setState({
            selectedMembers:this.state.selectedMembers.filter(m=>m!==member.phone),
            selected:this.state.selectedMembers.filter(m=>m!==member.phone).length>0
        })
    };

    handleDeleteSelected=()=>{
        let res = this.props.deleteMembersByPhones(this.state.selectedMembers);
        if(res===-1){
            return;
        }
        this.setState({
            selectedMembers:[],
            selected:false,
            unsavedChanges:true,
        })
    };



    render() {
        if (this.props.json === undefined || this.props.json.length < 1) {
            return <></>;
        }
        const cols=this.state.cols;

        return (
            <Modal {...this.props}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   style={{
                       borderRadius: "1.5rem",
                   }}
                    onHide={()=>{
                        if(this.state.unsavedChanges){
                            this.setState({
                                showUnsavedAlert:true,
                            })
                        }else{
                            this.props.onHide();
                            this.setState({
                                newMember:{},
                                collapsed:{
                                    addNew:false,
                                },
                                searchString:"",
                                selected:false,
                                selectedMembers:[],
                                unsavedChanges:false,
                                showUnsavedAlert:false,
                            });
                        }

                    }}>

                {/* Modal header */}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Group members
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{overflowY: "hidden", overflowX: "hidden", display:"flex",flexDirection:"column",maxHeight:"80vh"}}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-manage-members-tab" data-toggle="tab" href={"#nav-manage-members"}
                               role="tab" aria-controls="#nav-manage-members" aria-selected="true"><h4>Manage existing members</h4></a>
                            <a className="nav-item nav-link " id="nav-add-member-tab" data-toggle="tab" href={"#nav-add-member"}
                               role="tab" aria-controls="nav-add-member" aria-selected="false"><h4>Add new member</h4></a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        {/* Add members start*/}
                        <div className="tab-pane fade " id="nav-add-member" role="tabpanel"
                             aria-labelledby="nav-add-member-tab">
                            <div className=" mt-4 col-6" style={{flex:1}}>
                                  {
                                        cols.map(label =>
                                            <div className={"row m-1"} key={"col-" + label}>
                                                <div className={"col-md-4 col-sm-12"}>
                                                    <label htmlFor={label}>
                                                        {label.split("_").map(l=>
                                                            capitalizeFirstLetter(l)).join(" ")}
                                                    </label>
                                                </div>
                                                <div className={"col-md-8 col-sm-12"}>
                                                    <input type={" text"}
                                                           className={"form-control"}
                                                           name={label}
                                                           onChange={this.handleNewMemberChange}
                                                           value={this.state.newMember[label]}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={"offset-md-4 col-md-4 col-sm-12 my-4"}>
                                        <button onClick={this.handleNewMemberAdd} className={"btn btn-primary col-12"}> Add </button>
                                    </div>
                            </div>
                        </div>
                        {/* add member ends */}
                        {/* manage memgers starts*/}
                        <div className="tab-pane fade show active" id="nav-manage-members" role="tabpanel"
                             aria-labelledby="nav-manage-members-tab"
                             >

                            <input className={"form-control mt-2"}
                                   placeholder={"Search member(s)"}
                                   onChange={this.handleSearchChange}
                                   value={this.state.searchString}
                                   type={"text"}
                                   style={{flex:1}}/>
                            <br/>
                            <div style={{overflowX: "auto",overflowY: "scroll", height:"60vh"}}>

                                <JsontoTable json={this.props.json.filter(m=>(
                                    (m.name && m.name.toLowerCase().includes(this.state.searchString.toLowerCase())) ||
                                    (m.phone && m.phone.includes(this.state.searchString))
                                ))}
                                             deleteMemberByPhone={this.props.deleteMemberByPhone}
                                             selected={this.state.selected}
                                             toggleSelectAll={this.handleToggleSelectAll}
                                             selectedMembers={this.state.selectedMembers}
                                             addSelected={this.addToSelectedMembers}
                                             removeSelected={this.removeFromSelectedMember}/>
                            </div>
                        </div>
                        {/* manage members ends*/}

                    </div>



                </Modal.Body>
                <Modal.Footer>
                    {this.state.selected?
                        <div style={{position:"absolute",left:"10px"}}>
                            <button className={"btn btn-danger"}
                                    onClick={this.handleDeleteSelected}
                                    >
                                Delete selected
                            </button>
                            <span className={"ml-2 font-italic"}>{this.state.selectedMembers.length} selected</span>
                        </div>:""
                    }

                    <button className={this.state.unsavedChanges?"btn btn-primary":"btn btn-secondary"}
                            disabled={!this.state.unsavedChanges}
                            onClick={(e)=>{
                                this.props.onSave(e);
                                this.props.onHide();
                            }}>
                         Save
                    </button>
                </Modal.Footer>
                <ConfirmationModal
                    show={this.state.showUnsavedAlert}
                    onHide={()=>{
                        this.setState({
                            newMember:{},
                            collapsed:{
                                addNew:false,
                            },
                            searchString:"",
                            selected:false,
                            selectedMembers:[],
                            unsavedChanges:false,
                            showUnsavedAlert:false,
                        });
                        this.props.onHide();
                    }}
                    onSubmit={(e)=>{
                        this.props.onSave(e);
                        this.setState({
                            newMember:{},
                            collapsed:{
                                addNew:false,
                            },
                            searchString:"",
                            selected:false,
                            selectedMembers:[],
                            unsavedChanges:false,
                            showUnsavedAlert:false,
                        });
                        this.props.onHide();
                    }}
                    alertMessage={"Do you want to save the changes?"}
                    note={"This action can not be reverted."}
                    positiveButtonHead={"Yes"}
                    negativeButtonHead={"No"}
                    positiveButtonColor={"#2ecc71"}
                />
            </Modal>
        );
    }
}

GroupMembersViewModal.propTypes = {
    deleteMembersByPhones: PropTypes.func
};

GroupMembersViewModal.defaultProps = {};

export default GroupMembersViewModal;
