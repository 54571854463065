/* eslint-disable */
export function formatPhoneNumber(phoneNumberString){
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export function setTime(timestamp) {
    const days=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    var date = new Date(timestamp);
    var today = new Date();

    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    var lastWeek = new Date(today.getTime()-1000*60*60*24*7);

    if (today.toDateString() == date.toDateString()) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var hoursDs = 'am';

      if (hours >= 12) {
        hours -= 12;
        hoursDs = 'pm';
      }
      if (hours == 0) {
          hours = 12;
      }
      var hoursStr = hours.toString();
      var minutesStr = minutes > 9 ? minutes.toString() : '0' + minutes.toString();
      return  hoursStr + ':' + minutesStr + ' ' + hoursDs;
    }
    else if (yesterday.toDateString() == date.toDateString()) {
      return 'Yesterday';
    }
    else if (date.getTime() > lastWeek.getTime()) {
      return days[date.getDay()];
    }
    else {
      return (date.getMonth() + 1).toString() + '/' + date.getDate().toString() + '/' + date.getFullYear().toString();
    }
  }