import MessageServices from "../Services/MessageServices";

const messageServices = MessageServices.getInstance();

/**********************************************************
 *            Fetch all scheduled messages                *
 **********************************************************/

export const FETCH_SCHEDULED_MESSAGES_BEGIN = "FETCH_SCHEDULED_MESSAGES_BEGIN";
export const FETCH_SCHEDULED_MESSAGES_SUCCES = "FETCH_SCHEDULED_MESSAGES_SUCCESS";
export const FETCH_SCHEDULED_MESSAGES_FAILURE = "FETCH_SCHEDULED_MESSAGES_FAILURE";

const fetchScheduledMessagesBegin = () => ({
    type:FETCH_SCHEDULED_MESSAGES_BEGIN
});

const fetchScheduledMessagesSuccess=(res)=>({
    type:FETCH_SCHEDULED_MESSAGES_SUCCES,
    payload:res
});

const fetchScheduledMessagesFailure =(err)=>({
    type:FETCH_SCHEDULED_MESSAGES_FAILURE,
    payload:err
});

export const fetchScheduledMessages=()=>{
    return dispatch=>{
        dispatch(fetchScheduledMessagesBegin());
        messageServices.getScheduledMessages()
            .then(res => dispatch(fetchScheduledMessagesSuccess(res)))
            .catch(err => dispatch(fetchScheduledMessagesFailure(err)))
    }
};


/***************************************************
 *     Get scheduled messages for conversation     *
 ***************************************************/
export const FETCH_SCHEDULED_MESSAGES_CONVERSATION_BEGIN = "FETCH_SCHEDULED_MESSAGES_CONVERSATION_BEGIN";
export const FETCH_SCHEDULED_MESSAGES_CONVERSATION_SUCCES = "FETCH_SCHEDULED_MESSAGES_CONVERSATION_SUCCESS";
export const FETCH_SCHEDULED_MESSAGES_CONVERSATION_FAILURE = "FETCH_SCHEDULED_MESSAGES_CONVERSATION_FAILURE";

// const fetchScheduledMessagesForConversationBegin = () => ({
//     type:FETCH_SCHEDULED_MESSAGES_CONVERSATION_BEGIN
// })

const fetchScheduledMessagesForConversationSuccess=(res)=>({
    type:FETCH_SCHEDULED_MESSAGES_CONVERSATION_SUCCES,
    payload:res
});

const fetchScheduledMessagesForConversationFailure =(err)=>({
    type:FETCH_SCHEDULED_MESSAGES_CONVERSATION_FAILURE,
    payload:err
});

export const fetchScheduledMessagesForConversation=(id)=>{
    return dispatch=>{
        dispatch(fetchScheduledMessagesBegin());
        messageServices.getScheduledMessagesForConversation(id)
            .then(res => dispatch(fetchScheduledMessagesForConversationSuccess(res)))
            .catch(err => dispatch(fetchScheduledMessagesForConversationFailure(err)))
    }
};

/***************************************************
 *            Update schedled message              *
 ***************************************************/
export const UPDATE_SCHEDULED_MESSAGE_SUCCESS = "UPDATE_SCHEDULED_MESSAGE_SUCCESS";

// export const updateScheduledMessageSuccess =(res)=>({
//     type:UPDATE_SCHEDULED_MESSAGE_SUCCESS,
//     payload:res
// });

/***************************************************
 *            Delete schedled message              *
 ***************************************************/
export const DELETE_SCHEDULED_MESSAGE_SUCCESS = "DELETE_SCHEDULED_MESSAGE_SUCCESS";

export const deleteScheduledMessageSuccess =(res)=>({
    type:DELETE_SCHEDULED_MESSAGE_SUCCESS,
    payload:res
});