/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();

export default class MessageServices{
    static messageServices = null;
    static getInstance(){
        if(this.messageServices==null){
            this.messageServices=new MessageServices();
        }
        return this.messageServices;
    }

    sendMessage(message){
        return http._post("/message",{
            contact_id: parseInt(message.contact_id),
            fileHash: message.fileHash,
            group_id: message.group_id,
            message: message.message,
            mms: message.mms,
            text: message.text,
            schedule:message.schedule===""?null:message.schedule,
        }).then(res => res)
        .catch(err => {throw err})
    }

    getAutoReplyMessage(){
        return http._get("/auto-reply")
                .then(res=>res)
                .catch(err=>{throw err})
    }

    createUpdateAutoReply(reply){
        return http._post("/auto-reply",reply)
                .then(res=>res)
                .catch(err=>{throw err})
    }

    getScheduledMessages(){
        return http._get("/scheduled")
                .then(res=>res)
                .catch(err=>{throw err})
    }

    getScheduledMessagesForConversation(conversationId){
        return http._get("/scheduled/"+conversationId)
                .then(res=>res)
                .catch(err=>{throw err})
    }

    updateScheduledMessage(id,message){
        return http._put("/scheduled/"+id,message)
                    .then(res=>res)
                    .catch(err=>{throw err})
    }

    deleteScheduledMessage(id){
        return http._delete("/scheduled/"+id)
                    .then(res=>res)
                    .catch(err=>{throw err})
    }

    getDeliveryStatus(id){
        return http._get("/onvoy-status/"+id)
                .then(res=>res)
                .catch(err=>{throw err})
    }

    static getOngoingCampaignMessage(){
        return http._get("/ongoingBroadcast")
            .then(res=>res)
            .catch(err=>{throw err})
    }

    static getQueuedCampaignMessages(){
        return http._get("/queuedBroadcast")
            .then(res=>res)
            .catch(err=>{throw err})
    }
    static deleteOngoingCampaign(messageId){
        return http._delete(`/campaign/${messageId}`)
            .then(res=>res)
            .catch(err=>{throw err})
    }
    static deleteQueuedMessage(id){
        return http._delete(`/queuedBroadcast/${id}`)
            .then(res => res)
            .catch(err=>{throw err})
    }

    static getPastCampaigns(){
        return http._get("/pastCampaigns")
            .then(res=>res)
            .catch(err=>{throw err})
    }

    static getMessageCounts(){
        return http._get("/sevenDaysIOCounts")
            .then(res=>res)
            .catch(err=>{throw err})
    }
}
