/* eslint-disable */
import React from 'react'

var _ = require('lodash');


export default function SmartReply(props) {
    let smartReplies = props.smartReplies;
    smartReplies = unflatten(smartReplies)

    if(smartReplies.length>0){
        return (
            <div className=" mt-2">
                <ul className={"smart-reply-list"}>
                    {smartReplies.map(sr=>getSmartReplyDisplay(sr,props))}
                </ul>
            </div>
        )
    }else if(props.cantEdit) {
        return <div className={"text-muted"}>
            No Smart Reply configured for this group.
        </div>
    }else{
        return <div className={"text-muted"}>
            No Smart Reply configured for this group. Click on <i className="fas fa-plus"/> above to add a smart reply.
        </div>
    }
}


function unflatten( array, parent, tree ){

    tree = typeof tree != 'undefined' ? tree : [];
    parent = typeof parent != 'undefined' ? parent : { id: 0 };

    let children = _.filter( array, function(child){ return child.parent === parent.id; });

    if( !_.isEmpty( children )  ){
        if( parent.id === 0 ){
           tree = children;   
        }else{
           parent['children'] = children;
        }
        _.each( children, function( child ){ unflatten( array, child ) } );                    
    }

    return tree;
}

function getSmartReplyDisplay(smartReply,props){
    return <li className="row mt-1 p-0">
             <div className="col-5 p-1">
                 <div className={"form-inline"}>
                     <div className={"col-6 p-1 text-right"}>
                        <label><small className={"font-italic"}>If response includes </small></label>
                     </div>
                     <div className={"col-6 p-1"}>
                        <input className="form-control"
                                placeholder="Keyword"
                                value={smartReply.keyword}/>
                     </div>
                 </div>
            </div>
            <div className="col-6 p-1">
                <div className={"form-inline"}>
                    <div className={"col-2 p-1"}>
                        <label><small className={"font-italic"}>then reply </small></label>
                    </div>
                    <div className={"col-6 p-1"}>
                        <input className="form-control"
                          placeholder="message"
                          value={smartReply.message}/>
                    </div>
                {!props.cantEdit ?
                <div className="col-4">
                    {smartReply.unSaved ?
                        <button className="btn left" title="Save changes"><i className="fas fa-save"/></button> :
                        <button className="btn  btn-secondary btn-sm  left"
                                title="Add keyword for reply to the message on left"
                                onClick={(e) => {
                                    e.preventDefault()
                                    props.onAdd(smartReply)
                                }}>
                            <i className="fas fa-plus"/></button>}
                    {smartReply.children ?
                        ""
                        :
                        <button className="btn  btn-danger btn-sm  left ml-1"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onDelete(smartReply.id)
                                }}><i className="fas fa-minus"/></button>

                    }
                </div>
                : ""
                }
                </div>

            </div>

            <ul className="col-12 ml-4">
                {smartReply.children?smartReply.children.map(sr=>getSmartReplyDisplay(sr,props)):""}
            </ul>
        </li>
}