/* eslint-disable */
import {connect} from 'react-redux';
import {deleteScheduledMessageSuccess, fetchScheduledMessages} from '../actions/ScheduledMessagesActions';
import ScheduledMessages from '../components/AppComponents/ScheduledMessages';
import React from "react";

const stateToPropertyMapper = (state, props) => {
    return {
        scheduledMessages:state.scheduledMessages
    }
}

const propertyToDispatchMapper = (dispatch) => ({
     fetchScheduledMessages:()=>dispatch(fetchScheduledMessages()),
     deleteScheduledMessage:(id)=>dispatch(deleteScheduledMessageSuccess(id))
})

const scheduledMessagesContainer=(props)=>{
    return(
        <div className="message-container">
            <div className="conversation-container">
                {/* Titlebar start */}
                <div className="conversation-title-container text-light shadow">
                    <div className="row p-2">
                        <div className="col-12">
                            <div className="p-2  text-left">
                                        <span className="text-light pt-2 py-2  title ">
                                            Scheduled messages
                                        </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ScheduledMessages {...props}/>
            </div>
        </div>
    )
}
const ScheduledMessageContainer =  connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(scheduledMessagesContainer)


export default ScheduledMessageContainer;