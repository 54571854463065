/* eslint-disable */
import React from 'react'
import {Redirect} from 'react-router-dom';

const InjectSessionComponent = (props) => {
    localStorage.setItem("joopzId",props.match.params.token);
    localStorage.setItem("userId",-1);

    return (
        <Redirect to={"/account"}/>
    );
};


export default InjectSessionComponent;
