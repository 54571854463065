/* eslint-disable */
import {connect} from 'react-redux'
import ConversationList from '../components/AppComponents/ConversationList';
import {fetchConversations} from '../actions/conversationActions';
// import  } from '../actions/conversationActions';




const stateToPropertyMapper = (state, props) => {
    return {
        conversations: state.conversations,
        conversationFilterString: props.conversationFilterString,
        contacts:state.contacts,
        groups:state.groups,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
    fetchAllConversations: () => 
       dispatch(fetchConversations(0)),
})


const ConversationListContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(ConversationList)

export default ConversationListContainer;