import Chart from 'react-google-charts';
import React from "react";


export default function DeliveryReportBarChart(props) {
    return (
        <div className={"col-12 bg-light p-0"}>
            <Chart
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['Task', 'Hours per Day'],
                    ['Success', props.success],
                    ['Failure', props.failed],
                    ['Pending', props.remaining],
                ]}
                options={{
                    // title: 'Delivery status',
                    legend:'bottom'
                }}
                rootProps={{ 'data-testid': '-1' }}
            />
        </div>
    );
}