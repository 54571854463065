import React from 'react'
import {Link} from "react-router-dom";
import MessageQueueDeleteConfirmationModal from "./MessageQueueDeleteConfirmationModal";

const QueuedMessageItem = (props) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className={"container border text-left p-3 bg-light"}>
            <b>To:</b> <Link to={"/groups/"+props.group.id}> {props.group.name} </Link> <br/>
            <b>Message</b>:{props.message.message}

            <button className={"btn btn-sm btn-light pull-right text-danger"}
                    onClick={()=>{setModalShow(true)}}>
                <i className="fa fa-trash" aria-hidden="true"/>
            </button>
            <MessageQueueDeleteConfirmationModal
                show={modalShow}
                onHide={()=>{setModalShow(false)}}
                onSubmit={props.onDelete}
                message={props.message}
                />
        </div>
    );
};

export default QueuedMessageItem;
