/* eslint-disable */
import React, {Component} from "react";
import ContactEditor from "../components/AppComponents/ContactEditor";
import {addContact, deleteContactSuccess, fetchContactById, updateContact} from "../actions/contactActions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import ContactServices from "../Services/ContactServices";

const contactServices = ContactServices.getInstance();

const stateToPropertyMapper = (state, props) => {
    return {
        profile: state.user.profile,
        contact: state.contacts.selectedContact,
        updateContactLoading: state.contacts.updateContactLoading,
        updateContactError: state.contacts.updateContactError,
        newContact: state.contacts.newContact
    };
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchContactById: (id) =>
        dispatch(fetchContactById(id)),
    updateContact: (id, contact) =>
        dispatch(updateContact(id, contact)),
    addContact: (contact) =>
        dispatch(addContact(contact)),
    deleteContactSuccess: (id) =>
        dispatch(deleteContactSuccess(id)),

});

class ContactContainer extends Component {
    componentDidMount() {
        if (this.props.match.params.id != "new") {
            this.props.fetchContactById(this.props.match.params.id);
        }
        if (this.props.newContact != null) {
            toast.update(101, {
                render: "Contact created.",
                type: toast.TYPE.SUCCESS,
                className: "rotateY animated",
                autoClose: true
            });

            this.props.history.push("/contacts/" + this.props.newContact.id + "/");
        }
    }

    componentWillReceiveProps(props) {
        if (props.newContact != null) {
            toast.update(101, {
                render: "Contact created.",
                type: toast.TYPE.SUCCESS,
                className: "rotateY animated",
                autoClose: true
            });
            props.fetchContactById(props.newContact.id);
            props.history.push("/contacts/" + props.newContact.id + "/");
        }
        this.props = props;
    }

    //handle delete group
    handleDeleteContact = (e) => {
        e.preventDefault();
        contactServices.deleteContact(this.props.contact.id)
            .then(res => {
                toast.success("Contact " + this.props.contact.name + " deleted successfully.");
                this.props.deleteContactSuccess(this.props.contact);
                this.props.history.push("/");
            }).catch();
    };

    render() {
        return (
            <div className="message-container">
                <ContactEditor
                    profile={this.props.profile}
                    contact={this.props.contact}
                    loading={this.props.updateContactLoading}
                    error={this.props.updateContactError}
                    updateContact={this.props.updateContact}
                    new={this.props.match.params.id == "new"}
                    addContact={this.props.addContact}
                    onDelete={this.handleDeleteContact}
                />
            </div>
        );
    }
}

export default connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(ContactContainer);
