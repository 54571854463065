/* eslint-disable */
import React from 'react'
import SignupComponent from '../components/AppComponents/SignupComponent';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {getStripeKey} from "../helpers/StripeHelper";
import TagManager from "react-gtm-module";


export default function SignupContainer() {

    return (
        <StripeProvider apiKey={getStripeKey()}>
            <Elements>
                <SignupComponent />
            </Elements>
      </StripeProvider>
    )
}
