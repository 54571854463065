import ContactServices from "../Services/ContactServices";


const contactServices = ContactServices.getInstance();

/************************************************************
 *                   Get Opt-out List                       *
 ************************************************************/

export const FETCH_BLOCKS_BEGIN = "FETCH_BLOCKS_BEGIN";
export const FETCH_BLOCKS_SUCCESS = "FETCH_BLOCKS_SUCCESS";
export const FETCH_BLOCKS_FAILURE = "FETCH_BLOCKS_FAILURE";

const fetchBlocksBegin = () => ({
    type: FETCH_BLOCKS_BEGIN,
});
const fetchBlocksSuccess = (blocks) => ({
    type: FETCH_BLOCKS_SUCCESS,
    payload: blocks,
});

const fetchBlocksFailure = (error) => ({
type: FETCH_BLOCKS_FAILURE,
payload: error,
});

export const fetchBlocks = () => {
    return dispatch => {
        dispatch(fetchBlocksBegin());
        contactServices.getBlocks()
        .then(res => {
            dispatch(fetchBlocksSuccess(res))
        })
        .catch(err => {
            dispatch(fetchBlocksFailure(err))
        })
    }
};

/************************************************************
 *                 Add to Opt-out List                      *
 ************************************************************/

// export const CREATE_BLOCKS_BEGIN = "CREATE_BLOCKS_BEGIN";
export const CREATE_BLOCKS_SUCCESS = "CREATE_BLOCKS_SUCCESS";
// export const CREATE_BLOCKS_FAILURE = "CREATE_BLOCKS_FAILURE";


// const  createBlockBegin = () => ({
//     type:CREATE_BLOCKS_BEGIN
// });

export const createBlockSuccess=(res)=>({
    type: CREATE_BLOCKS_SUCCESS,
    payload:res
});


