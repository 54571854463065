/* eslint-disable */
import React, {Component} from 'react'
import NavbarFull from './NavbarFull';
import AuthServices from '../../Services/AuthServices';
import {emailIsValid} from '../../helpers/validation-helper';
import {toast, ToastContainer} from 'react-toastify';
import NotificationHelpers from "../../helpers/NotificationHelpers";

const authServices = AuthServices.getInstance();

export default class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state={}
    }
    handleFormSubmit=(e)=>{
        e.preventDefault();
        if(this.state.email && this.state.email!="" && emailIsValid(this.state.email)){
            authServices.sendForgotPasswordLink(this.state.email)
                .then(res => {toast.success(NotificationHelpers.RESET_LINK_SENT)})
                .catch(err => {toast.error(NotificationHelpers.ERROR_RESET_LINK)})

        }else{
            toast.error(NotificationHelpers.INVALID_EMAIL)
        }
    }
    handleInputChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    render() {
        return (
            <div>
                <ToastContainer/>
                <NavbarFull signedOut="true"/>
                <div className="container ">
                    <div className="col-md-4 offset-md-4 col-xs-12 mt-4 p-0 py-3 settings-card">
                        {/* <img src="https://slytext.com/app/img/logo.png" height="50" alt=""></img> */}
                        <h5 className="title">Forgot password?</h5>
                        <div className="p-3">
                            <form>
                                <div className="form-group">
                                    <label for="email">Email address</label>
                                    <input type="email"
                                        className="form-control" 
                                        id="email" 
                                        name="email"
                                        aria-describedby="emailHelp" 
                                        placeholder="Enter email address"
                                        onChange={this.handleInputChange}/>
                                </div>
                                
                                <button type="submit" 
                                        className="btn btn-primary"
                                        onClick={this.handleFormSubmit}>Get reset link</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
