/* eslint-disable */
import {
    FETCH_CONVERSATION_FOR_CONTACT_SUCCESS, FETCH_CONVERSATION_FOR_GROUP_SUCCESS,
    FETCH_CONVERSATIONS_BEGIN,
    FETCH_CONVERSATIONS_SUCCESS,
    fetchConversations,
    NEW_MESSAGE_RECEIVED,
    NEW_MESSAGE_SENT,
    READ_CONVERSATION_MESSAGE,
} from '../actions/conversationActions';
import {DELETE_CONTACT_SUCCESS, UPDATE_CONTACT_SUCCESS} from '../actions/contactActions';
import {DELETE_GROUP_SUCCESS, UPDATE_GROUP_SUCCESS} from '../actions/groupActions';

const initialState = {
    loading: false,
    error: null,
    items: [],
}
    
export function ConversationReducer(state = initialState, action) {
    let conv=null;
    switch (action.type) {
        case FETCH_CONVERSATIONS_BEGIN:
            return {
                items:state.items,
                loading:true,
                error: null,
            }
        case FETCH_CONVERSATIONS_SUCCESS:
            let convs = state.items;
            if(action.payload.first){
                convs = (action.payload.result)
            }else{
                convs.concat(action.payload.result)
            }
            return {
                loading: false,
                error: null,
                items: convs,
            };
        // case FETCH_CONVERSATIONS_FAILURE:
        //         return {
        //             loading: false,
        //             error: action.payload,
        //             items: state.conversations
        //         }
        case UPDATE_CONTACT_SUCCESS:
            return{
                ...state,
                items: state.items.map(conversation=>{
                    if(conversation.contact_id==action.payload.id){
                        conversation.name=action.payload.name;
                    }
                    return conversation;
                })
            }
        case UPDATE_GROUP_SUCCESS:
            return{
                ...state,
                items: state.items.map(conversation => {
                    if(conversation.group_id==action.payload.id){
                        conversation.name=action.payload.name;
                    }
                    return conversation;
                })
            }
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                items:state.items.filter(conv=>conv.group_id!=action.payload.id)
            }
        case DELETE_CONTACT_SUCCESS:
                const filteredList=state.items.filter(conv=>conv.contact_id!=action.payload.id);
                return {
                    ...state,
                    items:filteredList

                }
        case NEW_MESSAGE_RECEIVED:
            
            conv = state.items.length>0?state.items.find(conv=>conv.id===action.payload.id):null;
            if(conv){
                conv.has_unread=true;
                conv.message=action.payload.message;
                conv.timestamp=new Date().toISOString()
                let convs_new_message = state.items.filter(conv=>conv.id!==action.payload.id)
                convs_new_message.unshift(conv)
                return{
                    ...state,
                    items:convs_new_message,
                }
            }else{
                fetchConversations(0)
                return state
            }
        case NEW_MESSAGE_SENT:
            conv = state.items.length>0?state.items.find(conv=>conv.id===action.payload.id):null;
            if(conv){
                conv.message=action.payload.message.message;
                conv.timestamp=new Date().toISOString()

            }else{
                conv = {
                    id:action.payload.id,
                    message:action.payload.message.message,
                    timestamp:new Date().toISOString(),
                    group_id:action.payload.message.group_id,
                    contact_id:action.payload.message.contact_id,
                    name:action.payload.conv_name,
                }

            }
            let convs_new_message_sent = state.items.filter(conv=>conv.id!==action.payload.id)
            convs_new_message_sent.unshift(conv)
            if(convs_new_message_sent===null) {
                convs_new_message_sent=[conv]
            }
            return{
                ...state,
                items:convs_new_message_sent,
            }
        case READ_CONVERSATION_MESSAGE:
                return{
                    ...state,
                    items:state.items.map(conv => {
                        if(conv.id===action.payload.id){
                            conv.has_unread=false;
                        }
                        return conv;
                    })
                }
        // case FETCH_CONVERSATION_FOR_CONTACT_SUCCESS:
        //     if(state.items.filter(conv=>conv.id==action.payload.id).length>0){
        //         return state;
        //     }else{
        //         convs=state.items.unshift(action.payload)
        //         return{
        //             ...state,
        //             items:convs
        //         }
        //     }
        // case FETCH_CONVERSATION_FOR_GROUP_SUCCESS:
        //     if(state.items.filter(conv=>conv.id==action.payload.id).length>0){
        //         return state;
        //     }else{
        //         convs=state.items.unshift(action.payload)
        //         return{
        //             ...state,
        //                 items:convs
        //         }
        //     }
        default:
                return state
    }
}