/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {formatPhoneNumber} from "../../helpers/FormattingHelper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function OptoutListModal(props) {
    let inputVal="";
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{borderRadius:"1.5rem"}}
        >
            <Modal.Header closeButton>
                <Modal.Title >
                    Alert!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"text-center"}>
                <i className="fas fa-exclamation-circle fa-7x text-muted"></i>
                <h4>Are you sure you want to add <span className={"text-primary"}>{formatPhoneNumber(props.phoneNumber)}</span> to the opt-out list?</h4>
                <p>This action cannot be reverted.</p>
            </Modal.Body>
            <Modal.Footer style={{display:"block"}}>
                <Row className={"pr-1"}>
                    <Col md={{span:3,offset:6}} className={"p-1"}>
                        <button onClick={(e)=>{
                            e.preventDefault()
                            props.onSubmit(inputVal)}}
                                className={"btn btn-danger col-12"}>Yes</button>
                    </Col>

                    <Col md={{span:3}} className={"p-1"}>
                        <Button onClick={props.onHide}
                                variant={"secondary"}
                                className={"col-12"}>Cancel</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


OptoutListModal.propTypes = {
    onSubmit: PropTypes.func,
    onHide: PropTypes.func,
    phoneNumber: PropTypes.string
}

export default OptoutListModal;