import SlybroadcastListServices from "../Services/SlybroadcastListServices";

export const FETCH_SLYB_LISTS_BEGIN = "FETCH_SLYB_LISTS_BEGIN";
export const FETCH_SLYB_LISTS_SUCCESS = "FETCH_SLYB_LISTS_SUCCESS";
export const FETCH_SLYB_LISTS_FAILURE = "FETCH_SLYB_LISTS_FAILURE";

const fetchSlyBListsBegin=() => ({
    type: FETCH_SLYB_LISTS_BEGIN
});

const fetchSlyBListsSuccess=(payload) => ({
    type:FETCH_SLYB_LISTS_SUCCESS,
    payload: payload,
});

const fetchSlyBListsFailure=(err) => ({
    type: FETCH_SLYB_LISTS_FAILURE,
    payload: err,
});

export const fetchSlyBLists=()=>{
    return dispatch => {
        dispatch(fetchSlyBListsBegin());
        SlybroadcastListServices.getLists()
            .then(res => {
                return dispatch(fetchSlyBListsSuccess(res))
            })
            .catch(err => {
                return dispatch(fetchSlyBListsFailure(err))
            })
    }
};