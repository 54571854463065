import React from 'react'
import {Link} from "react-router-dom";
import {formatTime} from "../../helpers/FormattingHelper";

const DashboardPastCampaignItem = (props) => {
    return (
        <div className={"row border text-left p-3 bg-light"} style={{maxHeight:"200px", overflow:"hidden"}}>
            <div className={"col-6 "} >
                <b>To:</b> <Link to={"/groups/"+props.group.id}> {props.group.name} </Link> <br/>
                <b>Message</b>:{props.message.message}
            </div>
            <div className={"col-6 text-right"}>
                <b>Finished at:</b> <span className={"font-italic"}>{formatTime(props.last_sent_at)}</span><br/>
                <b># of members in group:</b> <span className={"font-italic"}>{props.total_messages_in_group}</span><br/>
                <b># of messages sent:</b> <span className={"font-italic"}>{props.total_sent}</span>

            </div>
        </div>
    );
};

export default DashboardPastCampaignItem;