/* eslint-disable */
import {connect} from 'react-redux';
import {fetchCustomer, fetchPlans} from '../actions/SubscriptionActions';
import {fetchProfile} from '../actions/UserActions';
import SubscriptionComponent from '../components/AppComponents/SubscriptionComponent';
import React from 'react'
import {Elements, StripeProvider} from 'react-stripe-elements';
import {getStripeKey} from "../helpers/StripeHelper";

const stateToPropertyMapper = (state, props) => {
    return {
        plans:state.plans,
        user:state.user,
    }
}

const propertyToDispatchMapper = (dispatch) => ({
    fetchPlans:()=>dispatch(fetchPlans()),
    fetchCustomer:()=>dispatch(fetchCustomer()),
    fetchProfile:()=>dispatch(fetchProfile()),
})


const SubscriptionContainer =  connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(Container)

function Container(props) {
    return (
        <div>
            <StripeProvider apiKey={getStripeKey()}>
            <Elements>
                <SubscriptionComponent {...props}/>
            </Elements>
      </StripeProvider>
        </div>
    )
}


export default SubscriptionContainer;