import {
  FETCH_DOMAINS_BEGIN,
  FETCH_DOMAINS_FAILURE,
  FETCH_DOMAINS_SUCCESS,
} from "../actions/redirectURLActions";

const initialState={
  loading:false,
  domains: [],
  error:null,
};

export default function RedirectURLReducer(state = initialState,action){
  switch(action.type){
    case FETCH_DOMAINS_BEGIN:
      return {
        ...state,
        loading:true,
      };
    case FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        loading:false,
        domains:action.payload,
        error:null
      };
    case FETCH_DOMAINS_FAILURE:
      return {
        ...state,
        loading:false,
        domains: [],
        error:action.payload
      };
    default:
      return state
  }
}