
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { Modal} from 'react-bootstrap';


class InputDialogModal extends PureComponent {
    constructor(props){
        super(props);
        this.state={inputVal:""};
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            inputVal:""
        })
    }

    handleChange=(e)=>{
        this.setState({
            inputVal:e.target.value
        })
    };

    render() {
        const props=this.props;
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{borderRadius: "1.5rem"}}>

                {/* Modal header */}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.heading}
                    </Modal.Title>
                </Modal.Header>

                {/* Modal body */}
                <Modal.Body>
                    <div className="row text-center">
                        {props.inputName === "reply" ?
                            <div className="col-12 text-center">
                                <label>If response includes</label>
                                {props.keyword}
                            </div>
                            : ""
                        }
                        <div className="col-12 text-center">
                            <label>{props.label}</label>
                        </div>
                        <div className="col-12">
                            {props.inputName === "keyword" ?
                                <input type="text"
                                       className="form-control"
                                       onChange={this.handleChange}
                                       name={"keyword"}
                                       id={"keyword"}
                                       placeholder={"Enter keyword"}
                                       value={this.state.inputVal}/>
                                :
                                <input type="text"
                                       className="form-control"
                                       onChange={this.handleChange}
                                       name={"reply"}
                                       id={"reply"}
                                       placeholder={"Enter reply"}
                                       value={this.state.inputVal}/>

                            }
                        </div>
                    </div>
                </Modal.Body>

                {/* Modal footer */}
                <Modal.Footer>
                    <button onClick={(e) => {
                        e.preventDefault();
                        props.onSubmit(this.state.inputVal)
                    }} className={"btn btn-primary"}>
                        {props.inputName === "keyword" ? "Next" : "Submit"}
                    </button>
                </Modal.Footer>

            </Modal>
        );
    }

}
InputDialogModal.propTypes = {
    heading: PropTypes.string,
    onSubmit: PropTypes.func,
    onHide: PropTypes.func,
    label: PropTypes.string,
    inputName: PropTypes.string,
    keyword: PropTypes.string,
};

export default InputDialogModal;