/* eslint-disable */
import React, {Component} from 'react';
import {toast} from 'react-toastify';
import AuthServices from '../../Services/AuthServices';
import NavbarFull from './NavbarFull';
import NotificationHelpers from "../../helpers/NotificationHelpers";

const authServices = AuthServices.getInstance();

export default class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state={
            password:"",
            confirmPassword:""
        }
    }
    handleFormSubmit=(e)=>{
        e.preventDefault();
        if(this.state.password.length<8){
            toast.error(NotificationHelpers.INVALID_PASSWORD)
        }else if(this.state.confirmPassword!=this.state.password){
            toast.error(NotificationHelpers.PASSWORD_MISMATCH_ERROR)
        }else{
            authServices.resetPassword(this.props.match.params.token,this.state.password)
                .then(res => {
                                toast.success(NotificationHelpers.SUCCESS_PASSWORD_UPDATE);
                                this.props.history.push("/");
                            })
                .catch(err => {
                    toast.error(NotificationHelpers.ERROR_PASSWORD_UPDATE)
                })

        }
        
    }
    handleInputChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    render() {
        return (
            <div>
                <NavbarFull signedOut="true"/>
                <div className="container ">
                    <div className="col-md-4 offset-md-4 col-xs-12 mt-4 p-0 py-3 settings-card">
                        {/* <img src="https://slytext.com/app/img/logo.png" height="50" alt=""></img> */}
                        <h5 className="title">Reset password</h5>
                        <div className="p-3">
                            <form>
                                <div className="form-group">
                                    <label for="password">Password</label>
                                    <input type="password"
                                        className="form-control" 
                                        id="password" 
                                        name="password"
                                        aria-describedby="passwordHelp" 
                                        placeholder="Enter password (at least 8 characters)"
                                        onChange={this.handleInputChange}/>
                                </div>
                                
                                <div className="form-group">
                                    <label for="password">Confirm password</label>
                                    <input type="password"
                                        className="form-control" 
                                        id="confirmPassword" 
                                        name="confirmPassword"
                                        aria-describedby="passwordHelp" 
                                        placeholder="Enter password again"
                                        onChange={this.handleInputChange}/>
                                </div>

                                <button type="submit" 
                                        className="btn btn-primary"
                                        onClick={this.handleFormSubmit}>Change</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
