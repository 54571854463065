/* eslint-disable */

import AuthServices from "./AuthServices";
import {getWsHost} from "../helpers/url-helper";

const url = getWsHost();
let ws = null;

export default class WebSocketService{
    static webSocketService = null;

    static getInstance(){
        if(this.webSocketService==null){
            this.webSocketService=new WebSocketService();
        }
        return this.webSocketService;
    }

    connect(listner){
        ws = new WebSocket(url);
        this.login();
        ws.onclose=(e=>{
            this.connect(listner)
        });
        ws.onerror = function(err) {
            ws.close();
        };
        ws.onmessage=(msg=>listner(msg))
    }

    login(){
        if(AuthServices.getInstance().isLoggedIn()){
            const data = {"session" : AuthServices.getInstance().storedSession()}
            this.send(JSON.stringify(data));
        }else{
        }
    }

    send = function (message, callback) {
        this.waitForConnection(function () {
            ws.send(message);
            if (typeof callback != 'undefined') {
              callback();
            }
        }, 1000);
    };
    
    waitForConnection = function (callback, interval) {
        if (ws.readyState === 1) {
            callback();
        } else {
            var that = this;
            // optional: implement backoff for interval here
            setTimeout(function () {
                that.waitForConnection(callback, interval);
            }, interval);
        }
    };

}