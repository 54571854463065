/* eslint-disable */
import React from 'react'
import IncomingMessageItem from './IncomingMessageItem';
import OutgoingMessageItem from './OutgoingMessageItem';

export default function MessageItem(props) {
    return (
        <div className="mt-2 chat-container" >
            {props.message.direction==='I'?
                <IncomingMessageItem message={props.message} sender={props.conversationName}/>:
                <OutgoingMessageItem message={props.message}
                                     groupCount={props.groupCount}
                                     broadcastGroup={props.broadcastGroup}/>}
            <div style={{clear:"both"}}></div>
        </div>
    )
}
