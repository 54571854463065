import RedirectURLHttpClient from "./RedirectURLHttpClient";
import moment from 'moment';

const http = RedirectURLHttpClient.getInstance();
export default class RedirectURLServices {
  static service = null;

  static getInstance() {
    if (this.service === null) {
      this.service = new RedirectURLServices();
    }
    return this.service;
  }

  static fetchDomains() {
    const body = {
      user_name: localStorage.getItem("redirectUser"),
      method: 'get_domain'
    }
    return http._post('', body).then(res => {
      if (!Array.isArray(res)) {
        return Promise.reject(res);
      }
      return res;
    });
  }

  static setSingleURLWithKey(data) {
    const body = {
      domain: data.domain,
      url: data.url,
      urlkey: data.key,
      method: 'save_one_url_with_key'
    }
    return http._post('', body).then(res => {return res}).catch(err => err);
  }

  static generateKeysForPhoneNumbers(data) {
    const body = {
      domain: data.domain,
      listname: data.listName,
      phonelist: data.phoneList,
      method: 'generate_keys_for_phone_lists',
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }

  static async uploadMultipleKeysByDomain(data) {
    const body = {
      domain: data.domain,
      phonelist: data.phoneList,
      method: 'save_keys_with_url',
    };
    return http._post('', body)
        .then(res => {
          return res
        })
        .catch(err => err);
  }

  static updateURLByList(data) {
    const body = {
      domain: data.domain,
      listid: data.listId,
      url: data.url,
      method: 'update_url',
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }

  static updateListName(data) {
    const body = {
      domain: data.domain,
      listid: data.listId,
      listname: data.listname,
      method: 'update_listname',
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }

  static getClickThruReport(domain, startDate, endDate) {
    const body = {
      domain,
      method: 'get_report',
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }

  static getListByDomain(domain) {
    const body = {
      domain,
      method: 'get_list_name',
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }

  static downloadList(data) {
    const body = {
      domain: data.domain,
      listid: data.listId,
      method: 'download_list',
    }
    return http._post('', body)
      .then(res => {
        return res
      })
      .catch(err => err);
  }
}
