/* eslint-disable */
import React from 'react'
import {setTime} from '../../helper';
import MediaViewer from './MediaViewer';
import Linkify from 'react-linkify';


export default function IncomingMessageItem(props) {
    return (
        <div className="incoming-message left">
            <span id="title"> {props.sender} </span>
            <br/>
            <Linkify>
                <span id="message">{props.message.message}</span>
            </Linkify>
            <br/>
            {props.message.fileHash==="" ?"":
                <span id="file">
                    <MediaViewer {...props.message}/>
                </span>}
            <br/>
            <div className="mt-2">
                <span id="timestamp">{setTime(props.message.timestamp)}</span>
            </div>
        </div>
    )
}
