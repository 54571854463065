/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class SubscriptionServices{
    static subscriptionServices = null;
    static getInstance(){
        if(this.subscriptionServices==null){
            this.subscriptionServices=new SubscriptionServices();
        }
        return this.subscriptionServices;
    }

    getCustomer(){
        return http._get("/customer")
                .then(res => res)
                .catch(err=>{throw err})
    }

    getPlans(){
        return http._get("/plans")
                .then(res => res)
                .catch(err => {throw err})
    }

    updateCreditCard(stripeToken){
        return http._put("/payment-token",{
                        stripeToken:stripeToken
                    })
                    .then(res=>res)
                    .catch(err=>{throw err})
    }
    subscribeToPlan(planId,stripeToken){
        return http._post("/subscriptions",{
                                planId:planId,
                                stripeToken:stripeToken
                            })
                    .then(res => res)
                    .catch(err => {throw err})
    }
    removeInvalidUser() {
        return http._delete("/signup")
                   .then(res=>res)
                   .catch(err=>{throw err});
    }
    sendWelcomeEmail() {
        return http._post('/signup-welcome')
                   .then(res=>res)
                   .catch(err=>{throw err});
    }
    cancelSubscription(){
        return http._delete("/subscriptions")
                    .then(res=>res)
                    .catch(err=>{throw err})
    }

    creditMessages(messageCount,stripeToken){
        return http._post("/credits",{
            messageCount:messageCount,
            stripeToken:stripeToken
        })
        .then(res => res)
        .catch(err => err)
    }

    applyPromoCode(promocode){
        return http._post("/promo",{
            promocode:promocode
        })
            .then(res => res)
            .catch(err => {
                throw err.text()
            })
    }
}