/* eslint-disable */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class PhoneServices{
    static phoneServices = null;
    static getInstance(){
        if(this.phoneServices==null){
            this.phoneServices=new PhoneServices();
        }
        return this.phoneServices;
    }

    getNumbers(prefix){
        return http._get("/numbers?prefix="+prefix)
                .then(res => res)
                .catch(err => {throw err})
    }

    purchaseNumber(phone){
        return http._post("/numbers/"+phone)
            .then(res => res)
            .catch(err => {throw err})
    }
}