/* eslint-disable */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {toast} from 'react-toastify';
import {emailIsValid} from '../../helpers/validation-helper';
import SignupServices from '../../Services/SignupServices';
import SubscriptionServices from '../../Services/SubscriptionServices';
import NavbarFull from './NavbarFull';
import NotificationHelpers from "../../helpers/NotificationHelpers";
import "../../assets/style/signup-form.css"
import '../../assets/style/webflow.css';
import '../../assets/style/normalize.css'
import SignUpSlider from "./SignupSlider";
import {createToast} from "../../helpers/ToastIdHelper";
import TagManager from 'react-gtm-module'

const subscriptionServices = SubscriptionServices.getInstance();
const signupServices = SignupServices.getInstance();


const tagManagerArgs = {
    gtmId: 'GTM-M96W495',
    dataLayer: {
        page: 'signup'
    },
};

const tagManagerArgsOnCreateAccount = {
    gtmId: 'GTM-M96W495',
    event: 'createAccount',
    events: {
        createAccount: 'createAccount'
    },
    dataLayer: {
        event: 'createAccount',
        page: 'create-account'
    },
};

const routeToApp = '/app';

class SignupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",
            plans: [],
            sendingRequest:false,
            promocode:'',
        };
        this.form = React.createRef();
        this.toastId = 106;
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    componentDidMount() {
        TagManager.initialize(tagManagerArgs);
        subscriptionServices.getPlans()
            .then(res => {
                    res = res.sort((a, b) => (a.price - b.price));
                    let plans = res.filter(plan => !plan.recurring);
                    plans = plans.concat(res.filter(plan => plan.recurring));
                    this.setState({
                        plans: plans
                    })
                }
            )
            .catch(err => {
                createToast(this.toastId,NotificationHelpers.SIGNUP_500,toast.TYPE.ERROR)
            })
    }

    async handleFormSubmit(ev) {
        if(this.state.sendingRequest){
            return;
        }
        this.setState({sendingRequest:true});
        if (!this.form.current.reportValidity()) {
            this.setState({sendingRequest: false});
            return;
        }

        if (this.state.emailCheckStatus === false) {
            createToast(this.toastId,this.state.emailCheckError,toast.TYPE.ERROR);
            this.setState({sendingRequest:false});
            return;
        }
        if (this.state.password != this.state.passwordConfirm) {
            createToast(this.toastId,NotificationHelpers.PASSWORD_MISMATCH_ERROR,toast.TYPE.ERROR);
            this.setState({sendingRequest:false});
            return
        }
        let {token} = await this.props.stripe.createToken({name: this.state.name});
        if (token == undefined) {
            createToast(this.toastId,NotificationHelpers.INVALID_CARD,toast.TYPE.ERROR);
            this.setState({sendingRequest:false});
            return;
        }
        signupServices.signUp(this.state)
            .then(res => {
                TagManager.initialize(tagManagerArgsOnCreateAccount);
                const selectedPlan = this.state.plans.find(plan => plan.id === this.state.plan);
                subscriptionServices.updateCreditCard(token.id)
                    .then(res=>{
                        if (selectedPlan.recurring) {
                            subscriptionServices.subscribeToPlan(selectedPlan.id, token.id)
                                .then(res => {
                                    if (res && res.status == 500) {
                                        throw(res);
                                    }
                                    createToast(this.toastId,NotificationHelpers.SUCCESSFUL_SIGN_UP,toast.TYPE.SUCCESS);
                                    toast.success("Successfully subscribed to a plan.");
                                    if(this.state.promocode!==''){
                                        subscriptionServices.applyPromoCode(this.state.promocode)
                                            .then(res => {
                                                toast.success("Successfully applied promotion. Added " +
                                                    res.message_credit+" credits to your account.");
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                            .catch(err => {
                                                err.then(text=>{
                                                    toast.error(JSON.parse(text).error);
                                                    this.setState({sendingRequest:false});
                                                    this.props.history.push(routeToApp)
                                                })

                                            })
                                    }else{
                                        this.setState({sendingRequest:false});
                                        this.props.history.push(routeToApp)
                                    }
                                    subscriptionServices.sendWelcomeEmail().catch(err => console.error(err));
                                })
                                .catch(err => {
                                    subscriptionServices.removeInvalidUser().then(res => {
                                        err.text()
                                        .then(err => {
                                            err = JSON.parse(err);
                                            toast.error(NotificationHelpers.UNABLE_TO_SUBSCRIBE);
                                        });
                                        this.setState({sendingRequest:false})
                                    });

                                })
                        } else {
                            subscriptionServices.creditMessages(selectedPlan.messageCount, token.id)
                                .then(res => {
                                    if (res && res.status == 500) {
                                        throw(res);
                                    }
                                    createToast(this.toastId,NotificationHelpers.SUCCESSFUL_SIGN_UP,toast.TYPE.SUCCESS);
                                    toast.success("Successfully purchased message credit.");
                                    if(this.state.promocode!==''){
                                        subscriptionServices.applyPromoCode(this.state.promocode)
                                            .then(res => {
                                                toast.success("Successfully applied promotion. Added " +
                                                    res.message_credit+" credits to your account.");
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                            .catch(err => {
                                                err.then(text=>{
                                                    toast.error(JSON.parse(text).error);
                                                    this.setState({sendingRequest:false});
                                                    this.props.history.push(routeToApp)
                                                });
                                                this.setState({sendingRequest:false});
                                                this.props.history.push(routeToApp)
                                            })
                                    }else{
                                        this.setState({sendingRequest:false});
                                        this.props.history.push(routeToApp)
                                    }
                                    subscriptionServices.sendWelcomeEmail().catch(err => console.error(err));
                                })
                                .catch(err => {
                                    subscriptionServices.removeInvalidUser().then(res => {
                                        err.text()
                                        .then(err => {
                                            err = JSON.parse(err);
                                            toast.error(NotificationHelpers.UNABLE_TO_SUBSCRIBE);
                                        });
                                        this.setState({sendingRequest:false})
                                    });
                                })
                        }
                    })
                    .catch(err=>{
                        subscriptionServices.removeInvalidUser().then(res => {
                            err.text()
                            .then(err => {
                                err = JSON.parse(err);
                                toast.error("Could not process your payment information. Please try a different card.")
                            });
                            this.setState({sendingRequest:false})
                        });
                    })

            })
            .catch(err => {
                err.text()
                    .then(err => {
                        err = JSON.parse(err);
                        createToast(this.toastId,"Error: " + err.error,toast.TYPE.ERROR);
                        this.setState({sendingRequest:false})
                    })
            })

    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleEmailCheck = (e) => {
        if (emailIsValid(e.target.value)) {
            signupServices.checkEmail(e.target.value)
                .then(res => {
                    if (res.status) {
                        this.setState({
                            emailCheckStatus: true,
                            emailCheckError: null
                        })
                    } else {
                        this.setState({
                            emailCheckStatus: false,
                            emailCheckError: res.error
                        })
                    }
                })
        } else {
            this.setState({
                emailCheckStatus: false,
                emailCheckError: NotificationHelpers.INVALID_EMAIL
            })
        }

    };

    render() {
        return (
            <div>
                <NavbarFull signedOut="true"/>

                <div className="container text-left container">
                    <div className={"row mt-4 pt-4"}>
                        <div className="col-md-5 offset-md-2 col-sm-10 offset-sm-1">
                            <div className="form">
                                <div className="text-block">Create Account</div>
                                <p className={"text-muted"}> <span className={"text-danger"}>*</span> Mandatory fields </p>
                                <div className="form-block w-form">
                                    <form id="wf-form-Log-In" name="wf-form-Log-In" data-name="Log In" className="form-2"
                                          ref={this.form} onSubmit={e => e.preventDefault()}>
                                        <label htmlFor="name" className="field-label">Name<span className={"text-danger"}>*</span> </label>
                                        <input type="text"
                                               className="text-field w-input"
                                               autoFocus="true"
                                               maxLength="256"
                                               name="name"
                                               data-name="Name"
                                               placeholder="First &amp; last name"
                                               id="name"
                                               onChange={this.handleInputChange}
                                               required/>
                                        <label htmlFor="Email" className="field-label">Email address<span className={"text-danger"}>*</span></label>
                                        <input type="email"
                                               className="text-field w-input"
                                               maxLength="256"
                                               name="email"
                                               data-name="Email"
                                               placeholder="Enter email"
                                               id="Email"
                                               onChange={this.handleInputChange}
                                               onBlur={this.handleEmailCheck}
                                               required/>
                                        {!this.state.emailCheckStatus ?
                                            <small className="text-danger">{this.state.emailCheckError}</small> :
                                            ""
                                        }
                                        <label htmlFor="Phone-Number-3" className="field-label-2">Phone number<span className={"text-danger"}>*</span></label>
                                        <input type="tel"
                                               className="text-field-2 w-input"
                                               maxLength="256"
                                               name="phone"
                                               data-name="Phone Number"
                                               placeholder="Phone number"
                                               id="Phone-Number-3"
                                               onChange={this.handleInputChange}
                                               required/>

                                        <div className="row ">
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Password<span className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="password"
                                                       data-name="Password"
                                                       placeholder="At least 8 characters"
                                                       id="Password"
                                                       onChange={this.handleInputChange}
                                                       required/>
                                            </div>
                                            <div className={"col-6"}>
                                                <label htmlFor="Phone-number-3" className="field-label-2">Confirm
                                                    password<span className={"text-danger"}>*</span></label>
                                                <input type="password"
                                                       className="text-field-2 w-input"
                                                       maxLength="256"
                                                       name="passwordConfirm"
                                                       data-name="Confirm password"
                                                       placeholder="Enter password again"
                                                       id="Confirm-password"
                                                       onChange={this.handleInputChange}
                                                       required/>
                                            </div>
                                        </div>

                                        <label htmlFor="field-2" className="field-label-2">
                                            Select a plan<span className={"text-danger"}>*</span>
                                        </label>
                                        <select id="field-2"
                                                required
                                                onChange={this.handleInputChange}
                                                name="plan"
                                                className="select-field w-select">
                                            <option default>Select a plan</option>
                                            {this.state.plans.map(plan =>
                                                <option value={plan.id}>{plan.name} | ${plan.price / 100}</option>)}
                                        </select>
                                        <label htmlFor="Phone-number-3" className="field-label-2">Payment details<span className={"text-danger"}>*</span></label>
                                        <div className=" w-input text-field-2">
                                            <CardElement/>
                                        </div>
                                        <label htmlFor="promocode" className="field-label-2">
                                            Referral code
                                        </label>
                                        <input type="text"
                                               className="text-field-2 w-input"
                                               maxLength="15"
                                               name="promocode"
                                               placeholder="Enter code"
                                               id="promocode"
                                               onChange={this.handleInputChange}
                                               />
                                        <button
                                            type="submit"
                                            className={this.state.sendingRequest?"button w-button bg-secondary":"button w-button"}
                                            onClick={this.handleFormSubmit}
                                            id={"submit-signup"}
                                            disabled={this.state.sendingRequest}>{this.state.sendingRequest?"Please wait...":"Submit"}
                                        </button>
                                    </form>

                                    <div className="w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"col-md-4 col-sm-0 d-none d-md-block"}>
                            <SignUpSlider/>
                        </div>
                    </div>
                </div>
            </div>
    )
    }
    }

    export default withRouter(injectStripe(SignupComponent))
