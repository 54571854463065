/* eslint-disable */
import 'bootstrap/dist/css/bootstrap.css';
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import {AutoSizer, List} from "react-virtualized";
import 'react-virtualized/styles.css'; // only needs to be imported once
import ConversationComponent from './ConversationComponent';


import React, {Component} from 'react'
import {sortTimeStamp} from "../../helpers/FormattingHelper";

export default class ConversationList extends Component {
    constructor(props){
        super(props);
        this.state={
            inr : 1,
            conversationFilterString:"",
            conversations:sortTimeStamp(this.props.conversations.items,'timestamp')
        }
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentWillReceiveProps(props){
        if(props!=this.props){
            this.setState({
                inr: this.state.inr+1,
                conversations:sortTimeStamp(props.conversations.items,'timestamp',0)
            })
        }
    }

    componentDidMount(){
        this.props.fetchAllConversations();
    }

    handleFilterChange(e){
        this.setState({
            conversationFilterString:e.target.value.toLowerCase(),
            conversations:this.props.conversations.items.filter(z=>z.name.toLowerCase().includes(e.target.value))
        })
        
    }
    render() {
        let props = this.props;
        return (
            <div className="p-0 h-100">
           
            <div className="container my-2 bg-muted">
                        <input className="form-control rounded empty" 
                                placeholder="&#xF002; Search in Conversations"
                                onChange={this.handleFilterChange}>
                                
                        </input>                   
            </div>
            <div className={props.conversations.loading && props.conversations.items.length===0?"centered ":"invisible hidden"}>
            <ReactLoading type="spin"  
                          color={'#000000'} 
                          className={'centered'}
                          />
            </div>
            <div id="virtualized" style={{flexGrow:1,height: 'calc(100% - 95px)'}}>
                {props.conversations.loading || this.state.conversations.length > 0 ?
                    <AutoSizer>
                        {({height, width}) => (
                            <List
                                height={height}
                                rowCount={this.state.conversations.length}
                                rowHeight={78}
                                width={width}
                                rowRenderer={this.Row}
                                overscanRowCount={5}
                                style={{textDecoration: "none"}}
                            >
                            </List>
                        )}
                    </AutoSizer>
                    :
                    <div className={"text-muted col-10 offset-1 text-left"}>
                        <br/>
                        Click on <i className={"fa fa-plus"}/> in the top
                        to create <Link to={"/contacts/new/edit"}>contact</Link> or a <Link to={"/groups/new/edit"}> group</Link>.
                        <br/><br/>
                        Click on <i className={"fas fa-user"}/> in the top
                        to start conversation with a single contact.
                        <br/><br/>
                        Click on <i className={"fas fa-users"}/> in the top
                        to broadcast a message to a group.

                    </div>
                }
            </div>
            {/* {props.conversations.items.map(z => {
                if(z.name.toLowerCase().includes(this.state.conversationFilterString)){
                    return (
                    
                    )
                }
            }
                       
            )} */}
        </div>
        )
    }

    Row=({index,style})=>{
        return <Link to={this.state.conversations[index].contact_id===0?
                            "/groups/"+this.state.conversations[index].group_id+"/conversation/"+this.state.conversations[index].id
                            :
                            "/contacts/"+this.state.conversations[index].contact_id+"/conversation/"+this.state.conversations[index].id} 
                    key={this.state.conversations[index].id} 
                    style={style}
                    className="no-decoration">
                    <ConversationComponent conversation = {this.state.conversations[index]}
                                           target={this.state.conversations[index].group_id!=0?
                                                        this.props.groups.items.find(group=>
                                                                group.id==this.state.conversations[index].group_id)
                                           :
                                               this.props.contacts.items.find(contact=>
                                                   contact.id==this.state.conversations[index].contact_id)}/>
                </Link>
    }
}