/* eslint-disable */
import {
    CREATE_UPDATE_AR_SUCCESS,
    FETCH_AUTO_REPLY_BEGIN,
    FETCH_AUTO_REPLY_FAILURE,
    FETCH_AUTO_REPLY_SUCCESS
} from "../actions/autoReplyActions";

const initialState = {
   loading:false,
   item:{},
   error:null,
}

export default function AutoReplyReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_AUTO_REPLY_BEGIN:
            return {
                ...state,
                loading:true,
                error:null,
            }
        case FETCH_AUTO_REPLY_SUCCESS:
            return{
                ...state,
                error:null,
                loading:false,
                item:action.payload
            }
        case FETCH_AUTO_REPLY_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload,
                item:{}
            }
        case CREATE_UPDATE_AR_SUCCESS:
            return{
                ...state,
                loading:false,
                error:null,
                item:action.payload,
            }
        default:
            return state;
    }
}