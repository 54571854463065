/* eslint-disable */
import {CREATE_BLOCKS_SUCCESS, FETCH_BLOCKS_BEGIN, FETCH_BLOCKS_SUCCESS} from "../actions/blockActions";
import {
    FETCH_SLYB_LISTS_BEGIN,
    FETCH_SLYB_LISTS_FAILURE,
    FETCH_SLYB_LISTS_SUCCESS
} from "../actions/SlybroadcastListActions";

const initialState = {
   loading:false,
   items:[],
   error:null,
};

export default function SlybListReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_SLYB_LISTS_BEGIN:
            return{
                ...state,
                loading:true,
                error:null
            };
        case FETCH_SLYB_LISTS_SUCCESS:
            return{
                ...state,
                loading:false,
                items:action.payload,
                error:null
            };
        case FETCH_SLYB_LISTS_FAILURE:
            return{
                ...state,
                loading:false,
                items:[],
                error:action.payload
            };
        default:
            return state;
    }
}