/* eslint-disable */
import {CREATE_BLOCKS_SUCCESS, FETCH_BLOCKS_BEGIN, FETCH_BLOCKS_SUCCESS} from "../actions/blockActions";

const initialState = {
   loading:false,
   items:[],
   error:null,
};

export default function BlockReducer(state = initialState, action){
    switch(action.type) {
        case FETCH_BLOCKS_BEGIN:
            return{
                ...state,
                loading:true,
                error:null
            };
        case FETCH_BLOCKS_SUCCESS:
            return{
                ...state,
                loading:false,
                items:action.payload,
                error:null
            };
        case CREATE_BLOCKS_SUCCESS:
            return{
                ...state,
                items:[action.payload,...state.items]
            };
        default:
            return state;
    }
}